import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 8;
    min-height: 78vh;
    @media only screen and (min-width:${props => props.theme.phoneBreakpoint + 'px'}) {
        justify-content: center;
    }
`;

export const CheckWrapper = styled.div`
   display: flex;
   margin: 10px 0 15px 0;
`;

export const SendToMobile = styled.label`
    color: rgb(80, 93, 100);
    font-weight: 500;
`;

export const Paragraph = styled.p`
    text-align: left;
    font-size: 16px;
    line-height: 23px;
    @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
        font-size: 16px;
        line-height: 21px;
    }
`;

export const SpinnerWrapper = styled.div`
    text-align: center;
`;
