import { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";

import FaceCaptureUI from "../../common/FaceCaptureUI";
import * as S from "./PageFaceCapture.styles";
import VisiblityChangeHandler from "Components/modules/common/VisiblityChangeHandler";
import PageContent from "Components/PageContent";
import { preview } from "logic/actions/faceCapture";
import {
  failedAttempt,
  idRndFail,
  incrementRetryCount,
} from "logic/actions/livenessTest";
import {
  IOS,
  FOREGROUND_EVENT,
  GRANT_CAMERA_PERMISSION,
} from "logic/enums/composite";

import {
  logEvent,
  face_capture_started,
  retry_sensor_init,
  face_capture_error,
  face_capture_timeout,
  idrnd_liveness_error,
  idrnd_liveness_retry,
  face_capture_finished,
  idrnd_liveness_passed,
  face_capture_back,
} from "logic/eventLogger";
import CaptureButtonPanel from "../../../CaptureButtonPanel";
import { changeCamera } from "logic/actions/camera";
import { withTranslation } from "react-i18next";
import { toggleNetworkWarnings } from "logic/actions/apiFetch";
import { setSelectedCamera } from "logic/actions/camera";
import DaonErrorBox from "Components/common/DaonErrorBox";
import DaonButton from "Components/common/DaonButton";
import { go, previous } from "logic/actions/navigation";
import { FACE_CAPTURE_INSTRUCTIONS, THANK_YOU } from "logic/enums/pages";
import { faceSave } from "logic/actions/api/face";
import { isMobile } from "logic/deviceType";
import {
  CAPTURE_HEIGHT,
  CAPTURE_WIDTH,
  FACE_FOUND_STYLE,
  FACE_PASSED_STYLE,
  IDFP_ERROR_MESSAGE,
} from "logic/enums/faceCapture";
import { setGyroscope } from "logic/actions/gyroscope";
import { webView } from "logic/webView";

const START = 0;
const PASS = 2;

const CAPTURE_METHOD = {
  WEBRTC: "WEBRTC",
};

export class PageFaceCapture extends Component {
  static propTypes = {
    setGyroscope: func,
  };
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      formState: START,
      captureMethod: CAPTURE_METHOD.WEBRTC,
      cameraPermission: true,
      retrySensorInit: false,
      messageList: [],
      pending: true,
      isWasmLoaded: this.props.isWasmLoaded,
      videoMetadataLoaded: false,
      showSwitchCameraButton: true,
      shouldDisableCameraButton: true,
      cameraCaptureStarted: false,
      showRemainingTime: false,
    };
    this.onCameraStarted = this.onCameraStarted.bind(this);
  }

  componentDidMount() {
    document.title = `${this.props.t("PageFaceCapture.title")} | Onboarding`;
    if (this.props.mobileAppPlatform === IOS) {
      window.addEventListener("appState", this.appState);
    }
  }

  componentWillUnmount() {
    if (this.video) {
      this.video.onloadedmetadata = null;
      this.video = null;
    }
    clearTimeout(this.redirectTimeout);
    clearTimeout(this.showRemainingTimeTimeout);
    clearTimeout(this.hideRemainingTimeTimeout);
    clearInterval(this.messagePriorityInterval);
    window.removeEventListener("appState", this.appState);
  }

  getBack = () => {
    logEvent(face_capture_back, {
      captureTime: performance.now() - this.state.startTime || 0,
    });
    this.props.previous();
  };

  changeCamera = (cameraId) => {
    this.setState({ shouldDisableCameraButton: true });
    this.props.changeCamera(cameraId);
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      if (this.state.unfocusedPage)
        if (this.props.isGyroscopeActive && this.state.cameraPermission) {
          this.props.FaceCaptureInstance.startCamera();
          this.setState({ canvasClass: "", faceStatus: "" });
        }
      this.setState({ unfocusedPage: false });
    } else {
      try {
        this.props.FaceCaptureInstance.stopCamera();
      } catch (error) {
        console.log("visibility change error", error);
      }
      this.setState({ unfocusedPage: true });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isWasmLoaded !== this.props.isWasmLoaded) {
      if (this.state?.videoMetadataLoaded) {
        this.startFaceDetector();
      }
      this.setState({ isWasmLoaded: true });
    }
  }

  onCameraStarted = (video) => {
    if (this.redirectTimeout) clearTimeout(this.redirectTimeout);
    if (this.showRemainingTimeTimeout) clearTimeout(this.showRemainingTimeTimeout);
    if (this.hideRemainingTimeTimeout) clearTimeout(this.hideRemainingTimeTimeout);
    
    this.video = video;
    this.video.onloadedmetadata = () => {
      if (!this.props.isFacePlusEnabled) {
        if (this.props.FaceCaptureInstance.camera.videoTracks.length) {
          const trackSettings =
            this.props.FaceCaptureInstance.camera.videoTracks[0].getSettings();
          this.setState({
            streamWidth: trackSettings.width,
            streamHeight: trackSettings.height,
          });
          const device = this.props.FaceCaptureInstance.camera.videoTracks[0];
          const { deviceId, groupId } = device.getSettings();
          this.props.setSelectedCamera({
            label: device.label,
            deviceId,
            groupId,
          });
        }
      }
      if (this.props.isWasmLoaded && !this.state.requestPending)
        setTimeout(() => {
          this.startFaceDetector();
        }, 500);

      this.setState({ videoMetadataLoaded: true });
    };

    this.showRemainingTimeTimeout = setTimeout(() => {
      this.setState({ showRemainingTime: true });
      this.hideRemainingTimeTimeout = setTimeout(() => {
        this.setState({ showRemainingTime: false });
      }, 4000);
    }, 30000);

    this.redirectTimeout = setTimeout(() => this.failedToCapture(), 60000);
  };

  failedToCapture = () => {
    if (this.state.pending) {
      this.timeout = setTimeout(() => this.failedToCapture(), 10000);
    } else {
      // we are not relying on retries left anymore, only counting retries
      // if (this.props.retriesLeft) {
      this.props.failedAttempt();
      logEvent(face_capture_timeout, { retryCount: this.props.retryCount });
      // }
      this.props.go(FACE_CAPTURE_INSTRUCTIONS);
    }
  };

  getPriorityMessage() {
    this.messagePriorityInterval = setInterval(() => {
      if (this.state.messageList.length) {
        const highestPriorityMessage = this.state.messageList[0];
        if (highestPriorityMessage) {
          this.setFaceStatus(highestPriorityMessage.code);
          logEvent(face_capture_error, {
            error: highestPriorityMessage.message,
          });

          this.setState({
            message: this.props.t(highestPriorityMessage.messageCode),
            messageList: [],
          });
        }
      }
    }, 900);
  }

  sendToIdx = (base64, encryptedImage) => {
    this.setState({ requestPending: true });
    if (this.state.pending) return;
    this.setState({ pending: true, error: null, canvasClass: "pending" });
    const adjustedBase64 = base64.replace(/^data:image\/(jpeg);base64,/, "");
    this.props
      .faceSave(encryptedImage || adjustedBase64, this.props.isFacePlusEnabled)
      .then(({ errorMessage, isError }) => {
        if (errorMessage) {
          if (errorMessage == IDFP_ERROR_MESSAGE) errorMessage = "";
          this.setState({
            message: isError ? this.props.t("Common.error") : errorMessage,
            pending: false,
            formState: START,
            isError: false,
            canvasClass: "",
            faceStatus: "",
          });
          logEvent(idrnd_liveness_error, {
            errorMessage,
          });
          this.props.idRndFail();
          logEvent(idrnd_liveness_retry, {
            retryCount: this.props.idRndRetryCount,
          });
          this.props.incrementRetryCount();
          if (this.props.livenessRetryCount > this.props.livenessRetries) {
            this.props.go(THANK_YOU);
          } else {
            setTimeout(() => {
              this.props.FaceCaptureInstance.findFace();
              if (this.messagePriorityInterval)
                clearInterval(this.messagePriorityInterval);
              this.getPriorityMessage();
            }, 1500);
          }
        } else {
          this.setState({
            pending: false,
            formState: PASS,
            canvasClass: "success",
          });
          logEvent(idrnd_liveness_passed);
          setTimeout(() => {
            this.props.preview({
              base64,
              isRejected: false,
              captureMethod: this.state.captureMethod,
            });
          }, 300);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (
          error.response &&
          error.response.data &&
          error.response.data.feedback
        ) {
          error.response.data.feedback.forEach(
            (singleError) => (errorMessage += singleError.message)
          );
        } else {
          errorMessage =
            typeof error.response !== "undefined" && error.response.data.message
              ? error.response.data.message
              : this.props.t("Common.error_connection_issues");
        }
        this.setState({
          pending: false,
          message: errorMessage,
          canvasClass: "",
        });
      })
      .finally(() => {
        this.setState({
          requestPending: false,
        });
      });
  };

  startFaceDetector() {
    this.setState({ cameraCaptureStarted: true });
    const fidelityConfig =
      this.props.isEncryptionEnabled && this.props.publicKey
        ? {
            serverPublicKey: this.props.publicKey,
            idxUserId: this.props.user.id,
          }
        : null;
    this.props.FaceCaptureInstance.startFaceDetector(
      {
        onFaceDetectorInitialized: () => {
          this.setState({
            pending: false,
            message: this.props.t("PageFaceCapture.error_face_inside"),
          });
          const timeoutDuration = this.props.showCountdown ? 5500 : 1800;
          setTimeout(() => {
            this.props.FaceCaptureInstance.findFace();
            logEvent(face_capture_started);
            const startTime = performance.now();
            this.setState({ shouldDisableCameraButton: false, startTime });
            if (this.messagePriorityInterval)
              clearInterval(this.messagePriorityInterval);
            this.getPriorityMessage();
          }, timeoutDuration);
        },
        onFaceDetectorError: (err) => {},
        onFaceDetectorFeedback: (detectorFeedbackObject) => {
          let { faceImage, result } = detectorFeedbackObject;
          let encryptedData;
          if (this.props.isFacePlusEnabled) {
            let { encryptedFile } = detectorFeedbackObject;
            encryptedData = encryptedFile;
          } else {
            let { encryptedPayload } = detectorFeedbackObject;
            encryptedData = encryptedPayload;
          }
          if (result === "PASS") {
            clearInterval(this.messagePriorityInterval);
            this.setState({ message: "" });
            logEvent(face_capture_finished, {
              captureTime: performance.now() - this.state.startTime,
            });
            this.sendToIdx(faceImage, encryptedData);
          } else {
            if (detectorFeedbackObject.feedback?.code !== 901) {
              this.setState({ faceFound: true });
            }
            this.setState({
              messageList: [
                detectorFeedbackObject.feedback,
                ...this.state.messageList,
              ],
            });
            this.props.FaceCaptureInstance.findFace();
          }
        },
      },
      fidelityConfig
    );
  }

  setFaceStatus = (code) => {
    if (code === 900) {
      this.setState({ faceStatus: FACE_PASSED_STYLE });
    } else if (code === 901) {
      this.setState({ faceStatus: "" });
    } else {
      this.setState({ faceStatus: FACE_FOUND_STYLE });
    }
  };

  updateGyroscope = (isGyroscopeActive) => {
    this.props.setGyroscope(isGyroscopeActive);
  };

  sensorPermissionDenied = (error) => {
    this.setState({
      cameraPermission: false,
      message: this.props.t("Common." + error.messageCode),
      messageTitle: this.props.t("Common." + error.messageTitle),
      isError: true,
      retrySensorInit: false,
      retryButtonHidden: error.code === 3 ? true : false,
      pending: false,
    });
  };

  retrySensorInit = () => {
    logEvent(retry_sensor_init);
    if (this.props.mobileAppPlatform === IOS) webView(GRANT_CAMERA_PERMISSION);
    if (
      !this.props.isGyroscopeActive ||
      (this.props.isGyroscopeActive && !this.state.cameraPermission)
    )
      this.setState({
        cameraPermission: true,
        retrySensorInit: true,
        message: "",
        messageTitle: "",
        isError: false,
      });
  };

  appState = (e) => {
    try {
      let response = JSON.parse(e.detail.data);
      if (response.appState === FOREGROUND_EVENT) {
        this.props.go(FACE_CAPTURE_INSTRUCTIONS);
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const message = this.state.message;

    return (
      <PageContent
        toggleLoading={this.state.pending}
        isDarkMode={true}
        backButtonClick={this.getBack}
        showBack={!this.props.shouldSkipInstructionsPage}
        justifyContent="center"
        title={this.props.t("PageFaceCapture.title")}
        isScrollDisabled={true}
      >
        <S.CanvasWrapper className={this.state.canvasClass}>
          {this.props.mobileAppPlatform !== IOS && (
            <VisiblityChangeHandler
              onVisibilityChange={this.onVisibilityChange}
            />
          )}
          <FaceCaptureUI
            onCameraStarted={this.onCameraStarted}
            cameraCaptureStarted={
              this.props.showCountdown && this.state.cameraCaptureStarted
            }
            height={CAPTURE_HEIGHT}
            width={CAPTURE_WIDTH}
            className={`overlay  ${!this.state.isError ? "overlay-oval" : ""} ${
              this.state.faceStatus
            }`}
            sensorPermissionDenied={this.sensorPermissionDenied}
            retrySensorInit={this.state.retrySensorInit}
            shouldCheckGyroscope={this.props.shouldCheckGyroscope}
            updateGyroscope={this.updateGyroscope}
          />

          <S.FaceCaptureFooter isError={this.state.isError}>
            {!this.state.isError &&
              this.props.isGyroscopeActive &&
              this.state.cameraPermission && (
                <S.MessageBoxWrapper aria-live="polite">
                  {!this.state.showRemainingTime ? message : this.props.t("Common.time_remaining_message_face")}
                </S.MessageBoxWrapper>
              )}
            {this.state.isError &&
              (!this.props.isGyroscopeActive ||
                !this.state.cameraPermission) && (
                <DaonErrorBox
                  title={this.state.messageTitle}
                  message={message}
                  style={{ margin: "0 auto 30px auto" }}
                />
              )}
            {!this.state.pending &&
              (!this.props.isGyroscopeActive || !this.state.cameraPermission) &&
              !this.state.retryButtonHidden && (
                <DaonButton
                  onClick={this.retrySensorInit}
                  style={{ margin: "0 auto" }}
                >
                  {!this.props.isGyroscopeActive
                    ? this.props.t("Common.confirm_motion_sensors_permission")
                    : this.props.t("Common.confirm_camera_permission")}
                </DaonButton>
              )}
            {!isMobile(navigator.userAgent) && (
              <CaptureButtonPanel
                onChangeCamera={this.changeCamera}
                showSwitchCameraButton={this.state.showSwitchCameraButton}
                disabled={this.state.formState !== START}
                shouldHideCaptureButton={true}
                shouldDisableCameraButton={this.state.shouldDisableCameraButton}
              />
            )}
          </S.FaceCaptureFooter>
        </S.CanvasWrapper>
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageFaceCapture);
export default connect(
  (state) => {
    let shouldCheckGyroscope = "NONE",
      isEncryptionEnabled = false;
    const passiveLivenessRetries =
      state.configuration.extraConfig?.steps?.face?.options
        ?.passiveLivenessRetries ?? 5;
    const isFacePlusEnabled =
      state.configuration.extraConfig?.steps?.face?.options?.isFacePlusEnabled;
    const showCountdown =
      state.configuration.extraConfig?.featureFlags?.showCountdown ?? false;
    if (state.configuration.extraConfig?.steps?.face?.options) {
      shouldCheckGyroscope =
        state.configuration.extraConfig?.steps?.face?.options
          .shouldCheckGyroscope;
      isEncryptionEnabled =
        state.configuration.extraConfig?.steps?.face?.options
          .isEncryptionEnabled;
    }
    const shouldSkipInstructionsPage =
      state.configuration.extraConfig?.steps?.face?.options
        ?.shouldSkipInstructionsPage;

    const { isGyroscopeActive } = state.gyroscope;
    const { retriesLeft, retryCount, idRndRetryCount, livenessRetryCount } =
      state.livenessTest;
    const { publicKey } = state.fidelity;
    const { mobileAppPlatform } = state.composite;
    return {
      shouldCheckGyroscope: shouldCheckGyroscope || "NONE",
      isEncryptionEnabled,
      retriesLeft,
      retryCount,
      shouldSkipInstructionsPage,
      livenessRetryCount,
      livenessRetries: passiveLivenessRetries || 5,
      isFacePlusEnabled,
      showCountdown: showCountdown,
      idRndRetryCount,
      isGyroscopeActive,
      publicKey,
      mobileAppPlatform,
      ...state.faceCapture,
      ...state.application,
    };
  },
  {
    faceSave,
    preview,
    previous,
    changeCamera,
    failedAttempt,
    idRndFail,
    toggleNetworkWarnings,
    setSelectedCamera,
    incrementRetryCount,
    go,
    setGyroscope,
  }
)(componentWithTranslation);