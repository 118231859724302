export const applicationPutPhoneMock = {
  id: "409f1531-e361-4619-88fd-3dc04d02d8bb",
  user: {
    id: "QTAzj2qI0kvX5HHQFB0knyLVTw",
    userId: "0559ee6b-1797-4dc5-aca8-f984901ce971",
    idxFriendlyUserId: "g0n1klsc",
    primaryPhone: "+353563845794",
    href: "https://dobsdev-oidc-dobs.identityx-build.com/drag3338/IdentityXServices/rest/v1/users/QTAzj2qI0kvX5HHQFB0knyLVTw",
    idCheck: {
      id: "6Pg5Ohpad-cPIvA2nyOvnw",
      referenceId: "+381643242419@2022-02-18T15:25:25.306Z",
      href: "https://dobsdev-oidc-dobs.identityx-build.com/drag3338/DigitalOnBoardingServices/rest/v1/users/QTAzj2qI0kvX5HHQFB0knyLVTw/idchecks/6Pg5Ohpad-cPIvA2nyOvnw",
      type: "Account Opening",
    },
  },
  tenantDomainName: "drag3338",
  interruptIDPAuth: false,
  verificationCode: 0,
  languageTag: "en-US",
  countryCode: "rs",
  cancelled: false,
};
