export function isAndroid(userAgent) {
  return !!userAgent.match(/(Android)/i);
}

export function isMobile(userAgent) {
  return !!userAgent.match(
    /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i
  );
}

export function isIOS(userAgent) {
  // const isSafari = !!userAgent.match(/Version\/[\d.]+.*Safari/);
  const iOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  return iOS;
}

export const isSafari = (userAgent) => {
  return !!userAgent.match(/Version\/[\d.]+.*Safari/);
};

export function isMacOS() {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
}

export function isChrome(userAgent) {
  return !!userAgent.match(/chrome|chromium|crios/i);
}
export function isMiUi(userAgent) {
  return !!userAgent.match(/miui/i);
}

export const isOpera = (userAgent) => {
  return !!userAgent.match(/opr\/|opt\//i);
};

export const isFirefox = (userAgent) => {
  return !!userAgent.match(/firefox|fxios/i);
};

export const isAppleWebKit = (userAgent) => {
  return !!userAgent.match(/AppleWebKit/i);
};

export const checkIOSNotSafariVersion = (userAgent) => {
  const iOS = isIOS(userAgent);
  const safari = isSafari(userAgent);
  const version = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);

  if (iOS && !safari) return version[1] + "." + version[2];
};

export const checkBrowserCompatibility = (userAgent) => {
  const appleWebKit = isAppleWebKit(userAgent);
  const safariAgent = isSafari(userAgent);
  const opera = isOpera(userAgent);
  const chromeAgent = isChrome(userAgent);
  const firefox = isFirefox(userAgent);
  const unsupportedMobileBrowser = opera || firefox;

  if (!isMobile(userAgent))
    return (safariAgent || chromeAgent || firefox || opera);

  if (isIOS(userAgent))
    return (
      (safariAgent || chromeAgent || appleWebKit) && !unsupportedMobileBrowser
    );

  return chromeAgent && !opera;
};
