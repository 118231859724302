import * as S from "./InstructionVideos.styles";
import { useEffect, useRef } from "react";

const InstructionVideo = ({ video, title, alt }) => {
  return (
    <S.ImageWrapper>
      <img src={video} alt={alt} title={title}></img>
    </S.ImageWrapper>
  );
};

export default InstructionVideo;
