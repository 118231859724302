import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* Position it absolutely within the video container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
`;

export const Message = styled.h3`
  text-align: center;
  color: white;
`;

export const Countdown = styled.h2`
  margin-top: 10px;
  text-align: center;
  color: white;
`;
