export const ANDROID = "ANDROID";
export const IOS = "IOS";
export const ON_CHECK_DOCUMENT_OCR = "onCheckDocumentOCR";
export const WEB_LOADED = "webLoaded";
export const NFC_SCAN = "nfcScan";
export const NFC_SCAN_WITH_CHALLENGE = "nfcScanWithChallenge";
export const DAON_CALLBACK = "daonCallback";
export const ENABLE_NFC = "enableNFCSetting";
export const NFC_SCAN_TIMEOUT = "nfcScanTimeout";
export const NFC_IS_AVAILABLE = "checkNFCAvailability";
export const PAGE_INTRO_BACK_PRESS = "pageIntroBackPress";
export const FOREGROUND_EVENT = "foreground";
export const BACKGROUND_EVENT = "background";
export const LANGUAGE_TAG = "languageTag";
export const GRANT_CAMERA_PERMISSION = "grantCameraPermission";

export const AUTO_APPROVED_STATUS_CODE = 100;
export const AUTO_DECLINED_STATUS_CODE = 101;
export const REVIEW_PENDING_STATUS_CODE = 102;
export const DEFAULT_STATUS_CODE = 103;
export const ONLINE_STATUS_CODE = 200;
export const OFFLINE_STATUS_CODE = 201;
export const BAD_REQUEST_STATUS_CODE = 400;
export const SESSION_EXPIRED_STATUS_CODE = 401;
export const VERSION_BELLOW_MIN = 601;
export const VERSION_NOT_LATEST = 600;
export const VERSION_NOT_CHECKED = 602;

export const DIGEST_ALGORITHM = "SHA1";
export const SIGNATURE_ALGORITHM = "SHA1WithRSA/ISO9796-2";
export const MRZ_CHECK_ERROR_MESSAGE = "We cannot read data from your document";
