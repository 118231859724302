import styled from "styled-components/macro";

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.h2`
  color: ${(props) => props.color};
  text-align: center;
`;
