import { connect } from "react-redux";

import { go } from "logic/actions/navigation"

export function DebugLinks({ go, pages }) {
  const items = Object.keys(pages).map(key => <li key={key}>
    <button className="button-link" onClick={() => go(key)}>{key.replace("Page", "")}</button>
  </li>);

  return <ul style={{ position: "absolute", left: 0, zIndex: 100 }}>{items}</ul>
}

export default connect(null, { go })(DebugLinks);