import checkboxIcon from "../../../assets/icons/16-24/icon-check.svg";
import styled from "styled-components";

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
    border: none;
    width: 0px;
    height: 0px;
    outline: unset;
    &:focus {
      visibility: none;
    }
  & + label {
    display: inline-block;
    padding: 0 0 0 0px;

    background-size: 100%;
    background-repeat: no-repeat;
    height: 30px;
    min-width: 30px;
    background-color: ${(props) => props.theme.panelBackground};
    border-radius: 8px;
    position: relative;
    top: 13px;
    align-self: center;
    margin-right: 1rem;
    z-index: 49;
    cursor: pointer;
  }

  &:checked + label:before {
    content: " ";
    background-image: url(${checkboxIcon});
    display: inline-block;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    position: absolute;
    left: 3px;
    top: 5px;
    z-index: 51;
  }

  &:focus + label {
    border: thin solid black;
  }
`;
