import { Component } from "react";
import { connect } from "react-redux";
import { func, string } from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PageContent from "../../../PageContent";
import ImagePreview from "../../common/ImagePreview";
import {
  retry,
  retryProcessing,
  setDocumentId,
  increaseRetryCounter,
  setDocumentNumber,
  setDocumentExpiryDate,
  setDocumentDateOfBirth,
} from "logic/actions/documentCapture";
import { go, nextStep } from "logic/actions/navigation";
import { documentsSave } from "logic/actions/api/document";
import {
  validateDate,
  validateInput,
  validateDateOfBirth,
} from "logic/validators";
import {
  logEvent,
  document_capture_retry,
  document_processing_retry,
} from "logic/eventLogger";
import { isMobile } from "logic/deviceType";
import { withTranslation } from "react-i18next";
import * as S from "./PageDocumentCaptureEditDocument.styles";
import DaonButton from "../../../common/DaonButton";
import DaonErrorBox from "Components/common/DaonErrorBox";
import SubmitDocument from "Components/modules/common/SubmitDocument";
import { PASSPORT } from "logic/enums/document";
import { steps } from "logic/steps";
import { NFC_STEP } from "logic/enums/steps";

export class PageDocumentCaptureEditDocument extends Component {
  static propTypes = {
    retry: func,
    base64CroppedFirstPage: string,
    base64CroppedSecondPage: string,
    documentType: string,
    documentsSave: func,
  };

  constructor(props) {
    super(props);
    this.handleFormSubmit.bind(this);
    let documentNumberError = validateInput(this.props.documentNumber);
    let documentExpiryDateError = validateDate(this.props.documentExpiryDate);
    let documentDateOfBirthError = validateDateOfBirth(
      this.props.documentDateOfBirth
    );

    this.state = {
      serverProcessingFailed: false,
      imageLoaded: false,
      error: null,
      message: null,
      calledCount: 0,
      messageFromIos: undefined,
      nfc: {},
      documentExpiryDate: this.props.documentExpiryDate,
      documentNumber: this.props.documentNumber,
      documentDateofBirth: this.props.documentDateOfBirth,
      documentNumberError,
      documentExpiryDateError,
      documentDateOfBirthError,
    };
    this.getBack.bind(this);
    this.submit.bind(this);
  }

  componentDidMount() {
    document.title = `${this.props.t(
      "PageDocumentCapture.title"
    )} | Onboarding`;
  }
  static getDerivedStateFromProps({
    base64CroppedFirstPage,
    base64CroppedSecondPage,
  }) {
    return {
      base64: base64CroppedSecondPage || base64CroppedFirstPage,
    };
  }

  getBack = () => {
    logEvent(document_capture_retry, {
      documentType: this.props.documentType,
      documentCountry: this.props.dialCountryNum,
      retryCount: this.props.retries + 1,
    });
    this.props.retry();
  };

  retryCapturing = () => {
    logEvent(document_processing_retry, {
      documentType: this.props.documentType,
      documentCountry: this.props.dialCountryNum,
      retryCount: this.props.documentProcessingRetriesCounter + 1,
    });
    this.props.retryProcessing();
  };

  onErrorHandler = ({ pending, serverProcessingFailed = true }) => {
    this.setState({ pending, serverProcessingFailed });
  };

  submit = () => {
    this.setState({ pending: true, calledCount: this.state.calledCount + 1 });
  };

  handleDocumentNumberChange = (event) => {
    const newDocumentNumber = event.target.value;
    this.setState({
      documentNumber: newDocumentNumber,
      documentNumberError: validateInput(newDocumentNumber),
    });
    this.props.setDocumentNumber(newDocumentNumber);
  };

  handleDateChange = (value, type) => {
    const formattedDate = dayjs(value).format("YYYY-MM-DD");

    if (type == "expiryDate") {
      this.setState({
        documentExpiryDate: formattedDate,
        documentExpiryDateError: validateDate(value),
      });
      this.props.setDocumentExpiryDate(formattedDate);
    } else {
      this.setState({
        documentDateOfBirth: formattedDate,
        documentDateOfBirthError: validateDateOfBirth(value),
      });
      this.props.setDocumentDateOfBirth(formattedDate);
    }
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  render() {
    let documentsObject = {
      base64CroppedFirstPage: this.props.base64CroppedFirstPage,
      base64CroppedSecondPage: this.props.base64CroppedSecondPage,
      base64UnprocessedFirstPage: this.props.base64UnprocessedFirstPage,
      base64UnprocessedSecondPage: this.props.base64UnprocessedSecondPage,
    };

    let isNfcStepPresent = steps.findIndex((x) => x.name === NFC_STEP) > -1;

    let shouldScanNfc =
      this.props.mobileAppPlatform &&
      isNfcStepPresent &&
      this.props.isNFCReady &&
      (this.props.singlePaged ||
        this.props.documentType === PASSPORT ||
        this.props.base64CroppedSecondPage);
    let text;
    if (this.props.isRejected && !this.props.allRetriesFailed) {
      text = this.props.t("PageDocumentCaptureConfirm.error_please_retake");
    } else if (!this.props.allRetriesFailed) {
      text = this.props.t("PageDocumentCaptureConfirm.error_ensure_visible");
    } else {
      text = this.props.t(
        "PageDocumentCaptureConfirm.error_unable_check_quality"
      );
    }

    return (
      <PageContent
        toggleLoading={this.state.pending}
        isDarkMode={true}
        title={this.props.t(
          "PageDocumentCaptureEditDocument.title_confirm_details"
        )}
        backButtonClick={this.getBack}
      >
        <S.WrapperDiv
          onClick={this.toggleImage}
          id="wrapperDiv"
          isNative={this.props.captureMethod === "NATIVE"}
        >
          <div style={{ margin: "1em" }}></div>
          <ImagePreview
            previewImage={this.state.base64}
            style={{ margin: "1em 0" }}
          />
          <S.MessageParagraph>
            {this.props.t(
              "PageDocumentCaptureEditDocument.confirm_details_card_number"
            )}
          </S.MessageParagraph>
          {(this.props.message ||
            this.state.documentExpiryDateError ||
            this.state.documentNumberError ||
            this.state.documentDateOfBirthError) && (
            <DaonErrorBox
              style={{ alignSelf: "center" }}
              message={
                this.props.message ||
                this.state.documentExpiryDateError ||
                this.state.documentNumberError ||
                this.state.documentDateOfBirthError
              }
            ></DaonErrorBox>
          )}
          <S.ConfirmInputsDiv>
            <S.MessageParagraph style={{ width: "50%" }}>
              {this.props.t("PageDocumentCaptureEditDocument.card_number")}
            </S.MessageParagraph>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "16ch" },
              }}
              onSubmit={this.handleFormSubmit}
              noValidate
              autoComplete="off"
            >
              <S.StyledTextField
                id="outlined-basic"
                variant="outlined"
                value={this.state.documentNumber}
                onChange={this.handleDocumentNumberChange}
              />
            </Box>
          </S.ConfirmInputsDiv>

          <S.MessageParagraph>
            {this.props.t(
              "PageDocumentCaptureEditDocument.confirm_details_expiry_date"
            )}
          </S.MessageParagraph>
          <S.ConfirmInputsDiv>
            <S.MessageParagraph style={{ width: "50%" }}>
              {this.props.t("PageDocumentCaptureEditDocument.expiry_date")}
            </S.MessageParagraph>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <S.CalendarDiv>
                <S.StyledDateField
                  format="DD/MM/YYYY"
                  sx={{
                    "& > :not(style)": { width: "100%" },
                  }}
                  value={dayjs(this.state.documentExpiryDate)}
                  onChange={(newValue) =>
                    this.handleDateChange(newValue, "expiryDate")
                  }
                />
              </S.CalendarDiv>
            </LocalizationProvider>
          </S.ConfirmInputsDiv>

          <S.MessageParagraph>
            {this.props.t(
              "PageDocumentCaptureEditDocument.confirm_details_date_of_birth"
            )}
          </S.MessageParagraph>
          <S.ConfirmInputsDiv>
            <S.MessageParagraph style={{ width: "50%" }}>
              {this.props.t("PageDocumentCaptureEditDocument.date_of_birth")}
            </S.MessageParagraph>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <S.CalendarDiv>
                <S.StyledDateField
                  format="DD/MM/YYYY"
                  sx={{
                    "& > :not(style)": { width: "100%" },
                  }}
                  value={dayjs(this.state.documentDateofBirth)}
                  onChange={(newValue) =>
                    this.handleDateChange(newValue, "dateOfBirth")
                  }
                />
              </S.CalendarDiv>
            </LocalizationProvider>
          </S.ConfirmInputsDiv>
        </S.WrapperDiv>

        {this.state.calledCount > 0 && (
          <SubmitDocument
            style={{ alignSelf: "center" }}
            notWhiteBackground={true}
            key={this.state.calledCount}
            errorHandler={this.onErrorHandler}
            documentsObject={documentsObject}
            documentType={this.props.documentType}
            isNFCReady={this.props.isNFCReady}
            mobileAppPlatform={this.props.mobileAppPlatform}
          />
        )}

        {this.state.serverProcessingFailed ? (
          <S.ButtonsWrapper>
            <DaonButton
              disabled={this.state.pending}
              style={{ margin: "0 auto" }}
              onClick={this.retryCapturing}
              id="retry"
            >
              {this.props.t("PageDocumentCaptureConfirm.btn_retry_capturing")}
            </DaonButton>
          </S.ButtonsWrapper>
        ) : (
          <S.ButtonsWrapper>
            <DaonButton
              disabled={
                this.state.pending ||
                this.state.documentNumberError ||
                this.state.documentExpiryDateError ||
                this.state.documentDateOfBirthError
              }
              style={
                !isMobile(navigator.userAgent)
                  ? { margin: "1rem" }
                  : { marginTop: "1rem" }
              }
              onClick={
                shouldScanNfc ? () => this.props.nextStep() : this.submit
              }
              id="submit_photo"
            >
              {this.props.t("Common.proceed")}
            </DaonButton>
          </S.ButtonsWrapper>
        )}
      </PageContent>
    );
  }
}
const componentWithTranslation = withTranslation()(
  PageDocumentCaptureEditDocument
);
export default connect(
  (state) => {
    const {
      allRetriesFailed,
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      base64UnprocessedFirstPage,
      base64UnprocessedSecondPage,
      documentType,
      isNFCReady,
      aspectRatio,
      message,
      isRejected,
      processingRetriesCounter,
      documentProcessingRetriesCounter,
      retries,
      summaryScorePresent,
      documentExpiryDate,
      documentNumber,
      documentDateOfBirth,
    } = state.documentCapture;

    const { dialCountryNum } = state.documentCaptureConfiguration;

    const { mobileAppPlatform } = state.composite;
    const { featureFlags } = state.configuration.extraConfig;

    return {
      allRetriesFailed,
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      base64UnprocessedFirstPage,
      base64UnprocessedSecondPage,
      documentType,
      aspectRatio,
      isNFCReady,
      mobileAppPlatform,
      dialCountryNum,
      message,
      isRejected,
      processingRetriesCounter,
      documentProcessingRetriesCounter,
      retries,
      summaryScorePresent,
      documentExpiryDate,
      documentNumber,
      documentDateOfBirth,
      singlePaged: state.documentCaptureConfiguration.singlePaged,
      shouldNotSendUnprocessedDocuments:
        featureFlags?.shouldNotSendUnprocessedDocuments,
      documentProcessorRetries:
        featureFlags?.documentProcessorRetries ||
        state.configuration.extraConfig.steps?.document?.options
          ?.documentProcessorRetries ||
        3,
    };
  },
  {
    retry,
    retryProcessing,
    documentsSave,
    go,
    nextStep,
    increaseRetryCounter,
    setDocumentId,
    setDocumentNumber,
    setDocumentExpiryDate,
    setDocumentDateOfBirth,
  }
)(componentWithTranslation);
