import { SMS } from "logic/enums/verification";
import { axiosInstance } from "./axiosInstance";

export function applicationGet(applicationId) {
  return axiosInstance.get(`/rest/v1/accountapplications/${applicationId}`);
}

export function getStart(applicationId, code, type) {
  return axiosInstance.get(
    `/start?accountApplicationId=${applicationId}&type=${type}&code=${code}`
  );
}

export function applicationQRValidate(link) {
  return fetch(`${link}`);
}
export function cancelConsent(request) {
  return axiosInstance.post(
    `/cancel`,
    request
  );
}
export function applicationUpdate(
  applicationId,
  verificationType,
  shouldProceedOnMobile,
  request
) {
  let requestVerificationType;
  if (verificationType === SMS) {
    requestVerificationType = shouldProceedOnMobile ? "smsLink" : "smsCode";
  } else requestVerificationType = verificationType;
  return axiosInstance.post(
    `/rest/v1/accountapplications/${applicationId}/verifications/${requestVerificationType}`,
    request
  );
}

export function applicationUpdateParams(applicationId, request) {
  return axiosInstance.post(
    `/rest/v1/accountapplications/${applicationId}`,
    request
  );
}

export function applicationVerify(applicationId, request) {
  return axiosInstance.post(
    `/rest/v1/accountapplications/${applicationId}/verifications/validate`,
    request
  );
}

export function applicationSubmit(
  applicationId,
  evaluationPolicyName,
  request
) {
  return axiosInstance.post(
    `/rest/v1/accountapplications/${applicationId}/submit?evaluationPolicyName=${evaluationPolicyName}`,
    request
  );
}

export function applicationStatus(applicationId, request, signal) {
  return axiosInstance.post(
    `/rest/v1/accountapplications/${applicationId}/status`,
    request,
    { signal }
  );
}
