import ProcessingOverlay from "./ProcessingOverlay";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { previous } from "../../logic/actions/navigation";
import Version from "../Debug/Version";
import ProgressSteper from "./ProgressSteper";
import * as S from "./PageContent.styles";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../logic/deviceType";
import ButtonBack from "Components/common/ButtonBack";
import SelectLanguage from "Components/common/SelectLanguage";
import { ThemeContext } from "styled-components";
import PoweredByDaonAbsolute from "Components/common/PoweredByDaonAbsolute";
import { selectMobileAppPlatform } from "logic/reducers/composite";
import { ANDROID } from "logic/enums/composite";
import { getDebugLinksData } from "logic/api/debugLinks";
import { DEBUG_LINKS_POSSIBILITY } from "logic/enums/debugLinks";
import QRProgressStepper from "Components/PageIntroScreen/ProgressStepper";
import WhitelabelCompanyLogo from "Components/common/WhitelabelCompanyLogo/WhitelabelCompanyLogo";

export default function PageContent({
  toggleLoading,
  loadingText,
  children,
  title,
  showBack = true,
  isDarkMode,
  backButtonClick,
  justifyContent,
  hideFooter,
  instructions,
  isScrollDisabled,
  isIntroScreen,
  isQrScanStep,
  backgroundSideDiv,
  hideHeader,
}) {
  const dispatch = useDispatch();

  const debugLinks = useSelector((state) => {
    if (
      state.configuration.extraConfig &&
      state.configuration.extraConfig.featureFlags
    ) {
      return state.configuration.extraConfig.featureFlags.debugLinks;
    }
  });

  const mobileAppPlatform = useSelector(selectMobileAppPlatform);

  const [debugLinksPossibility, setDebugLinksPossibility] = useState(false);

  useEffect(() => {
    if (mobileAppPlatform === ANDROID)
      window.onMobileBackPressed = onMobileBackPressed;

    if (process.env.NODE_ENV === "production")
      getDebugLinksData()
        .then((debugLinksData) => {
          setDebugLinksPossibility(debugLinksData[DEBUG_LINKS_POSSIBILITY]);
        })
        .catch(() => {});

    return () => {
      if (mobileAppPlatform === ANDROID) window.onMobileBackPressed = null;
    };
  }, []);

  function onMobileBackPressed() {
    //handles back button press on android
    if (showBack) {
      if (typeof backButtonClick === "function") backButtonClick();
      else dispatch(previous());
    }
  }

  const localDebugLinks =
    process.env.REACT_APP_DEBUG_LINKS === "true" ||
    process.env.REACT_APP_DEBUG_LINKS === true;

  const shouldShowVersion = localDebugLinks || debugLinksPossibility;

  const showApiVersionInfo =
    localDebugLinks || (debugLinksPossibility && !!debugLinks);

  const mobileDevice = isMobile(navigator.userAgent);
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  let backgroundColor = theme.lightBackground;
  if (isDarkMode) {
    backgroundColor = isMobile(navigator.userAgent)
      ? theme.grayBackground
      : theme.darkBackground;
  }
  document.body.style = `background: ${backgroundColor};`;

  return (
    <S.WrapperDiv
      isScrollDisabled={isScrollDisabled}
      marginLeft={isQrScanStep ? "40px" : ""}
    >
      {!isIntroScreen && (
        <S.SideDiv
          backgroundColor={backgroundSideDiv}
          minWidth={isQrScanStep ? "300px" : "250px"}
          justifyContent={isQrScanStep ? "start" : "center"}
        >
          {isQrScanStep ? (
            <div>
              <S.Logo>
                {" "}
                <WhitelabelCompanyLogo logoSize={40} />
              </S.Logo>
              <S.SideTitle>{t("PageIntro.title")}</S.SideTitle>
              <p>{t("PageIntro.verify_explanation")}</p>
              <S.SideSubtitle>
                <h2>{t("PageIntro.stepper_title")}</h2>
              </S.SideSubtitle>
              <QRProgressStepper ommitDesktopSteps ommitVertLine />
            </div>
          ) : (
            <div>
              <ProgressSteper />
              <S.BottomDiv>
                <PoweredByDaonAbsolute />
              </S.BottomDiv>
            </div>
          )}
        </S.SideDiv>
      )}
      <S.MainContentDiv>
        <ProcessingOverlay
          loadingText={loadingText}
          toggleLoading={toggleLoading}
          isDarkMode={isDarkMode}
        >
          {mobileDevice && (
            <Header
              title={title}
              isDarkMode={isDarkMode && !hideHeader}
              onClick={backButtonClick}
              showBack={showBack}
              instructions={instructions}
              isIntroScreen={isIntroScreen}
              hideHeader={hideHeader}
            />
          )}
          <S.PageContent justifyContent={justifyContent} isIntroScreen={isIntroScreen}>
            {!mobileDevice && (
              <>
                {showBack && (
                  <S.BackButtonWrapper>
                    <ButtonBack onClick={backButtonClick} />
                  </S.BackButtonWrapper>
                )}
                <S.ActionHeader>
                  {instructions && <SelectLanguage />}
                </S.ActionHeader>
              </>
            )}
            {children}
            {isMobile(navigator.userAgent)}
          </S.PageContent>
          {shouldShowVersion && (
            <Version showApiVersionInfo={showApiVersionInfo} />
          )}
        </ProcessingOverlay>
      </S.MainContentDiv>
    </S.WrapperDiv>
  );
}
