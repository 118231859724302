import {
  GDPR,
  INTRO_PAGE,
  VALIDATE_PHONE,
  VERIFICATION_CODE,
  FACE_CAPTURE,
  FACE_CAPTURE_INSTRUCTIONS,
  FACE_CAPTURE_CONFIRM,
  DOCUMENT_CAPTURE_INSTRUCTIONS,
  DOCUMENT_CAPTURE_PORTRAIT,
  DOCUMENT_CAPTURE_CONFIRM,
  THANK_YOU,
  VERIFY_IDENTITY,
  CONTINUE_ON_DESKTOP,
  DECLINED,
  APPROVED,
  DOCUMENT_COUNTRY_SELECTOR,
  REVIEWING,
  CREATE_CHALLENGE,
  PAGE_SESSION_EXPIRED,
  NFC_INSTRUCTIONS,
  NFC_SCAN,
  NFC_DISABLED,
  NFC_NOT_SUPPORTED,
  NFC_TIMEOUT,
  NFC_FAILED,
  NFC_INTERRUPTED,
} from "./enums/pages";
import {
  STEPER_CONSENT,
  STEPER_DOCUMENT_CAPTURE,
  STEPER_FACE_CAPTURE,
  STEPER_NFC,
  STEPER_SWITCH_TO_MOBILE,
  STEPER_PHONE_VERIFICATION,
  STEPER_THANK_YOU,
} from "./enums/progressStepperTitles";
import phoneIcon from "../assets/icons/16-24/Phone-ok.svg";
import selfieIcon from "../assets/icons/16-24/Selfie.svg";
import documentCaptureIcon from "../assets/icons/16-24/ID-card1.svg";
import consentIcon from "../assets/icons/16-24/consent.svg";
import thankYouIcon from "../assets/icons/16-24/thank-you.svg";
import {
  COMPLETE_STEP,
  CONSENT_STEP,
  DOCUMENT_STEP,
  FACE_STEP,
  INTRO_STEP,
  PHONE_STEP,
  NFC_STEP,
} from "./enums/steps";
import { ANDROID, IOS } from "./enums/composite";
import { NONE } from "./enums/verification";

export let steps = [];

export const allSteps = [
  {
    name: INTRO_STEP,
    pages: [INTRO_PAGE],
    hide: true,
  },
  {
    name: CONSENT_STEP,
    title: STEPER_CONSENT,
    pages: [GDPR],
    icon: consentIcon,
  },
  {
    name: PHONE_STEP,
    title: STEPER_PHONE_VERIFICATION,
    pages: [VALIDATE_PHONE, VERIFICATION_CODE, CREATE_CHALLENGE],
    icon: phoneIcon,
  },
  {
    name: FACE_STEP,
    title: STEPER_FACE_CAPTURE,
    pages: [FACE_CAPTURE_INSTRUCTIONS, FACE_CAPTURE, FACE_CAPTURE_CONFIRM],
    icon: selfieIcon,
  },
  {
    name: DOCUMENT_STEP,
    title: STEPER_DOCUMENT_CAPTURE,
    pages: [
      VERIFY_IDENTITY,
      DOCUMENT_COUNTRY_SELECTOR,
      DOCUMENT_CAPTURE_INSTRUCTIONS,
      DOCUMENT_CAPTURE_PORTRAIT,
      DOCUMENT_CAPTURE_CONFIRM,
    ],
    icon: documentCaptureIcon,
  },
  {
    name: NFC_STEP,
    title: STEPER_NFC,
    pages: [
      NFC_INSTRUCTIONS,
      NFC_SCAN,
      NFC_DISABLED,
      NFC_NOT_SUPPORTED,
      NFC_TIMEOUT,
      NFC_FAILED,
      NFC_INTERRUPTED,
    ],
    icon: thankYouIcon,
  },
  {
    name: COMPLETE_STEP,
    title: STEPER_THANK_YOU,
    hide: true,
    pages: [
      THANK_YOU,
      CONTINUE_ON_DESKTOP,
      APPROVED,
      DECLINED,
      REVIEWING,
      PAGE_SESSION_EXPIRED,
      CONTINUE_ON_DESKTOP,
    ],
    icon: thankYouIcon,
  },
];

export function initSteps() {
  steps = allSteps;
}

export function switchingToMobileStepsHandler(platform) {
  const phoneStepIndex = steps.findIndex((x) => x.name === PHONE_STEP);
  if (phoneStepIndex > -1) {
    if (platform === ANDROID || platform === IOS) {
      steps.splice(phoneStepIndex, 1);
      ommitIntroStep();
    } else steps.splice(0, phoneStepIndex + 1);
  }
}

export function setPhoneLabel(switchToMobile) {
  if (switchToMobile === NONE) return;
  const phoneStepIndex = steps.findIndex((x) => x.name === PHONE_STEP);
  steps[phoneStepIndex].title = STEPER_SWITCH_TO_MOBILE;
}

export function ommitIntroStep() {
  const introStepIndex = steps.findIndex((x) => x.name === INTRO_STEP);
  if (introStepIndex > -1) steps.splice(introStepIndex, 1);
}

export function ommitNFCStep() {
  const nfcStepIndex = steps.findIndex((x) => x.name === NFC_STEP);
  if (nfcStepIndex > -1) steps.splice(nfcStepIndex, 1);
}

export function handleValidationPages({ start, deleteCount, validationPages }) {
  const validation = steps.findIndex((x) => x.name === PHONE_STEP);
  if (validation > -1) {
    validationPages
      ? steps[validation].pages.splice(start, deleteCount, ...validationPages)
      : steps[validation].pages.splice(start, deleteCount);
  }
}

export function omitConsentStep() {
  const consentStep = allSteps.findIndex((x) => x.name === CONSENT_STEP);
  if (consentStep > -1) allSteps.splice(consentStep, 1);
}

export function getStepAndIndex(currentPage, step) {
  if (!steps[step]) {
    step = getPageStep(currentPage);
    if (step < 0)
      throw Error("Cannot find page " + currentPage + " in form steps");
  }
  let index = steps[step].pages.indexOf(currentPage);
  if (index === -1) throw Error("Cannot find page in steps array");
  return { step, index };
}

export function previous(currentPage, currentStep) {
  const { step, index } = getStepAndIndex(currentPage, currentStep);
  return (
    steps[step].pages[index - 1] ||
    (step && steps[step - 1].pages[0]) ||
    INTRO_PAGE
  );
}

export function nextPage(currentPage, currentStep) {
  const { step, index } = getStepAndIndex(currentPage, currentStep);
  return (
    steps[step].pages[index + 1] ||
    (steps.length > step && steps[step + 1].pages[0]) ||
    INTRO_PAGE
  );
}

/** Navigates to next step, no matter index of current page */
export function nextStep(currentPage) {
  const step = getPageStep(currentPage);

  return (
    (steps.length > step &&
      steps[step + 1].pages &&
      steps[step + 1].pages[0]) ||
    INTRO_PAGE
  );
}

export function goToStepNumber(stepNumber) {
  return (steps[stepNumber].pages && steps[stepNumber].pages[0]) || INTRO_PAGE;
}

export function getPageStep(page) {
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].pages && steps[i].pages.includes(page)) {
      return i;
    }
  }
  return -1;
}
