/*global Daon*/
/*eslint no-undef: "error"*/

const actionPrefix = "FACE_CAPTURE_";

export const CREATE_INSTANCE = actionPrefix + "CREATE_INSTANCE";
export const PREVIEW = actionPrefix + "PREVIEW";
export const RETRY = actionPrefix + "RETRY";
export const SET_IS_WASM_LOADED = actionPrefix + "SET_IS_WASM_LOADED";

export function createFaceCaptureInstance({ faceConfig, isFacePlusEnabled }) {
  return {
    type: CREATE_INSTANCE,
    payload: isFacePlusEnabled
      ? new Daon.FaceCapturePlus(faceConfig)
      : new Daon.FaceCapture(faceConfig),
  };
}

export function setIsFaceWasmLoaded(payload) {
  return {
    type: SET_IS_WASM_LOADED,
    payload,
  };
}

export function preview(payload) {
  return {
    type: PREVIEW,
    payload,
  };
}

export function retry() {
  return { type: RETRY };
}
