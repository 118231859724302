import styled from "styled-components/macro";

export const Wrapper = styled.div`
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PhoneImg = styled.img`
  max-width: 70%;
  margin: 0 auto auto auto;
  border: solid black 0.5px;
  border-radius: 5%;
`;

export const Content = styled.div`
  margin: 1rem;
  h2 {
    text-align: center;
    font-size: 2.5em;
  }
`;

export const LogoDiv = styled.div`
  margin-top: 2em;
`;

export const LinkText = styled.a`
  color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  display: inline-block;
  text-decoration-line: underline;
  margin-left: 12px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7%;
  margin-right: 7%;
  & > button {
    max-width: 500px;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  font-size: 1.3em;
  font-weight: 400;
  margin: 2em auto 0.5em;
`;

export const TryAgainParagraph = styled.p`
  text-align: center;
  margin-top: -16px;
`;
