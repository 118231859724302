import { axiosInstance } from "./axiosInstance";

export function logout() {
  return axiosInstance.post(`/logout`);
}

export const destroySessionAndNavigate = (redirectUrl) => {
  logout().then(
    data => console.log('Successfully logged out')
  ).finally(
    () => window.location.replace(redirectUrl)
  );
};
