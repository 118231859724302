import { base64ImageJPEG, base64ImagePNG } from "./enums/base64Image";

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    try {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        if (reader.result.includes(base64ImageJPEG))
          resolve(reader.result.replace(base64ImageJPEG, ""));
        else if (reader.result.includes(base64ImagePNG))
          resolve(reader.result.replace(base64ImagePNG, ""));
        else resolve(reader.result);
      };
    } catch (err) {
      reject(err);
    }
  });
};
