import styled from 'styled-components/macro'

export const Wrapper = styled.div`
    padding: 1rem;
    color: ${props => props.white ? '#FFF' : props.theme.textSecondary};
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    span {
        margin: 0 0.25rem;

        svg {
            margin-top: 4px;
        }
    }
    &.introScreen{
        position:initial;
        padding:0px;
    }
`;

export const LogoImage = styled.img`
    position: relative;
    top: 5px;
`;