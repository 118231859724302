import { connect, useSelector } from "react-redux";
import { nextPage, nextStep, go } from "logic/actions/navigation";
import { useTranslation } from "react-i18next";
import * as S from "./PageNFCScan.styles";
import { documentsSave } from "logic/actions/api/document";
import { useDispatch } from "react-redux";
import { webView } from "logic/webView";
import { setDocumentId } from "logic/actions/documentCapture";
import { selectDialCountryNum } from "logic/reducers/documentCaptureConfiguration";
import {
  selectInitialNFCRetries,
  selectIsNFCOptional,
} from "logic/reducers/configuration";
import { useEffect, useState, useRef } from "react";
import {
  ANDROID,
  IOS,
  NFC_SCAN,
  NFC_SCAN_WITH_CHALLENGE,
  NFC_SCAN_TIMEOUT,
} from "logic/enums/composite";
import PageContent from "Components/PageContent";
import SubmitDocument from "Components/modules/common/SubmitDocument";
import {
  NFC_DISABLED,
  NFC_TIMEOUT,
  NFC_INTERRUPTED,
  NFC_INSTRUCTIONS,
  NFC_FAILED,
} from "logic/enums/pages";
import { PASSPORT } from "logic/enums/document";
import usPassportNfcScanGif from "assets/gifs/us-passport-nfc-scan.gif";
import passportNfcScanGif from "assets/gifs/passport-nfc-scan.gif";
import documentNfcScanGif from "assets/gifs/document-nfc-scan.gif";
import {
  selectMobileAppPlatform,
  selectNFCRetriesCounter,
  selectNFCTestData,
  selectSDLVersion,
} from "logic/reducers/composite";
import { NFCRetry } from "logic/actions/composite";
import DaonErrorBox from "Components/common/DaonErrorBox";
import DaonButton from "Components/common/DaonButton";
import { logEvent, nfc_scan_canceled, nfc_timeout } from "logic/eventLogger";
import {
  nfc_disabled,
  nfc_error,
  nfc_interrupted,
  nfc_scan_finished,
  nfc_scan_started,
} from "logic/eventLogger";

export const PageNFCScan = (props) => {
  const [nfc, setNfc] = useState({});
  const [calledCount, setCalledCount] = useState(0);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");
  const errorRef = useRef(error);
  const pendingRef = useRef(pending);

  let resolvePromise;
  let rejectPromise;
  let nfcTimeout;

  const { t } = useTranslation();
  const [nfcScanGif, setNfcScanGif] = useState("");
  const dialCountryNum = useSelector(selectDialCountryNum);
  const initalNFCRetries = useSelector(selectInitialNFCRetries) || 3;
  const isNFCOptional = useSelector(selectIsNFCOptional);
  const NFCRetries = useSelector(selectNFCRetriesCounter);
  const NFCTestData = useSelector(selectNFCTestData);
  const sdkVersion = useSelector(selectSDLVersion);

  const dispatch = useDispatch();
  const mobileAppPlatform = useSelector(selectMobileAppPlatform);

  useEffect(() => {
    setTimeout(() => {
      if (mobileAppPlatform === IOS) {
        window.addEventListener("nfcEvent", nfcEventHandler);
        window.addEventListener(
          "nfcScanCancelEvent",
          nfcScanCancelEventHandler
        );
      }
      if (mobileAppPlatform === ANDROID) {
        window.onNfcDataRead = onNfcDataRead;
        window.sendToNativePromise = sendToNativePromise;
        window.onNfcReadingFailed = onNfcReadingFailed;
        window.onNFCNotEnabled = onNFCNotEnabled;
        window.onNfcScanCanceled = onNfcScanCanceled;
      }
      nfcHandler();
    }, 1000);

    setNfcScanGif(
      props.documentType === PASSPORT
        ? dialCountryNum === "us"
          ? usPassportNfcScanGif
          : passportNfcScanGif
        : documentNfcScanGif
    );

    return () => {
      if (nfcTimeout) clearTimeout(nfcTimeout);
      if (mobileAppPlatform === IOS) {
        window.removeEventListener("nfcEvent", nfcEventHandler);

        window.removeEventListener(
          "nfcScanCancelEvent",
          nfcScanCancelEventHandler
        );
      }
      if (mobileAppPlatform === ANDROID) {
        window.onNfcDataRead = null;
        window.sendToNativePromise = null;
        window.onNfcReadingFailed = null;
        window.onNFCNotEnabled = null;
        window.onNfcScanCanceled = null;
      }
    };
  }, []);

  useEffect(() => {
    if (error) {
      errorRef.current = error;
    }
  }, [error]);

  useEffect(() => {
    if (pending) {
      pendingRef.current = pending;
    }
  }, [pending]);

  const nfcEventHandler = (e) => {
    try {
      let response = JSON.parse(e.detail.data);
      if (response.nfcStatus === "interrupted") {
        retriesHandler(NFC_INTERRUPTED);
      } else {
        logEvent(nfc_scan_finished, { platform: mobileAppPlatform });
        setNfc(response);
      }
      setCalledCount(calledCount + 1);
      setPending(true);
    } catch (e) {
      retriesHandler(NFC_INTERRUPTED);
    }
  };

  const nfcHandler = async () => {
    logEvent(nfc_scan_started, { platform: mobileAppPlatform });
    try {
      if (nfcTimeout) clearTimeout(nfcTimeout);
      nfcTimeout = setTimeout(() => {
        if (!errorRef.current) nfcScanTimeout();
      }, 59000);
      if (mobileAppPlatform === IOS) {
        if (sdkVersion >= 1.4) {
          webView(NFC_SCAN_WITH_CHALLENGE, NFCTestData);
        } else {
          webView(NFC_SCAN);
        }
      } else if (mobileAppPlatform === ANDROID) {
        let response = await sendToNativePromise();
        setNfc(JSON.parse(response));
        logEvent(nfc_scan_finished, { platform: mobileAppPlatform });
        setCalledCount(calledCount + 1);
        setPending(true);
      }
    } catch (e) {
      console.log(e);
      if (e === "disabled") {
        go(NFC_DISABLED)(dispatch);
        logEvent(nfc_error, {
          error: nfc_disabled,
          platform: mobileAppPlatform,
        });
      } else {
        retriesHandler(NFC_INTERRUPTED);
      }
    }
  };

  const nfcScanTimeout = () => {
    if (pendingRef.current) {
      if (nfcTimeout) clearTimeout(nfcTimeout);
      nfcTimeout = setTimeout(() => {
        nfcScanTimeout();
      }, 10000);
    } else {
      webView(NFC_SCAN_TIMEOUT);
      if (mobileAppPlatform === ANDROID) {
        retriesHandler(NFC_TIMEOUT);
      }
    }
  };

  const sendToNativePromise = () => {
    return new Promise((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
      if (sdkVersion >= 1.4) {
        webView(NFC_SCAN_WITH_CHALLENGE, NFCTestData);
      } else {
        webView(NFC_SCAN);
      }
    });
  };
  const onNfcScanCanceled = () => {
    logEvent(nfc_scan_canceled, { platform: mobileAppPlatform });
    go(NFC_INSTRUCTIONS)(dispatch);
    setPending(false);
  };

  const onNfcDataRead = (messageFromNative) => {
    resolvePromise(messageFromNative);
  };
  const onNFCNotEnabled = (messageFromNative) => {
    rejectPromise(messageFromNative);
  };

  const onNfcReadingFailed = (messageFromNative) => {
    rejectPromise(messageFromNative);
  };

  const nfcScanCancelEventHandler = (e) => {
    try {
      let response = JSON.parse(e.detail.data);
      if (response.isNFCScanCancelledFromUI) {
        logEvent(nfc_scan_canceled, { platform: mobileAppPlatform });
        go(NFC_INSTRUCTIONS)(dispatch);
      } else if (response.isNfcScanTimeoutDialogDismissed) {
        retriesHandler(NFC_TIMEOUT);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const retriesHandler = (pageRedirection) => {
    let error;
    switch (pageRedirection) {
      case NFC_TIMEOUT:
        error = nfc_timeout;
        break;
      case NFC_INTERRUPTED:
        error = nfc_interrupted;
        break;
    }
    if (error) {
      logEvent(nfc_error, {
        platform: mobileAppPlatform,
        error,
        retries: NFCRetries + 1,
      });
    }
    if (initalNFCRetries - NFCRetries > 0) {
      NFCRetry()(dispatch);
      go(pageRedirection)(dispatch);
    } else {
      if (isNFCOptional) {
        setCalledCount(calledCount + 1);
        setPending(true);
      } else {
        go(NFC_FAILED)(dispatch);
      }
    }
  };

  const onErrorHandler = ({ pending, error }) => {
    if (!error) {
      retriesHandler(NFC_INTERRUPTED);
    } else {
      logEvent(nfc_error, {
        error,
        platform: mobileAppPlatform,
      });
      setPending(pending);
      setError(error);
    }
  };

  const retryNFCScan = () => {
    retriesHandler(NFC_INSTRUCTIONS);
  };
  let documentsObject = {
    base64CroppedFirstPage: props.base64CroppedFirstPage,
    base64CroppedSecondPage: props.base64CroppedSecondPage,
    base64UnprocessedFirstPage: props.base64UnprocessedFirstPage,
    base64UnprocessedSecondPage: props.base64UnprocessedSecondPage,
  };
  return (
    <PageContent
      justifyContent="center"
      toggleLoading={pending}
      showBack={error ? true : false}
      title={t("PageNFCScan.title")}
      backButtonClick={retryNFCScan}
    >
      {calledCount > 0 && (
        <SubmitDocument
          errorHandler={onErrorHandler}
          documentsObject={documentsObject}
          documentType={props.documentType}
          isNFCReady={props.isNFCReady}
          mobileAppPlatform={mobileAppPlatform}
          nfcData={nfc}
        />
      )}
      {error ? (
        <>
          <DaonButton onClick={retryNFCScan} id="retry">
            {t("PageNFCScan.btn_retry_nfc_scanning")}
          </DaonButton>
        </>
      ) : (
        nfcScanGif && (
          <S.GifWrapper>
            <img src={nfcScanGif} alt="" />
          </S.GifWrapper>
        )
      )}
    </PageContent>
  );
};

export default connect(
  (state) => {
    const {
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      documentType,
      base64UnprocessedFirstPage,
      base64UnprocessedSecondPage,
      isNFCReady,
    } = state.documentCapture;

    return {
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      base64UnprocessedFirstPage,
      base64UnprocessedSecondPage,
      isNFCReady,
      documentType,
    };
  },
  { nextPage, nextStep, go, documentsSave, setDocumentId }
)(PageNFCScan);
