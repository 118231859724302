import styled from "styled-components/macro";

export const CaptureButton = styled.button`
  width: 56px;
  height: 56px;
  padding: 5px;
  background: ${(props) => props.theme.captureButton};
  border-radius: 50%;
  margin: 2px;
  &:hover {
    background: #eee;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
    cursor: not-allowed;
  }

  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    border: 2px solid black;
    border-radius: 50%;
  }

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    width: 48px;
    height: 48px;
    padding: 4px;
  }

  @media (max-width: ${(props) => props.theme.smallPhoneBreakpoint + "px"}) {
    width: 44px;
    height: 44px;
    padding: 3px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwitchBox = styled.div`
  position: absolute;
  right: 5%;
`;

export const FlipBox = styled.div`
  position: absolute;
  left: 5%;

`;

export const FlipCamera = styled.button`
  border-radius: 50%;
  height: 56px;
  width: 56px;
  margin-top: 10%;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  outline: none;

  img {
    height: 32px;
    width: 32px;
  }

  ${props => props.focused && `
    border: thin solid white;
  `}
`;
export const SpinnerWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    text-align: center;
  }
`;

export const Label = styled.label`
  visibility: hidden;
`;
