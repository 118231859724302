export const HIDE_SWITCH_TO_MOBILE_CHECKER = "HIDE_SWITCH_TO_MOBILE_CHECKER";
export const REDIRECT_FROM = "REDIRECT_FROM";

export const hideSwitchToMobileChecker = (payload) => (dispatch) => {
  return dispatch({ type: HIDE_SWITCH_TO_MOBILE_CHECKER, payload });
};

export const redirectFrom = (payload) => (dispatch) => {
  return dispatch({ type: REDIRECT_FROM, payload });
};
