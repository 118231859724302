import styled from "styled-components";

export const ImageWrapper = styled.div`
  

  @media (min-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    text-align: left;
    margin-bottom: 0;
    width: 480px;
    max-width: 60%;
  }
  & img {
    width: 99%;
  }
  @media (max-width: ${(props) => props.theme.smallPhoneBreakpoint + "px"}) {   
      width: 90% !important ;
      margin-left: auto;
      margin-right: auto;   
  }
  
`;

