import { store } from "../../store";
import { getUserId } from "../../userId";
import { CACHE_APPLICATION, writeToLocalCache } from "../../localCacheAdapter";
import { getTenantNameFromHost } from "../../tenant";
import { setApplicationData } from "../application";
import { axiosInstance } from "logic/api";
import * as API from "logic/api/application";
import { OTHER, QR, SMS } from "logic/enums/verification";
import i18n from "i18n";

export const applicationGet = () => (dispatch) => {
  const { application } = store.getState();
  return API.applicationGet(application.applicationId).then((response) => {
    dispatch(setApplicationData(response.data));
    writeToLocalCache(response.data, CACHE_APPLICATION);

    return response;
  });
};

export const getStart = (code, type) => (dispatch) => {
  const { application } = store.getState();

  return API.getStart(application.applicationId, code, type).then(
    (response) => {
      return response;
    }
  );
};

export const applicationQRValidate = (link) => (dispatch) => {
  const newLink = link.replace("switchToMobile", "start");
  return API.applicationQRValidate(newLink).then((response) => {
    return response;
  });
};

export const applicationUpdate =
  (verificationType, primaryPhone, shouldProceedOnMobile) => (dispatch) => {
    const { application } = store.getState();
    let request = {
      destination: verificationType.includes(SMS) ? primaryPhone : QR,
    };
    return API.applicationUpdate(
      application.applicationId,
      verificationType,
      shouldProceedOnMobile,
      request
    ).then((response) => {
      dispatch(setApplicationData(response.data));
      writeToLocalCache(response.data, CACHE_APPLICATION);
      return response;
    });
  };

export const applicationUpdateParams = (shouldStayOnDesktop) => (dispatch) => {
  let request = {};
  const { application, documentCaptureConfiguration } = store.getState();
  if (
    documentCaptureConfiguration.dialCountryNum &&
    documentCaptureConfiguration.dialCountryNum !== OTHER
  )
    request.countryCode = documentCaptureConfiguration.dialCountryNum;
  if (shouldStayOnDesktop !== undefined) {
    request.shouldProceedOnMobile = !shouldStayOnDesktop;
  }
  request.languageTag = i18n.language;

  return API.applicationUpdateParams(application.applicationId, request);
};

export const applicationVerify = (code, verificationType) => (dispatch) => {
  const { application } = store.getState();

  let request = {
    code,
    type: verificationType,
  };
  return API.applicationVerify(application.applicationId, request);
};

/**
 * PUT /{tenant}/rest/v1/accountapplications/{applicationId}/submit?evaluationPolicyName={evaluationPolicyName}
 */
export const applicationSubmit = () => () => {
  const { application, configuration } = store.getState();
  return API.applicationSubmit(
    application.id,
    configuration.evaluationPolicyName,
    application
  );
};

export const applicationStatus = (signal) => () => {
  const { application } = store.getState();
  return API.applicationStatus(application.id, application, signal);
};

export const startSession = () => {
  return axiosInstance.get(`/startSession${window.location.search}`);
};
