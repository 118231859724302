import { combineReducers } from "redux";

import { application } from "./application";
import { configuration } from "./configuration";
import { documentCapture } from "./documentCapture";
import { documentCaptureConfiguration } from "./documentCaptureConfiguration";
import { faceCapture } from "./faceCapture";
import { resendVerificationCode } from "./resendVerificationCode";
import { livenessTest } from "./livenessTest";
import { navigation } from "./navigation";
import { apiFetch } from "./apiFetch";
import { camera } from "./camera";
import { gyroscope } from "./gyroscope";
import { phoneValidation } from "./phoneValidation";
import { composite } from "./composite";
import { fidelity } from "./fidelity";
import { version } from "./version";

export const rootReducer = combineReducers({
  application,
  configuration,
  documentCapture,
  documentCaptureConfiguration,
  faceCapture,
  livenessTest,
  navigation,
  apiFetch,
  camera,
  resendVerificationCode,
  gyroscope,
  phoneValidation,
  composite,
  fidelity,
  version,
});
