import styled from "styled-components";

export const DocumentCaptureFooter = styled.footer`
    @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
      top: 65%;
    }
    top: ${props => props.isError ? '60%' : '80%'};
    width: 80%;
    margin: 0 auto;
  }
`;

export const DocumentMessage = styled.p`
  color: ${(props) => props.theme.mainButtonText};
  text-align: center;
  margin-bottom: 3rem;

  @media (min-width: ${(props) => props.theme.largeScreenBreakpoint + "px"}) {
    margin-bottom: 0.25rem;
  }

  @media (min-width: 1024px) and (min-height: 900px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1024px) and (min-height: 768px) and (max-height: 899px) {
    margin-bottom: 3rem;
  }
`;
