import * as S from "./ProgressStepper.styles";
import { useContext, useEffect } from "react";
import placeholderIcon from "assets/icons/16-24/placeholder.svg";
import { useTranslation } from "react-i18next";
import { steps } from "logic/steps";
import {
  STEPER_THANK_YOU,
  STEPER_DOCUMENT_CAPTURE,
  STEPER_FACE_CAPTURE,
} from "logic/enums/progressStepperTitles";
import { ThemeContext } from "styled-components";

export default function ProgressSteper({ ommitVertLine, ommitDesktopSteps }) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  useEffect(() => {
    if (ommitDesktopSteps) {
      steps.map((step) => {
        step.title == STEPER_DOCUMENT_CAPTURE
          ? (step.hide = false)
          : step.title == STEPER_FACE_CAPTURE
          ? (step.hide = false)
          : (step.hide = true);
      });
    }
  }, [ommitDesktopSteps]);

  return (
    <S.WrapperDiv>
      <S.OrderedList>
        {steps
          .filter((step) => !step.hide && step.title !== STEPER_THANK_YOU)
          .map((step, index, { length }) => (
            <S.ListedItem key={index}>
              <S.IconWrap>
                <S.RoundIcon>
                  <img
                    src={step.icon ? step.icon : placeholderIcon}
                    width="24"
                    height="24"
                    alt=""
                  ></img>
                </S.RoundIcon>
                {!ommitVertLine && index + 1 < length && <S.VerticalLine />}
              </S.IconWrap>
              <div>
                {!ommitVertLine ? (
                  <div>
                    <S.Paragraph color={theme.textPrimary}>
                      {t(step.title)}
                    </S.Paragraph>
                    <S.Paragraph size={"12px"}>
                      {t(`${step.title}_text`)}
                    </S.Paragraph>
                  </div>
                ) : (
                  <div>
                    <S.Paragraph color={theme.textPrimary}>
                      {t(`${step.title}_qr`)}
                    </S.Paragraph>
                    <S.Paragraph size={"12px"}>
                      {t(`${step.title}_text_qr`)}
                    </S.Paragraph>
                  </div>
                )}
              </div>
            </S.ListedItem>
          ))}
      </S.OrderedList>
    </S.WrapperDiv>
  );
}
