import { NAVIGATION_GO, NAVIGATION_STEP_NUMBER } from "../actions/navigation";
import { INTRO_PAGE } from "../enums/pages";
import determineStep from "../determineStep";
import { goToStepNumber } from "logic/steps";

const defaultState = {
  page: INTRO_PAGE,
  step: 0,
};

export function navigation(state = defaultState, action) {
  switch (action.type) {
    case NAVIGATION_GO:
      return {
        ...state,
        page: action.payload,
        step: determineStep(action.payload) || state.step,
      };
    case NAVIGATION_STEP_NUMBER: {
      return {
        page: goToStepNumber(action.payload),
        step: determineStep(goToStepNumber(action.payload)) || state.step,
      };
    }
    default:
      return state;
  }
}
