import Navigation from "./Navigation";
import ConnectionSpinner from "./ConnectionSpinner";
import ApplicationConfig from "./ApplicationConfig";
import ApplicationContinue from "./ApplicationContinue";
import SwitchToMobile from "./SwitchToMobile";
// import { useSelector } from "react-redux";
import { GlobalStyle } from "./Global.styles";
import { ThemeProvider } from "styled-components";
import { theme } from "../../logic/enums/theme";
import { useEffect } from "react";
import WasmDependenciesLoader from "Components/App/WasmDependenciesLoader";

export default function App() {
  // const isInProgress = useSelector((store) => store.apiFetch.isInProgress);
  useEffect(() => {
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ConnectionSpinner>
        <ApplicationConfig>
          <SwitchToMobile>
            <ApplicationContinue>
              <WasmDependenciesLoader/>
              <Navigation />
            </ApplicationContinue>
          </SwitchToMobile>
          <GlobalStyle />
        </ApplicationConfig>
      </ConnectionSpinner>
      <GlobalStyle />
    </ThemeProvider>
  );
}
