import { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";

import { previous, nextStep, nextPage, go } from "logic/actions/navigation";
import { resendCode } from "logic/actions/resendVerificationCode";
import PageContent from "Components/PageContent";
import {
  logEvent,
  phone_verified,
  sms_retry,
  step_completed,
} from "logic/eventLogger";
import { isMobile } from "logic/deviceType";
import * as S from "./PageVerificationCode.styles";
import { challengeCreate } from "logic/actions/api/liveness";
import { withTranslation } from "react-i18next";
import { getTimePassed, sms_code, startEventTimer } from "logic/stepTimers";
import DaonButton from "../../../common/DaonButton";
import { countrySelected } from "logic/actions/documentCaptureConfiguration";
import SMSInput from "./SMSInput";
import {
  PAGE_SESSION_EXPIRED,
  VALIDATE_PHONE,
  VERIFICATION_CODE,
} from "logic/enums/pages";
import {
  applicationVerify,
  applicationGet,
} from "logic/actions/api/application";
import { SMS } from "logic/enums/verification";
import { redirectFrom } from "logic/actions/phoneValidation";
let timeout;

export class PageVerificationCode extends Component {
  static propTypes = {
    applicationSendCode: func,
    previous: func,
  };

  constructor(props) {
    super(props);
    this.state = { pending: false };
    this.submit.bind(this);
    this.onKeyDown.bind(this);
    this.changeCode.bind(this);
    if (!getTimePassed(sms_code)) startEventTimer(sms_code);
  }

  changeCode = (code) => {
    this.setState({ smsCode: code, error: null });
    this.setState({ smsCodeIsValid: code.length > 5 });
  };

  handleSuccessAplication = (response) => {
    if (response.data.status === "FAILED_NO_MATCH") {
      this.setState({
        error: this.props.t("PageVerificationCode.error_code", {
          retriesLeft:
            response.data.maxRetriesAllowed - response.data.retriesCount,
        }),
        pending: false,
      });
    } else {
      const timePassed = getTimePassed(sms_code);
      logEvent(step_completed, {
        stepNameCompleted: phone_verified,
        timePassed,
        country: this.props.documentCaptureConfiguration,
      });
      this.props.nextPage();
    }
  };

  handleFailedApplication = (err) => {
    if (err instanceof Error) {
      if (err?.response?.status === 401 || err?.response?.status === 403)
        this.props.go(PAGE_SESSION_EXPIRED);
      else {
        let error =
          err.response && err.response.data
            ? err.response.data.message
            : err.toString();
        this.setState({
          error,
          pending: false,
        });
      }
    } else
      this.setState({
        error: this.props.t("PageVerificationCode.error_generic"),
        pending: false,
      });
  };

  submit = async (e) => {
    e.preventDefault();
    if (this.state.pending) return;
    const smsCode = this.state.smsCode;
    if (smsCode) {
      this.setState({ pending: true });
      try {
        const response = await this.props.applicationVerify(smsCode, SMS);
        await this.props.applicationGet();
        await this.handleSuccessAplication(response);
      } catch (err) {
        this.handleFailedApplication(err);
      } finally {
        this.setState({ pending: false });
      }
    }
  };

  onKeyDown = (e) => {
    if (e.key === "Enter" && this.state.smsCodeIsValid) {
      this.submit(e);
    }
  };
  componentDidMount() {
    document.title = `${this.props.t("PageValidatePhone.mobile")} | Onboarding`;
    document.addEventListener("keydown", this.onKeyDown);
    if (this.props.challengeExpirySec) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.props.redirectFrom(VERIFICATION_CODE);
        this.props.go(VALIDATE_PHONE);
      }, this.props.challengeExpirySec * 1000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
    clearTimeout(timeout);
  }

  render() {
    let enterCodeMessage =
      this.props.isSwitchedToMobile || this.props.transactionFromDesktopSession
        ? this.props.t("PageVerificationCode.enter_code_mobile")
        : this.props.t("PageVerificationCode.enter_verification_code");
    let maskedPhoneNumber =
      this.props.destination?.substring(0, 3) +
      "*****" +
      this.props.destination?.substring(8);

    return (
      <PageContent
        toggleLoading={this.state.pending}
        title={this.props.t("PageValidatePhone.mobile")}
        instructions={true}
      >
        <S.Content>
          <div>
            <h2>{this.props.t("PageVerificationCode.verify")}</h2>
            <form onSubmit={this.submit} id="code-form">
              <S.Paragraph>
                {enterCodeMessage}
                {this.props.destination && ` +${maskedPhoneNumber}`}
              </S.Paragraph>
              <SMSInput
                onCodeChange={this.changeCode}
                hasError={this.state.error}
              />
              {this.state.error ? (
                <p className="error">{this.state.error}</p>
              ) : null}
              {!this.props.isSwitchedToMobile ? (
                <S.Paragraph>
                  {isMobile(navigator.userAgent)
                    ? null
                    : this.props.t("PageVerificationCode.didnt_receive")}
                  <S.ResendLink
                    id="resend_link"
                    onClick={() => {
                      logEvent(sms_retry);
                      this.props.resendCode();
                      this.props.previous();
                    }}
                  >
                    {isMobile(navigator.userAgent)
                      ? this.props.t("PageVerificationCode.resend_mobile")
                      : this.props.t("PageVerificationCode.resend")}
                  </S.ResendLink>
                </S.Paragraph>
              ) : null}
            </form>
          </div>

          <DaonButton
            form="code-form"
            disabled={!this.state.smsCodeIsValid || this.state.pending}
            type="submit"
            id="verify"
          >
            {this.props.t("PageVerificationCode.verify")}
          </DaonButton>
        </S.Content>
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageVerificationCode);
export default connect(
  (state) => {
    return {
      ...state.application,
      ...state.livenessTest,
      ...state.documentCaptureConfiguration,
    };
  },
  {
    go,
    previous,
    resendCode,
    nextStep,
    nextPage,
    challengeCreate,
    countrySelected,
    applicationVerify,
    applicationGet,
    redirectFrom,
  }
)(componentWithTranslation);
