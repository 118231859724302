import { Component } from "react";
import { connect } from "react-redux";
import { func, string } from "prop-types";
import PageContent from "../../../PageContent";
import ImagePreview from "../../common/ImagePreview";
import {
  retry,
  retryProcessing,
  setDocumentId,
  increaseRetryCounter,
} from "logic/actions/documentCapture";
import { go, nextStep } from "logic/actions/navigation";
import { documentsSave } from "logic/actions/api/document";
import {
  logEvent,
  document_capture_retry,
  document_processing_retry,
} from "logic/eventLogger";
import { isMobile } from "logic/deviceType";
import { withTranslation } from "react-i18next";
import * as S from "./PageDocumentCaptureConfirm.styles";
import DaonButton from "../../../common/DaonButton";
import DaonErrorBox from "Components/common/DaonErrorBox";
import SubmitDocument from "Components/modules/common/SubmitDocument";
import { DRIVERS_LICENCE, PASSPORT } from "logic/enums/document";
import { steps } from "logic/steps";
import { NFC_STEP } from "logic/enums/steps";

export class PageDocumentCaptureConfirm extends Component {
  static propTypes = {
    retry: func,
    base64CroppedFirstPage: string,
    base64CroppedSecondPage: string,
    documentType: string,
    documentsSave: func,
  };

  constructor(props) {
    super(props);
    this.state = {
      serverProcessingFailed: false,
      imageLoaded: false,
      error: null,
      message: null,
      calledCount: 0,
      messageFromIos: undefined,
      nfc: {},
    };
    this.getBack.bind(this);
    this.submit.bind(this);
  }

  componentDidMount() {
    document.title = `${this.props.t(
      "PageDocumentCapture.title"
    )} | Onboarding`;
  }

  static getDerivedStateFromProps({
    base64CroppedFirstPage,
    base64CroppedSecondPage,
  }) {
    return {
      base64: base64CroppedSecondPage || base64CroppedFirstPage,
    };
  }

  getBack = () => {
    logEvent(document_capture_retry, {
      documentType: this.props.documentType,
      documentCountry: this.props.dialCountryNum,
      retryCount: this.props.retries + 1,
    });
    this.props.retry();
  };

  retryCapturing = () => {
    logEvent(document_processing_retry, {
      documentType: this.props.documentType,
      documentCountry: this.props.dialCountryNum,
      retryCount: this.props.documentProcessingRetriesCounter + 1,
    });
    this.props.retryProcessing();
  };

  onErrorHandler = ({ pending, serverProcessingFailed = true }) => {
    this.setState({ pending, serverProcessingFailed });
  };

  submit = () => {
    this.setState({ pending: true, calledCount: this.state.calledCount + 1 });
  };

  render() {
    let documentsObject = {
      base64CroppedFirstPage: this.props.base64CroppedFirstPage,
      base64CroppedSecondPage: this.props.base64CroppedSecondPage,
      base64UnprocessedFirstPage: this.props.base64UnprocessedFirstPage,
      base64UnprocessedSecondPage: this.props.base64UnprocessedSecondPage,
    };

    let isNfcStepPresent = steps.findIndex((x) => x.name === NFC_STEP) > -1;

    let shouldScanNfc =
      this.props.mobileAppPlatform &&
      isNfcStepPresent &&
      this.props.isNFCReady &&
      (this.props.singlePaged ||
        this.props.documentType === PASSPORT ||
        this.props.base64CroppedSecondPage);
    let text;
    if (this.props.isRejected && !this.props.allRetriesFailed) {
      text = this.props.t("PageDocumentCaptureConfirm.error_please_retake");
    } else if (!this.props.allRetriesFailed) {
      text = this.props.t("PageDocumentCaptureConfirm.error_ensure_visible");
    } else {
      text = this.props.t(
        "PageDocumentCaptureConfirm.error_unable_check_quality"
      );
    }

    return (
      <PageContent
        toggleLoading={this.state.pending}
        isDarkMode={true}
        title={this.props.t("PageDocumentCapture.title_confirm")}
        backButtonClick={this.getBack}
      >
        <S.WrapperDiv
          onClick={this.toggleImage}
          id="wrapperDiv"
          isNative={this.props.captureMethod === "NATIVE"}
        >
          <S.Title>{this.props.t("PageDocumentCaptureConfirm.title")}</S.Title>
          {(this.props.allRetriesFailed || this.props.isRejected) &&
          this.props.summaryScorePresent ? (
            <S.MessageParagraph>{text}</S.MessageParagraph>
          ) : this.props.allRetriesFailed || this.props.isRejected ? (
            <DaonErrorBox
              style={{ alignSelf: "center" }}
              message={text}
            ></DaonErrorBox>
          ) : (
            <S.MessageParagraph>{text}</S.MessageParagraph>
          )}
          <ImagePreview previewImage={this.state.base64} alt="Image Preview" />
        </S.WrapperDiv>

        {this.state.calledCount > 0 && (
          <SubmitDocument
            style={{ alignSelf: "center" }}
            notWhiteBackground={true}
            key={this.state.calledCount}
            isAuDl={
              this.props.dialCountryNum === "au" &&
              this.props.documentType === DRIVERS_LICENCE
            }
            errorHandler={this.onErrorHandler}
            documentsObject={documentsObject}
            documentType={this.props.documentType}
            isNFCReady={this.props.isNFCReady}
            mobileAppPlatform={this.props.mobileAppPlatform}
            confirmOcrData={this.props.confirmOcrData}
          />
        )}
        {this.props.message && (
          <DaonErrorBox
            style={{ alignSelf: "center" }}
            message={this.props.message}
          ></DaonErrorBox>
        )}
        {this.state.serverProcessingFailed ? (
          <S.ButtonsWrapper>
            <DaonButton
              disabled={this.state.pending}
              style={{ margin: "0 auto" }}
              onClick={this.retryCapturing}
              id="retry"
            >
              {this.props.t("PageDocumentCaptureConfirm.btn_retry_capturing")}
            </DaonButton>
          </S.ButtonsWrapper>
        ) : (
          <S.ButtonsWrapper>
            <DaonButton
              style={
                isMobile(navigator.userAgent)
                  ? { margin: "0 0 1rem 0" }
                  : { margin: "1rem" }
              }
              disabled={this.state.pending}
              onClick={this.getBack}
              outline
              id="take_new_photo"
            >
              {this.props.t("PageDocumentCaptureConfirm.btn_retake_photo")}
            </DaonButton>
            <DaonButton
              disabled={this.state.pending}
              style={
                isMobile(navigator.userAgent)
                  ? { margin: "0 0 3rem 0" }
                  : { margin: "1rem" }
              }
              onClick={
                shouldScanNfc ? () => this.props.nextStep() : this.submit
              }
              id="submit_photo"
            >
              {this.props.t("Common.photo_ok_doc")}
            </DaonButton>
          </S.ButtonsWrapper>
        )}
      </PageContent>
    );
  }
}
const componentWithTranslation = withTranslation()(PageDocumentCaptureConfirm);
export default connect(
  (state) => {
    const {
      allRetriesFailed,
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      base64UnprocessedFirstPage,
      base64UnprocessedSecondPage,
      documentType,
      isNFCReady,
      aspectRatio,
      message,
      isRejected,
      processingRetriesCounter,
      documentProcessingRetriesCounter,
      retries,
      summaryScorePresent,
    } = state.documentCapture;

    const { dialCountryNum } = state.documentCaptureConfiguration;

    const { mobileAppPlatform } = state.composite;
    const { featureFlags } = state.configuration.extraConfig;

    return {
      allRetriesFailed,
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      base64UnprocessedFirstPage,
      base64UnprocessedSecondPage,
      documentType,
      aspectRatio,
      isNFCReady,
      mobileAppPlatform,
      dialCountryNum,
      message,
      isRejected,
      processingRetriesCounter,
      documentProcessingRetriesCounter,
      retries,
      summaryScorePresent,
      singlePaged: state.documentCaptureConfiguration.singlePaged,
      confirmOcrData: featureFlags?.confirmOcrData,
      shouldNotSendUnprocessedDocuments:
        featureFlags?.shouldNotSendUnprocessedDocuments,
      documentProcessorRetries:
        featureFlags?.documentProcessorRetries ||
        state.configuration.extraConfig.steps?.document?.options
          ?.documentProcessorRetries ||
        3,
    };
  },
  {
    retry,
    retryProcessing,
    documentsSave,
    go,
    nextStep,
    increaseRetryCounter,
    setDocumentId,
  }
)(componentWithTranslation);
