import styled from 'styled-components'

export const MainWrapper = styled.div`
    & p {
        font-size: 14px;
        padding: 2px;
    }
`;

export const Button = styled.button`
    margin-top: 10%;
`
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 
    position: relative;
    z-index: 8;
    min-height: 78vh;

    @media only screen and (min-width:${props => props.theme.phoneBreakpoint + 'px'}) {
        justify-content: center;
    }
`

export const Paragraph = styled.p`
    text-align: left;
    
    margin: ${({ margin }) => margin || "1em 0"};
`;

export const ListItems = styled.div`
    color: ${props => props.theme.textPrimary};
    font-size: 0.9rem;
    li {
        margin: 5px auto;
    }
`
export const Para = styled.div`
    color: ${props => props.theme.textPrimary};
    font-size: 0.9rem;
    font-weight: 600;
    margin: 5px 0 0 5px;
`
export const H2 = styled.h2`
   margin-bottom: 0.5rem !important;
`