export const APPROVED = "PageApproved";
export const DECLINED = "PageDeclined";
export const REVIEWING = "PageReview";
export const CONTINUE_ON_DESKTOP = "PageContinueOnDesktop";
export const DOCUMENT_CAPTURE_PORTRAIT = "PageDocumentCapturePortrait";
export const DOCUMENT_CAPTURE_LANDSCAPE = "PageDocumentCaptureLandscape";
export const DOCUMENT_CAPTURE_LOCK = "PageDocumentCaptureLock";
export const DOCUMENT_CAPTURE_CONFIRM = "PageDocumentCaptureConfirm";
export const DOCUMENT_CAPTURE_EDIT_DOCUMENT = "PageDocumentCaptureEditDocument";
export const DOCUMENT_CAPTURE_INSTRUCTIONS = "PageDocumentCaptureInstructions";
export const FACE_CAPTURE = "PageFaceCapture";
export const FACE_CAPTURE_CONFIRM = "PageFaceCaptureConfirm";
export const FACE_CAPTURE_INSTRUCTIONS = "PageFaceCaptureInstructions";
export const LIVENESS_TEST_RETRY = "PageLivenessTestRetry";
export const THANK_YOU = "PageThankYou";
export const VALIDATE_PHONE = "PageValidatePhone";
export const VERIFICATION_CODE = "PageVerificationCode";
export const VERIFY_IDENTITY = "PageVerifyIdentity";
export const SENT_TO_MOBILE = "PageSentToMobile";
export const INSTRUCTION_VIDEO_DOCUMENT = "PageInstructionVideoDocument";
export const INSTRUCTION_VIDEO_SELFIE = "PageInstructionVideoSelfie";
export const DOCUMENT_COUNTRY_SELECTOR = "PageDocumentCountrySelector";
export const INTRO_PAGE = "PageIntroScreen";
export const PAGE_SESSION_EXPIRED = "PageSessionExpired";
export const NFC_INSTRUCTIONS = "PageNFCInstructions";
export const NFC_SCAN = "PageNFCScan";
export const NFC_DISABLED = "PageNFCDisabled";
export const NFC_NOT_SUPPORTED = "PageNFCNotSupported";
export const NFC_WRONG_IOS_VERSION = "PageNFCWrongIosVersion";
export const NFC_TIMEOUT = "PageNFCTimeout";
export const NFC_FAILED = "PageNFCFailed";
export const NFC_INTERRUPTED = "PageNFCInterrupted";
export const INSTRUCTIONS_CLR = "PageInstructionsCLR";
export const GDPR = "PageGDPR";
export const QR_INSTRUCTIONS = "PageQRInstructions";
export const QR_SCAN = "PageQRScan";
export const CREATE_CHALLENGE = "PageCreateChallenge";
export const PHONE_STATUS = "PagePhoneStatus";
