import * as S from "./DaonErrorBox.styles";
import exclamationMark from "../../../assets/icons/16-24/exclamationMark.svg";
import exclamationMarkNeutral from "../../../assets/icons/16-24/exclamationMarkNeutral.svg";

export default function DaonErrorBox({
  title,
  message,
  whiteBackground,
  neutral,
  style
}) {
  return (
    <S.WrapperDiv aria-live="polite" neutral={neutral} id="error_box" style={style}>
      <S.TitleDiv>
        <S.ExclamationImage
          src={neutral ? exclamationMarkNeutral : exclamationMark}
          alt="!"
          height="20"
        />
        <div>
          {title && <S.TitleSpan>{title}</S.TitleSpan>}
          {message && (
            <S.Paragraph whiteBackground={whiteBackground}>
              {message}
            </S.Paragraph>
          )}
        </div>
      </S.TitleDiv>
    </S.WrapperDiv>
  );
}
