import {
  AUTO_APPROVED_STATUS_CODE,
  AUTO_DECLINED_STATUS_CODE,
  DEFAULT_STATUS_CODE,
  REVIEW_PENDING_STATUS_CODE,
} from "./enums/composite";

const setStatusCode = (status) => {
  switch (status) {
    case "AUTO_APPROVED":
      return AUTO_APPROVED_STATUS_CODE;
    case "AUTO_DECLINED":
      return AUTO_DECLINED_STATUS_CODE;
    case "REVIEW_PENDING":
      return REVIEW_PENDING_STATUS_CODE;
    default:
      return DEFAULT_STATUS_CODE;
  }
};
export default setStatusCode;
