import { IDRND_FACE } from "logic/enums/livenessTestType";

export const challengeMock = {
  items: [
    {
      maxRetries: 3,
      type: IDRND_FACE,
    },
  ],
};
