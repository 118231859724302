export const QR = "QR";
export const QR_API = "qr";
export const SMS = "SMS";
export const QR_SMS = "QR_SMS";
export const NONE = "NONE";
export const OPTIONAL = "OPTIONAL";
export const MANDATORY = "MANDATORY";
export const PHONE = "phone";
export const OTHER = "other";
export const SWITCH_TO_MOBILE = "switchToMobile";
