import { useEffect, useState, useRef } from "react";
import { getCameraIds } from "logic/getCameraIds";
import * as S from "./CaptureButtonPanel.styles";
import SwitchCamera from "./SwitchCamera";
import { useSelector, useDispatch } from "react-redux";
import DaonSpinner from "Components/common/DaonSpinner";
import { ReactComponent as Flip } from "../../assets/icons/16-24/Flip.svg";
import { selectCameraIndex } from "logic/reducers/camera";
import { storeCameraIndex } from "logic/actions/camera";
import { isIOS } from "logic/deviceType";

export default function CaptureButtonPanel({
  onCaptureClick,
  disabled,
  onChangeCamera,
  flipCameraHandler,
  showSwitchCameraButton,
  shouldHideCaptureButton,
  shouldDisableCameraButton,
}) {
  const [deviceList, setDeviceList] = useState({});
  const selectedCameraIndex = useSelector(selectCameraIndex);
  const [cameraIndex, setCameraIndex] = useState(selectedCameraIndex);
  const dispatch = useDispatch();
  const flipCameraRef = useRef(null);
  const switchCameraRef = useRef(null);

  useEffect(() => {
    getCameraIds().then((deviceList) => {
      setDeviceList(deviceList);
    });
  }, []);

  useEffect(() => {
    const handleMouseDown = () => {
      document.body.classList.add("using-mouse");
    };

    const handleKeyDown = (event) => {
      if (event.keyCode === 9) {
        document.body.classList.remove("using-mouse");
      }
    };

    document.body.addEventListener("mousedown", handleMouseDown);
    document.body.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.removeEventListener("mousedown", handleMouseDown);
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    return () => {
      storeCameraIndex(cameraIndex)(dispatch);
    };
  }, [cameraIndex]);

  const switchCamera = () => {
    if (shouldDisableCameraButton) return;
    let newCameraIndex = cameraIndex + 1;

    if (newCameraIndex > deviceList.length - 1) {
      newCameraIndex = 0;
    }
    if (newCameraIndex !== cameraIndex) {
      onChangeCamera(deviceList[newCameraIndex].cameraId);
      setCameraIndex(newCameraIndex);
    }
  };

  const handleFocus = (ref) => {
    if (!document.body.classList.contains("using-mouse")) {
      ref.current.classList.add("focused");
    }
  };

  const handleBlur = (ref) => {
    ref.current.classList.remove("focused");
  };
  return (
    <S.MainContainer>
      <S.FlipBox>
        {typeof flipCameraHandler === "function" && (
          <>
            <span id="flip_camera_label" hidden>
              Flip camera
            </span>
            <S.FlipCamera
              ref={flipCameraRef}
              onFocus={() => handleFocus(flipCameraRef)}
              onBlur={() => handleBlur(flipCameraRef)}
              focused={
                flipCameraRef.current &&
                flipCameraRef.current.classList.contains("focused")
              }
              onClick={flipCameraHandler}
              id="flip_camera"
              aria-describedby="flip_camera_label"
              disabled={shouldDisableCameraButton}
            >
              <Flip />
            </S.FlipCamera>
          </>
        )}
      </S.FlipBox>

      {!shouldHideCaptureButton && (
        <>
          {shouldDisableCameraButton ? (
            <S.SpinnerWrapper>
              <DaonSpinner type={"small"} />
            </S.SpinnerWrapper>
          ) : (
            <>
              <span id="capture_label" hidden>
                Capture
              </span>
              <S.CaptureButton
                id="capture"
                aria-describedby="capture_label"
                onClick={() =>
                  typeof onCaptureClick === "function" && onCaptureClick()
                }
                disabled={disabled}
              >
                <span></span>
              </S.CaptureButton>
            </>
          )}
        </>
      )}

      {!isIOS(navigator.userAgent) && (
        <S.SwitchBox>
          {showSwitchCameraButton && deviceList.length > 1 && (
            <>
              <span id="switch_camera_label" hidden>
                Switch camera
              </span>
              <S.FlipCamera
                ref={switchCameraRef}
                onFocus={() => handleFocus(switchCameraRef)}
                onBlur={() => handleBlur(switchCameraRef)}
                focused={
                  switchCameraRef.current &&
                  switchCameraRef.current.classList.contains("focused")
                }
                onClick={switchCamera}
                id="flip_camera"
                aria-describedby="switch_camera_label"
                disabled={shouldDisableCameraButton}
              >
                <SwitchCamera
                  fill={shouldDisableCameraButton ? "grey" : "white"}
                ></SwitchCamera>
              </S.FlipCamera>
            </>
          )}
        </S.SwitchBox>
      )}
    </S.MainContainer>
  );
}
