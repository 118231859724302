import styled from 'styled-components'

export const Video = styled.video`
  transform: ${(props) => (props.shouldRotate ? 'rotateY(180deg)' : 'none')};
`;

export const IndicatorWrapper = styled.div`
    position: absolute;
    z-index: 20;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 62vw;
    height: 75vw;


    @media screen and (orientation: portrait) and (max-width: 400px) {
          width: 60vw;
          height: 73vw;
    }

    @media screen and (orientation: portrait) and (max-width: 320px) {
          width: 54vw;
          height: 68vw;
    }

    @media (min-aspect-ratio: 3/4) {
          width: 42vh;
          height: 52vh;
    }

    @media (min-width: 1100px) {
          width: 52vh;
          height: 65vh;
    }
`

export const CaptureIndicator = styled.div`
    position: absolute;
    z-index: 20;
    padding: 8px;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    margin-top: -25px;
    left: 50%;
    top: 0%;
    border-radius: 50%;
    background: ${(props) => props.background};

    display: flex;
    justify-content: center;
    align-items: center;
    
    @media screen and (max-width: 700px) {
        width: 40px;
        height: 40px;
        margin-left: -20px;
        margin-top: -20px;
    }

`