import { SWITCH_TO_MOBILE } from "./enums/verification";

export function setBaseTag(tenant) {
  if (tenant) {
    if (tenant[0] !== "/") tenant = "/" + tenant;
    document.write("<base href='" + tenant + "/start' />");
  }
}

export function getTenantNameFromHost() {
  return window.location.pathname.split("/")[1] || "";
}

export function isSmsLink() {
  return window.location.pathname.split("/")[2] === SWITCH_TO_MOBILE;
}
