export const applicationPostMock = {
  "id": "bba7f997-9cdb-4265-9fde-2b828a8e05df",
  "user": {
    "id": "QTAzGe_QkO58yLhu4wd0ypVDxQ",
    "userId": "bba7f997-9cdb-4265-9fde-2b828a8e05df",
    "primaryPhone": "+381665126482",
    "href": "https://engdobs-idx45-qc.identityx-cloud.com/onboardingdemo/IdentityXServices/rest/v1/users/QTAzGe_QkO58yLhu4wd0ypVDxQ",
    "relHref": "/onboardingdemo/IdentityXServices/rest/v1/users/QTAzGe_QkO58yLhu4wd0ypVDxQ",
    "idCheck": {
      "id": "LjbpBiib1D1j1L6n0FJiqA",
      "referenceId": "f372c1c3-a3e9-4a89-8744-10f54993a8cc",
      "href": "https://engdobs-idx-latest.identityx-cloud.com/onboardingdemo/DigitalOnBoardingServices/rest/v1/users/QTAz9XKvt1IWTmJ3LhbHlrDSZA/idchecks/LjbpBiib1D1j1L6n0FJiqA",
      "relHref": "/onboardingdemo/DigitalOnBoardingServices/rest/v1/users/QTAz9XKvt1IWTmJ3LhbHlrDSZA/idchecks/LjbpBiib1D1j1L6n0FJiqA"
    }
  }
}