import styled from 'styled-components'

export const Content = styled.p`
  @media (min-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
    max-width: 600px;
    line-height: 20px;
}
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;
