import ReactDOM from "react-dom";
import { Provider as StateProvider } from "react-redux";

import "promise-polyfill/src/polyfill";
import "assets/styles/main.css";
import "assets/styles/panel.css";
import "assets/styles/button.css";
import "assets/styles/desktop.css";
import "assets/styles/modal.css";

import App from "Components/App";
import * as serviceWorker from "serviceWorker";
import { store } from "logic/store";
import { defaultState } from "logic/reducers/configuration";
import { setBaseTag } from "logic/tenant";
import "i18n";
import { setup } from "./logic/integrations/consoleLog";

if (process.env.REACT_APP_MOCK_API === "true") {
  const { worker } = require("./mocks/browser");
  worker.start();
}

setup();
setBaseTag(defaultState.tenant);

ReactDOM.render(
  <StateProvider store={store}>
    <App />
  </StateProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
