const actionPrefix = "DOCUMENT_CAPTURE_CONFIGURATION";

export const MULTI_CAPTURE_CONFIG = actionPrefix + "MULTI_CAPTURE_CONFIG";
export const CAMERA_RESOLUTION = actionPrefix + "CAMERA_RESOLUTION";
export const COUNTRY_SELECTOR = actionPrefix + "COUNTRY_SELECTOR";
export const IS_SINGLE_PAGED = actionPrefix + "IS_SINGLE_PAGED";
export const COUNTRY_NUM = actionPrefix + "COUNTRY_NUM";
export const VERTICAL_DOCUMENTS_PRESENT =
  actionPrefix + "VERTICAL_DOCUMENTS_PRESENT";

export function cameraResolution(payload) {
  return {
    type: CAMERA_RESOLUTION,
    payload: {
      width: payload.width,
      height: payload.height,
    },
  };
}

export function documentSidesSelector(payload) {
  return {
    type: COUNTRY_SELECTOR,
    payload,
  };
}
export function verticalDocumentsPresent(payload) {
  return {
    type: VERTICAL_DOCUMENTS_PRESENT,
    payload,
  };
}
export function isSinglePaged(payload) {
  return { type: IS_SINGLE_PAGED, payload };
}

export const countrySelected = (payload) => (dispatch) => {
  dispatch({
    type: COUNTRY_NUM,
    payload,
  });
};
