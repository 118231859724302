import deTrans from "./de/translation.json";
import enTrans from "./en/translation.json";
import esTrans from "./es/translation.json";
import frTrans from "./fr/translation.json";
import ieTrans from "./ga/translation.json";
import itTrans from "./it/translation.json";
import plTrans from "./pl/translation.json";
import ptTrans from "./pt/translation.json";
import ptBrTrans from "./pt-BR/translation.json";
import ruTrans from "./ru/translation.json";
import trTrans from "./tr/translation.json";
import roTrans from "./ro/translation.json";
import ukTrans from "./uk/translation.json"

const locales = {
  de: { translation: deTrans, label: "Deutsch" },
  en: { translation: enTrans, label: "English" },
  es: { translation: esTrans, label: "Español" },
  fr: { translation: frTrans, label: "Français" },
  ga: { translation: ieTrans, label: "Irish" },
  it: { translation: itTrans, label: "Italiano" },
  pl: { translation: plTrans, label: "Polski" },
  ru: { translation: ruTrans, label: "Pусский" },
  pt: { translation: ptTrans, label: "Português" },
  tr: { translation: trTrans, label: "Türkçe"},
  ro: { translation: roTrans, label: "Română"},
  uk: { translation: ukTrans, label: "Українська"},
  "pt-BR": { translation: ptBrTrans, label: "Português Brasileiro" },
};
export default locales;
