export const configMock = {
  faceQualityHostname: process.env.REACT_APP_DOMAIN_FACE_QUALITY,
  documentQualityHostname: process.env.REACT_APP_DOMAIN_DOCUMENT_QUALITY,
  sampleAppHostname: process.env.REACT_APP_DOMAIN_MIDDLEWARE,
  livenessPolicyName: process.env.REACT_APP_LIVENESS_POLICY_NAME,
  evaluationPolicyName: "WebEvaluationPolicy",
  tenant: "",
  runsWithIdp: false,
  extraConfig: {
    steps: {
      welcome: {},
      consent: {},
      phoneNumber: {
        options: {
          defaultCountry: "es",
          mobileVerificationType: "QR_SMS",
          switchToMobile: "OPTIONAL",
        },
      },
      face: {},
      document: {
        options: {
          countries: {
            br: [
              { sides: 1, aspectRatio: 1.5, type: "ID_CARD" },
              { sides: 1, aspectRatio: 1.5, type: "DRIVERS_LICENCE" },
              {
                sides: 1,
                aspectRatio: 2,
                type: "OTHER_DOCUMENT",
                label: "Residence Permit",
              },
            ],
          },
          defaultCountry: "br",
          allowedCountries: ["br"],
        },
      },
      complete: {},
    },
    verifications: {
      phoneVerificationRequired: true,
    },
    localization: {
      language: "es",
      languages: ["es", "fr", "en", "en-GB"],
      translations: {
        en: {
          "PageIntro.title": "Welcome",
        },
        es: {
          "PageIntro.title": "!Hola!",
        },
      },
    },
    featureFlags: {
      showApplicationResults: true,
      asyncDocumentProcessing: false,
      documentQualityRetries: 2,
      documentProcessorRetries: 2,
      numOfFrames: 1,
      initialDelay: 300,
      captureInterval: 200,
      timeout: 40000,
      phoneNumberDefault: "br",
      showNativeCamera: true,
      debugLinks: false,
    },
  },
};
