import styled from "styled-components/macro";

export const FlexDiv = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  overflow: hidden;
  @media (max-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) and (orientation: portrait) {
    min-height: 75vh;
  }
`;

export const Stepper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 90%;
  margin: 0 auto;
  padding: 1rem;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.smallPhoneBreakpoint + "px"}) {
    min-height: 75vh;
  }
  @media (max-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) and (orientation: portrait) {
    max-width: 100%;
    width: auto;
    padding: 0;
    & h2 {
      margin-top: 0;
      font-weight: bold;
    }
  }
`;

export const Background = styled.div`
  overflow: hidden;
  position: relative;
  text-align: center;
  color: white;
  width: 30%;
  min-height: 100vh;
  height: 100vh;

  img {
    min-width: 100%;
    min-height: 100%;
  }

  @media (max-width: ${(props) => props.theme.tabletBreakpoint + "px"}) {
    display: none;
  }
`;

export const ImageDiv = styled.div`
  display: flex;
  position: absolute;
  top: 80%;
  left: 0;
  width: 93%;
  flex-direction: column;
  align-items: flex-end;
`;

export const Paragraph = styled.p`
  font-size: 24px;
  color: white;
  font-style: italic;
  float: right;
`;

export const HorLine = styled.hr`
  margin-bottom: 0.5rem;
  background-color: ${(props) => props.theme.lightColor};
  @media (max-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) and (orientation: portrait) {
    margin-top: auto;
  }
`;
export const LanguageWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 1em;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) and (orientation: portrait) {
    text-align: center;
  }
`;

export const LogoWrapper = styled.div`
  align-self: center;
`;

export const WhiteDiv = styled.div`
  background: white;
`;
