import { store } from "../../store";
import { refreshSession } from "logic/refreshSession";
import { axiosInstance } from "logic/api";
import { isMobile, isIOS, isAndroid } from "logic/deviceType";
import { base64ImagePNG, base64ImageJPEG } from "logic/enums/base64Image";
import i18n from "i18n";

/**
 * POST /{tenant}/IdentityXServices/rest/v1/users/{userId}/face/samples
 */
export const faceSave = (data, isFacePlusEnabled) => (dispatch) => {
  const { application } = store.getState();

  if (data.includes(base64ImagePNG)) {
    data = data.replace(base64ImagePNG, "");
    if (data.includes(base64ImageJPEG)) {
      data = data.replace(base64ImageJPEG, "");
    }
  }
  let os;
  if (isMobile(navigator.userAgent)) {
    if (isIOS(navigator.userAgent)) os = "IOS";
    else if (isAndroid(navigator.userAgent)) os = "ANDROID";
    else os = "MOBILE";
  } else {
    os = "DESKTOP";
  }

  let promise = isFacePlusEnabled
    ? axiosInstance.post(
        `/IdentityXServices/rest/v1/users/${application.user.id}/samples`,
        {
          data: { value: data },
          format: "IFP",
          type: "Face",
          context: { platform: os },
        }
      )
    : axiosInstance.post(
        `/IdentityXServices/rest/v1/users/${application.user.id}/face/samples`,
        { data, format: "JPG", context: { os } }
      );

  return promise
    .then((response) => {
      refreshSession();
      if (response?.data && response.data.items) {
        let errorMessage = "";
        response.data.items.forEach((item) => {
          if (item.evaluations) {
            item.evaluations.forEach((evaluation) => {
              if (
                evaluation.qualityAssessmentResult &&
                !evaluation.qualityAssessmentResult.passed
              ) {
                const code = convertIdxResponseToErrorCode(
                  evaluation.qualityAssessmentResult.error.code
                );
                const translatedCode = `PageFaceCapture.${code}`;
                const translatedMessage = i18n.t(translatedCode);
                if (translatedMessage !== translatedCode) {
                  errorMessage += translatedMessage;
                } else {
                  errorMessage +=
                    evaluation.qualityAssessmentResult.error.message;
                }
              }
            });
          }
        });
        return { errorMessage };
      }
      return response;
    })
    .catch((error) => {
      let errorMessage = "";
      if (
        error.response &&
        error.response.data &&
        error.response.data.feedback
      ) {
        error.response.data.feedback.forEach(
          (singleError) => (errorMessage += singleError.message)
        );
      } else {
        errorMessage =
          typeof error.response !== "undefined" && error.response.data.message
            ? error.response.data.message
            : this.props.t("Common.error_connection_issues");
      }
      return { errorMessage, isError: true };
    });
};

export const convertIdxResponseToErrorCode = (idxCode) => {
  switch (idxCode) {
    case 748:
      return "failed_to_detect_face";
    case 749:
      return "too_many_faces";
    case 913:
      return "face_too_close";
    case 914:
      return "face_too_close_to_border";
    case 915:
      return "face_cropped";
    case 916:
      return "face_too_small";
    case 917:
      return "face_angle_too_large";
    case 918:
      return "face_is_occluded";
    case 919:
      return "could_not_find_face";
    case 929:
      return "failed_to_process";
    default:
      return "failed to process";
  }
};
