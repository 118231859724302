import { base64ImageRegex } from "./enums/base64Image";

export const getDocumentData = (type, subtype, value, imageFormat = "JPG") => {
  return {
    type: type,
    subtype: subtype,
    sensitiveData: {
      imageFormat: imageFormat,
      value: value.replace(base64ImageRegex, ""),
    },
  };
};
