export function formatPreviewImage(image) {
  const base64Prefixes = ["data:image/jpeg;base64,", "data:image/png;base64,"];

  if (!image || typeof image !== "string") {
    return image;
  }

  if (image.includes("http://") || image.includes("https://")) {
    return image;
  }

  for (const prefix of base64Prefixes) {
    if (image.startsWith(prefix)) {
      return image;
    }
  }

  const defaultPrefix = base64Prefixes[0];
  return defaultPrefix + image;
}
