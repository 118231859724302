import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 8;
    min-height: 78vh;
    @media only screen and (min-width:${props => props.theme.phoneBreakpoint + 'px'}) {
        justify-content: start;
    }
`;

export const Heading = styled.h2`
    margin-top: 2.5em;
`;

export const Paragraph = styled.p`
    text-align: left;
    display: inline-block;
    padding-right: 10px;
    font-size: 16px;
`;

export const QRWrapper = styled.div`
    margin: 50px 0 0 0;
`;

export const SMSWrapper = styled.div`
`;

export const SMSLink = styled.a`
    color: ${props => props.theme.mainColor};
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
`;
