import { AUTO_APPROVED, VERIFIED } from "logic/enums/applicationStatus";

export const applicationSubmitPost = {
  id: "ddb83ce7-f992-4b72-944a-e6f90e5302bc",
  user: {
    primaryPhone: "+381653445595",
    idCheck: {
      href: "https://engdobsqc-oidc-dobs.identityx-build.com/newdesigntest/DigitalOnBoardingServices/rest/v1/users/QTAz2T18fT2ABjoZ5p4gjCtUVQ/idchecks/BDP5RIptCPvjiNd2RVSaww",
    },
  },
  status: AUTO_APPROVED,
  shouldProceedOnMobile: "false",
  // redirectUrl:
  // "https://engdobsqc-oidc-dobs-keycloak.identityx-build.com/auth/realms/newdesigntest/login-actions/action-token?key=eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI1MDA3MjQ2OC04MThiLTRlOGUtOTA3Ny1lZmUwYjJkNzhiMWYifQ.eyJleHAiOjE2MzYxMDk0MjcsImlhdCI6MTYzNjEwNjcyNywianRpIjoiZmU0MjkxZDUtZmU3Ni00OTJhLWIyOTctMGQwZjkzOGQ1ZTQzIiwiaXNzIjoiaHR0cHM6Ly9lbmdkb2JzcWMtb2lkYy1kb2JzLWtleWNsb2FrLmlkZW50aXR5eC1idWlsZC5jb20vYXV0aC9yZWFsbXMvbmV3ZGVzaWdudGVzdCIsImF1ZCI6Imh0dHBzOi8vZW5nZG9ic3FjLW9pZGMtZG9icy1rZXljbG9hay5pZGVudGl0eXgtYnVpbGQuY29tL2F1dGgvcmVhbG1zL25ld2Rlc2lnbnRlc3QiLCJzdWIiOiJiNWFmMTNmNi1lM2FlLTQyNjItOWNlOS1kNzc0ZDRlM2I2ODAiLCJ0eXAiOiJleHRlcm5hbC1hcHAtbm90aWZpY2F0aW9uIiwiYXpwIjoibmV3ZGVzaWdudGVzdCIsIm5vbmNlIjoiZmU0MjkxZDUtZmU3Ni00OTJhLWIyOTctMGQwZjkzOGQ1ZTQzIiwiYXBwLWlkIjoiZGFvbkRPQlMiLCJhc2lkIjoiMTM4ZmI5NTQtZjI4NC00YmNjLTkzZDctMWRiOTQ1ODg0ZTQzLkRWanVnT2N5Vk1rLjc0OWE0ODQ5LTMyNTEtNDQ4Ny1hNzE4LWE2YjYxYTlhYWU2MSIsImFzaWQiOiIxMzhmYjk1NC1mMjg0LTRiY2MtOTNkNy0xZGI5NDU4ODRlNDMuRFZqdWdPY3lWTWsuNzQ5YTQ4NDktMzI1MS00NDg3LWE3MTgtYTZiNjFhOWFhZTYxIn0.TPhZqcLOwn5yhQGYuHPriJs9BbAvX_-kcv-P_xHVOac&client_id=newdesigntest&tab_id=DVjugOcyVMk&execution=afb0c5c3-fbc6-4c38-86dc-baae1e09259d&app-token=eyJhbGciOiJIUzI1NiJ9.eyJldmFsdWF0aW9uSHJlZiI6Imh0dHBzOi8vZW5nZG9ic3FjLW9pZGMtZG9icy5pZGVudGl0eXgtYnVpbGQuY29tL25ld2Rlc2lnbnRlc3QvRGlnaXRhbE9uQm9hcmRpbmdTZXJ2aWNlcy9yZXN0L3YxL3VzZXJzL1FUQXoyVDE4ZlQyQUJqb1o1cDRnakN0VVZRL2lkY2hlY2tzL0JEUDVSSXB0Q1B2amlOZDJSVlNhd3ciLCJ1c2VyUGhvbmUiOiIrMzgxNjUzNDQ1NTk1IiwiZXZhbHVhdGlvbkVycm9ycyI6Im51bGwiLCJldmFsdWF0aW9uU3RhdHVzIjoiQVVUT19BUFBST1ZFRCJ9._nxKzVcrK7IlRj3h9BlRs33gtqYVuEwXCOE62mXnTPk",
  interruptIDPAuth: false,
  verificationCode: 0,
  languageTag: "en-US",
  cancelled: false,
};

export const applicationQR = {
  id: "ddb83ce7-f992-4b72-944a-e6f90e5302bc",
  user: {
    primaryPhone: "+381653445595",
    idCheck: {
      href: "https://engdobsqc-oidc-dobs.identityx-build.com/newdesigntest/DigitalOnBoardingServices/rest/v1/users/QTAz2T18fT2ABjoZ5p4gjCtUVQ/idchecks/BDP5RIptCPvjiNd2RVSaww",
    },
  },
  status: VERIFIED,
  shouldProceedOnMobile: "false",
  // redirectUrl:
  // "https://engdobsqc-oidc-dobs-keycloak.identityx-build.com/auth/realms/newdesigntest/login-actions/action-token?key=eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI1MDA3MjQ2OC04MThiLTRlOGUtOTA3Ny1lZmUwYjJkNzhiMWYifQ.eyJleHAiOjE2MzYxMDk0MjcsImlhdCI6MTYzNjEwNjcyNywianRpIjoiZmU0MjkxZDUtZmU3Ni00OTJhLWIyOTctMGQwZjkzOGQ1ZTQzIiwiaXNzIjoiaHR0cHM6Ly9lbmdkb2JzcWMtb2lkYy1kb2JzLWtleWNsb2FrLmlkZW50aXR5eC1idWlsZC5jb20vYXV0aC9yZWFsbXMvbmV3ZGVzaWdudGVzdCIsImF1ZCI6Imh0dHBzOi8vZW5nZG9ic3FjLW9pZGMtZG9icy1rZXljbG9hay5pZGVudGl0eXgtYnVpbGQuY29tL2F1dGgvcmVhbG1zL25ld2Rlc2lnbnRlc3QiLCJzdWIiOiJiNWFmMTNmNi1lM2FlLTQyNjItOWNlOS1kNzc0ZDRlM2I2ODAiLCJ0eXAiOiJleHRlcm5hbC1hcHAtbm90aWZpY2F0aW9uIiwiYXpwIjoibmV3ZGVzaWdudGVzdCIsIm5vbmNlIjoiZmU0MjkxZDUtZmU3Ni00OTJhLWIyOTctMGQwZjkzOGQ1ZTQzIiwiYXBwLWlkIjoiZGFvbkRPQlMiLCJhc2lkIjoiMTM4ZmI5NTQtZjI4NC00YmNjLTkzZDctMWRiOTQ1ODg0ZTQzLkRWanVnT2N5Vk1rLjc0OWE0ODQ5LTMyNTEtNDQ4Ny1hNzE4LWE2YjYxYTlhYWU2MSIsImFzaWQiOiIxMzhmYjk1NC1mMjg0LTRiY2MtOTNkNy0xZGI5NDU4ODRlNDMuRFZqdWdPY3lWTWsuNzQ5YTQ4NDktMzI1MS00NDg3LWE3MTgtYTZiNjFhOWFhZTYxIn0.TPhZqcLOwn5yhQGYuHPriJs9BbAvX_-kcv-P_xHVOac&client_id=newdesigntest&tab_id=DVjugOcyVMk&execution=afb0c5c3-fbc6-4c38-86dc-baae1e09259d&app-token=eyJhbGciOiJIUzI1NiJ9.eyJldmFsdWF0aW9uSHJlZiI6Imh0dHBzOi8vZW5nZG9ic3FjLW9pZGMtZG9icy5pZGVudGl0eXgtYnVpbGQuY29tL25ld2Rlc2lnbnRlc3QvRGlnaXRhbE9uQm9hcmRpbmdTZXJ2aWNlcy9yZXN0L3YxL3VzZXJzL1FUQXoyVDE4ZlQyQUJqb1o1cDRnakN0VVZRL2lkY2hlY2tzL0JEUDVSSXB0Q1B2amlOZDJSVlNhd3ciLCJ1c2VyUGhvbmUiOiIrMzgxNjUzNDQ1NTk1IiwiZXZhbHVhdGlvbkVycm9ycyI6Im51bGwiLCJldmFsdWF0aW9uU3RhdHVzIjoiQVVUT19BUFBST1ZFRCJ9._nxKzVcrK7IlRj3h9BlRs33gtqYVuEwXCOE62mXnTPk",
  interruptIDPAuth: false,
  verificationCode: 0,
  languageTag: "en-US",
  cancelled: false,
};
