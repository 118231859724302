import styled from 'styled-components'

export const GifWrapper = styled.div`
    width: 100%;
    padding: 1em;

    & img {
        width: 100%;
        margin-bottom: 1rem;
    }

    @media only screen and (min-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
        & img {
            max-width: 500px;
            margin: 1rem auto;
        }
    }
`