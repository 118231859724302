import styled from "styled-components/macro";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  height: 100%;
  position: relative;
  z-index: 8;

  @media only screen and (min-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) {
    justify-content: center;
  }
`;

export const H2 = styled.h2`
  margin-bottom: 0px;
  margin-block: 0px;
`;

export const DescPar = styled.p`
  font-size: 14px;
  margin-bottom: 32px;
`;

export const PanelDiv = styled.div`
  display: flex;
  border-radius: 5px;
  align-items: center;
  background: ${(props) => props.theme.panelBackground};
  text-decoration: none;
  margin-bottom: 15px;
  cursor: pointer;
  max-width: 350px;
  max-height: 60px;
  padding: 2px;
  overflow: hidden;

  @media (max-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) and (orientation: portrait) {
    max-width: 100%;
  }
`;

export const PanelP = styled.p`
  font-size: 15px;
  flex: 1;
  padding-bottom: 2px;
  max-width: 65%;
`;
export const IconDiv = styled.div`
  align-self: center;
  margin: 0 20px;
`;

export const ChangeCountryButton = styled.button`
  color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  background-color: white;
`;
export const BottomP = styled.p`
  font-size: 14px;
  margin-top: 50px;
`;
