import {
  CAMERA_INDEX,
  CAMERA_SET_CAMERA_LIST,
  CAMERA_SET_SELECTED_CAMERA,
  CHANGE_CAMERA,
} from "logic/actions/camera";

const defaultState = {
  selectedCamera: undefined,
  cameras: [],
  cameraIndex: 0,
  cameraId: undefined,
};

export function camera(state = defaultState, action) {
  switch (action.type) {
    case CAMERA_SET_CAMERA_LIST:
      return { ...state, cameras: action.payload };
    case CAMERA_SET_SELECTED_CAMERA:
      return { ...state, selectedCamera: action.payload };
    case CHANGE_CAMERA:
      return {
        ...state,
        cameraId: action.payload,
      };
    case CAMERA_INDEX: {
      return {
        ...state,
        cameraIndex: action.payload,
      };
    }
    default:
      return state;
  }
}

export const selectCameraIndex = (state) => {
  return state.camera?.cameraIndex;
};
