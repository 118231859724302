import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { nextPage, nextStep, go } from "logic/actions/navigation";
import PageContent from "Components/PageContent";
import { isFirefox, isMobile, isOpera } from "logic/deviceType";
import * as S from "./PageQRInstructions.styles";
import { useTranslation } from "react-i18next";
import DaonButton from "Components/common/DaonButton";
import DaonCheckbox from "Components/common/DaonCheckbox";
import {
  applicationUpdate,
  applicationUpdateParams,
  applicationQRValidate,
} from "logic/actions/api/application";
import { setApplicationData } from "logic/actions/application";
import QRStepper from "../QRStepper";
import {
  logEvent,
  skipped_phone_verification,
  switching_to_mobile,
} from "logic/eventLogger";
import {
  CREATE_CHALLENGE,
  PAGE_SESSION_EXPIRED,
  QR_INSTRUCTIONS,
  QR_SCAN,
} from "logic/enums/pages";
import {
  hideSwitchToMobileChecker,
  redirectFrom,
} from "logic/actions/phoneValidation";
import DaonSpinner from "Components/common/DaonSpinner";
import { MANDATORY, QR, QR_API } from "logic/enums/verification";
import { getTimePassed, qr_code, startEventTimer } from "logic/stepTimers";
import DaonErrorBox from "Components/common/DaonErrorBox";
import resolutionCheckMethod from "logic/resolutionCheck";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { selectIsQrInstructionsSkipped } from "logic/reducers/configuration";

export const PageQRInstructions = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [resolutionTest, setResolutionTest] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [pending, setPending] = useState(false);
  const [shouldStayOnDesktop, setShouldStayOnDesktop] = useState(
    isMobile(navigator.userAgent)
  );
  const theme = useContext(ThemeContext);

  const [disabled, setDisabled] = useState(false);

  const switchToMobile = useSelector((state) => {
    return state.configuration?.extraConfig?.steps?.phoneNumber?.options
      ?.switchToMobile;
  });
  const shouldSkipQrInstructions = useSelector(selectIsQrInstructionsSkipped);
  const isMobileCheck = useSelector((state) => {
    return state.isMobile;
  });

  const updateApplication = async () => {
    const application = await applicationUpdate(QR_API)(dispatch);
    setApplicationData(application.data);
    return application;
  };

  useEffect(() => {
    if (!getTimePassed(qr_code)) startEventTimer(qr_code);
    if (
      !isMobileCheck &&
      props.rejectLowResolutionDevices &&
      switchToMobile !== MANDATORY
    ) {
      checkDeviceResolution();
    }

    document.title = `${t("PageValidatePhone.header")} | Onboarding`;
  }, []);

  useEffect(() => {
    if (shouldSkipQrInstructions) submit();
  }, []);

  const checkDeviceResolution = () => {
    let width = props.docCaptureWidth ? parseInt(props.docCaptureWidth) : 1920;
    let height = props.docCaptureHeigth
      ? parseInt(props.docCaptureHeigth)
      : 1080;

    setPending(true);
    setLoadingText(t("PageValidatePhone.resolution_check"));
    resolutionCheckMethod(width, height)
      .catch(() => {
        setResolutionTest(false);
      })
      .finally(() => {
        setPending(false);
        setLoadingText("");
      });
  };

  const submit = async () => {
    setDisabled(true);
    if (!shouldStayOnDesktop || switchToMobile === MANDATORY) {
      logEvent(switching_to_mobile, {
        type: QR,
      });
      props.redirectFrom(QR_INSTRUCTIONS);
      props.hideSwitchToMobileChecker(true);
      try {
        await applicationUpdateParams()(dispatch);
        await updateApplication();
        props.nextPage();
      } catch (err) {
        props.go(PAGE_SESSION_EXPIRED);
      }
    } else {
      const messageToUser = props.application?.verifications?.find(
        (verification) => verification?.type === QR
      )?.messageToUser;
      try {
        if (messageToUser) await applicationQRValidate(messageToUser)(dispatch);
        await applicationUpdateParams(shouldStayOnDesktop)(dispatch);
        logEvent(skipped_phone_verification);

        props.go(CREATE_CHALLENGE);
      } catch (err) {
        props.go(PAGE_SESSION_EXPIRED);
      }
    }
  };

  const toggleStayOnDesktop = (event) => {
    setShouldStayOnDesktop(event.target.checked);
  };
  if (shouldSkipQrInstructions)
    return (
      <>
        {!props.ignoreSpinner && (
          <PageContent
            justifyContent="center"
            showBack={false}
            title={t("PageQRInstructions.title")}
          >
            <h2>{t("Common.processing")}</h2>
            <DaonSpinner color={theme.spinner} />
          </PageContent>
        )}
      </>
    );

  return (
    <PageContent
      toggleLoading={pending}
      showBack={false}
      instructions={true}
      title={t("PageQRInstructions.title")}
      loadingText={loadingText}
    >
      <S.Content>
        <h2>{t("PageQRInstructions.heading")}</h2>

        <S.Paragraph>{t("PageQRInstructions.description")}</S.Paragraph>
        <QRStepper />
        {props.phoneValidation.redirectedFrom === QR_SCAN && (
          <DaonErrorBox
            whiteBackground={true}
            message={t("PageQRInstructions.expirationMessage")}
          ></DaonErrorBox>
        )}
        {switchToMobile !== MANDATORY &&
          !isFirefox(navigator.userAgent) &&
          !isOpera(navigator.userAgent) &&
          (resolutionTest ? (
            <S.SendToMobile>
              <S.CheckWrapper>
                <DaonCheckbox
                  checked={shouldStayOnDesktop}
                  onChange={toggleStayOnDesktop}
                />
                <S.Paragraph transparent={!shouldStayOnDesktop}>
                  {t("PageQRInstructions.stay_on_desktop")}
                </S.Paragraph>
              </S.CheckWrapper>
            </S.SendToMobile>
          ) : (
            <div>
              <S.Paragraph>
                {t("PageValidatePhone.low_resolution_info")}
              </S.Paragraph>
            </div>
          ))}
        <DaonButton id="continue" disabled={disabled} onClick={submit}>
          {t("PageQRInstructions.continue_btn")}
        </DaonButton>
      </S.Content>
    </PageContent>
  );
};

export default connect(
  (state) => ({
    ...state,
    docCaptureWidth:
      state.configuration.extraConfig?.steps?.document?.options?.width ||
      state.configuration.extraConfig?.featureFlags.width,
    docCaptureHeigth:
      state.configuration.extraConfig?.steps?.document?.options?.height ||
      state.configuration.extraConfig?.featureFlags.heigth,
    ignoreSpinner: state.configuration.extraConfig?.featureFlags?.ignoreSpinner,
    rejectLowResolutionDevices:
      !!state.configuration.extraConfig?.steps?.document?.options
        ?.rejectLowResolutionDevices,
  }),
  {
    nextPage,
    nextStep,
    go,
    hideSwitchToMobileChecker,
    applicationUpdateParams,
    applicationQRValidate,
    redirectFrom,
  }
)(PageQRInstructions);
