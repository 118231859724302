/*global Daon */
/*eslint no-undef: "error"*/
import packageJson from "../../../../package.json";
import * as S from "./Version.styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsVersionDataAvailable,
  selectWebVersionInfo,
} from "logic/reducers/version";
import { setVersionData } from "logic/actions/version";

export const version = packageJson.version;

let _webVersionInfo = `Web Application Version: ${version} \n`;

export const Version = (props) => {
  const isVersionDataAvailable = useSelector(selectIsVersionDataAvailable);
  const webVersionInfo = useSelector(selectWebVersionInfo);
  const dispatch = useDispatch();

  const getWebVersionInfo = () => {
    if (global.DAON_BUILD_VERSION && !global.DAON_BUILD_VERSION.includes("@"))
      _webVersionInfo += `(build version: ${global.DAON_BUILD_VERSION}) \n`;

    if (global.Daon) {
      if (Daon.FaceCapture && Daon.FaceCapture.VERSION)
        _webVersionInfo += `FaceCapture: ${Daon.FaceCapture.VERSION} \n`;
      if (Daon.DocumentCapture && Daon.DocumentCapture.VERSION)
        _webVersionInfo += `DocumentCapture: ${Daon.DocumentCapture.VERSION} \n`;
    }
    if (process.env.NODE_ENV !== "test") {
      console.log(_webVersionInfo);
    }
    dispatch(
      setVersionData({
        webVersionInfo: _webVersionInfo,
        isVersionDataAvailable: true,
      })
    );
  };

  useEffect(() => {
    if (!isVersionDataAvailable) {
      getWebVersionInfo();
    }
  }, []);

  return (
    <>
      {props.showApiVersionInfo && (
        <S.Version
          role="img"
          aria-label="version info"
          title={webVersionInfo}
          onClick={() => alert(webVersionInfo)}
        >
          ℹ️
        </S.Version>
      )}
    </>
  );
};

export default Version;
