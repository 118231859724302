import { API_SET_CONFIGURATION } from "../actions/configuration";
import { getTenantNameFromHost } from "../tenant";

export const defaultState = {
  faceQualityHostname: process.env.REACT_APP_DOMAIN_FACE_QUALITY,
  sampleAppHostname: process.env.REACT_APP_DOMAIN_MIDDLEWARE,
  livenessPolicyName: process.env.REACT_APP_LIVENESS_POLICY_NAME,
  tenant: process.env.REACT_APP_TENANT || getTenantNameFromHost(),
  gdprServiceHostname: process.env.REACT_APP_GDPR_SERVICE_HOSTNAME,
  extraConfig: {
    featureFlags: {
      showApplicationResults: true,
    },
  },
};

export function configuration(state = defaultState, action) {
  switch (action.type) {
    case API_SET_CONFIGURATION:
      return {
        ...state,
        ...action.payload,
        extraConfig: {
          ...state.extraConfig,
          ...action.payload.extraConfig,
          featureFlags: {
            ...state.extraConfig.featureFlags,
            ...((action.payload.extraConfig &&
              action.payload.extraConfig.featureFlags) ||
              {}),
          },
        },
      };
    default:
      return state;
  }
}

export const selectWhiteLabel = (state) => {
  return state.configuration.extraConfig
    ? state.configuration.extraConfig.whitelabel
    : undefined;
};

export const selectSessionExpirationTimeSeconds = (state) => {
  return state.configuration.sessionDurationSeconds
    ? state.configuration.sessionDurationSeconds
    : 2700;
};

export const selectSupportedLanguages = (state) => {
  return (
    state.configuration.extraConfig.localization &&
    state.configuration.extraConfig.localization.languages
  );
};

export const selectTranslations = (state) => {
  let languages =
    state.configuration.extraConfig.localization &&
    state.configuration.extraConfig.localization.translations;

  if (!languages) {
    languages = state.configuration.extraConfig.languages
      ? state.configuration.extraConfig.languages
      : [];
  }
  return languages;
};

export const selectLanguage = (state) => {
  return state.configuration.extraConfig.localization &&
    state.configuration.extraConfig.localization.language
    ? state.configuration.extraConfig.localization.language
    : undefined;
};

export const selectInitialNFCRetries = (state) => {
  return state.configuration?.extraConfig?.steps?.nfc?.options?.numberOfRetries;
};

export const selectIsNFCOptional = (state) => {
  return state.configuration?.extraConfig?.steps?.nfc?.options?.optional;
};

export const selectIsQrInstructionsSkipped = (state) => {
  return state.configuration?.extraConfig?.steps?.phoneNumber?.options
    ?.shouldSkipQrInstructions;
};

export const shouldSkipIntroScreenDesktop = (state) => {
  return state.configuration?.extraConfig?.featureFlags
    ?.shouldSkipIntroScreenDesktop;
};
export const shouldSkipIntroScreenMobile = (state) => {
  return state.configuration?.extraConfig?.general?.shouldSkipIntroScreenMobile || state.configuration?.extraConfig?.featureFlags?.shouldSkipIntroScreenMobile;
};
