import {
  SWITCHED_TO_MOBILE_PHONE,
  API_SET_APPLICATION_DATA,
  SET_APPLICATION_ID,
  SET_VALIDATE_PHONE_SESSION_DURATION,
} from "../actions/application";
const defaultState = {
  applicationId: null,
  isSwitchedToMobile: false,
  validatePhoneSessionDuration: null,
};

export function application(state = defaultState, action) {
  switch (action.type) {
    case SET_APPLICATION_ID:
      return {
        ...state,
        applicationId: action.payload,
      };
    case API_SET_APPLICATION_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SWITCHED_TO_MOBILE_PHONE:
      return {
        ...state,
        isSwitchedToMobile: action.payload,
      };

    case SET_VALIDATE_PHONE_SESSION_DURATION:
      return {
        ...state,
        validatePhoneSessionDuration: action.payload,
      };

    default:
      return state;
  }
}
