import {
  readLocalCache,
  writeToLocalCache,
  CACHE_SESSION_DATA,
} from "logic/localCacheAdapter";

export function refreshSession() {
  let cacheSessionData = readLocalCache(CACHE_SESSION_DATA);
  if (cacheSessionData) {
    cacheSessionData.timestamp = new Date().getTime();
    writeToLocalCache(cacheSessionData, CACHE_SESSION_DATA);
  }
}
