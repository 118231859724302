import styled from 'styled-components/macro'

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80vh; 
    height: 100%;
    position: relative;
    z-index: 8;

    @media only screen and (min-width: 800px) {
        justify-content: center;
    }

    @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) and (orientation: portrait) {
        min-height: initial;
        height: initial;
    }
`;

export const Heading = styled.h2`
    text-align: left;
    @media (min-width: ${props => props.theme.tabletBreakpoint + 'px'}) {
        margin-top: 3rem;
    }
`;

export const Paragraph = styled.p`
    text-align: left;
    font-size: 18px;
    line-height: 23px;
    margin: ${({ margin }) => margin || "1em 0"};
    @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
        font-size: 16px;
        line-height: 21px;
    }
`;
