import { useEffect, useRef } from "react";
import * as S from "./SMSInput.styles";

export default function SMSInput({ codeLength = 6, onCodeChange, hasError }) {
  const charsRef = useRef([]);

  useEffect(() => {
    charsRef.current = charsRef.current.slice(0, codeLength);
    if (charsRef.current && charsRef.current[0]) charsRef.current[0].focus();
  }, [codeLength]);

  const codeChanged = () => {
    let newCode = "";
    for (let i = 0; i < charsRef.current.length; i++) {
      newCode += charsRef.current[i].value;
    }
    onCodeChange(newCode);
  };

  const handleKeyDown = (e, index) => {
    if (e.keyCode === 8 && !charsRef.current[index].value && index > 0) {
      charsRef.current[index - 1].select();
      codeChanged();
    }
  };

  const handleKeyUp = (e, index) => {
    if (
      e.keyCode === 16 ||
      e.keyCode === 9 ||
      e.keyCode === 224 ||
      e.keyCode === 18 ||
      e.keyCode === 17 ||
      e.keyCode === 8
    ) {
      return;
    }

    if (e.keyCode === 37 && index > 0) {
      charsRef.current[index - 1].select();
    } else if (
      e.keyCode !== 8 &&
      index < codeLength - 1 &&
      charsRef.current[index].value !== ""
    ) {
      if (!charsRef.current[index + 1].value) {
        charsRef.current[index + 1].select();
      } else {
        let i = index + 1;
        while(charsRef.current[i].value && i < codeLength - 1) {
          i++;
        }
        charsRef.current[i].select();
      }
    }
    if (
      charsRef.current[index].value &&
      charsRef.current[index].value.length > 1
    ) {
      charsRef.current[index].value = charsRef.current[index].value.substring(
        0,
        1
      );
    }
    codeChanged();
  };

  const onInput = (e) => {
    let data = e.data || charsRef.current[0].value;
    if (!data) return;
    if (data.length === 1) return;
    data = data.substring(0, codeLength);

    for (let i = 0; i < data.length; i++) {
      charsRef.current[i].value = data[i];
    }
    onCodeChange(data);
  };

  const onFocus = (e, index) => {
    hasError = false;
    if (index === 0) return;

    // If value of input 1 is empty, focus it.
    if (charsRef.current[0] && charsRef.current[0].value === "") {
      charsRef.current[0].focus();
    }
    if (charsRef.current[index - 1].value === "") {
      charsRef.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (charsRef.current[0]) charsRef.current[0].focus();
  }, []);

  var inputs = [];

  for (let i = 0; i < codeLength; i++) {
    inputs.push(
      <S.InputField
        key={"otc-" + i}
        ref={(el) => (charsRef.current[i] = el)}
        onKeyDown={(e) => handleKeyDown(e, i)}
        onKeyUp={(e) => handleKeyUp(e, i)}
        onFocus={(e) => onFocus(e, i)}
        type="number"
        pattern="[0-9]*"
        autoComplete="one-time-code"
        id={"otc-" + i}
        required={i < 4}
        onInput={(e) => {
          if (i === 0) onInput(e);
        }}
        maxLength="1"
        className={hasError ? "error" : ""}
      />
    );
  }

  return <div name="one-time-code">{inputs}</div>;
}
