import { useSelector } from "react-redux";
import * as S from "./WhitelabelCompanyLogo.styles";
import { useTranslation } from "react-i18next";

export default function WhitelabelCompanyLogo({white, logoSize}) {
  const { t } = useTranslation();
  const companyLogo = useSelector((state) => {
    const translatedLogo = t('Common.company_logo');
    if (translatedLogo !== '') {
      return translatedLogo;
    }
    if (
      state.configuration.extraConfig &&
      state.configuration.extraConfig.whitelabel
    ) {
      return state.configuration.extraConfig.whitelabel.companyLogo;
    }
  });

  if (companyLogo)
    return (
      <S.CompanyLogo className={white ? 'white-logo' : ''} logoSize={logoSize}>
        <img src={companyLogo} alt="Daon logo short" />
      </S.CompanyLogo>
    );

  return null;
}
