export const getVersionsData = () => {
  return fetch(
    process.env.NODE_ENV === "development"
      ? `/versions-dev.json`
      : "/versions.json"
  ).then((response) => {
    const versionsInfo = response.json();
    return versionsInfo;
  });
};
