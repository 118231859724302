import Axios from "axios";
import { toggleNetworkWarnings } from "logic/actions/apiFetch";
import { store } from "../store";
import { go } from "logic/actions/navigation";
import { PAGE_SESSION_EXPIRED } from "logic/enums/pages";
import i18n from "i18next";

const axiosInstance = Axios.create({
  withCredentials: false,
});

axiosInstance.defaults.headers["Content-Type"] = "application/json";

axiosInstance.interceptors.request.use((request) => {
  dispatchToggleNetworkWarnings(true);
  if (
    request.url === "/actuator/info" ||
    request.url === "/url_configuration.json" ||
    request.url === "/userConsents"
  )
    return request;
  try {
    const { configuration } = store.getState();
    if (request.url === `${configuration.gdprServiceHostname}/userConsents`)
      return request;
    const { tenant } = configuration;
    const subUrl = tenant
      ? `/${tenant.replace(/\//g, "")}${request.url}`
      : request.url;
    if (!request.params) {
      request.params = {};
    }
  
    if (!request.params.languageTag) {
      request.params.languageTag = i18n.language;
    }
    request.url = `${subUrl}`;
  } catch (err) {
    console.log("Axios interceptor error: Request add tenant to URL", err);
  }
  return request;
});

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.headers) {
      const csrfHeaderKey = response.headers["x-csrf-header"];
      if (csrfHeaderKey) {
        axiosInstance.defaults.headers.common[csrfHeaderKey] =
          response.headers[csrfHeaderKey.toLowerCase()];
      }
    }
    dispatchToggleNetworkWarnings(false);
    return response;
  },
  function (error) {
    dispatchToggleNetworkWarnings(false);
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      error.response.config &&
      error.response.config.url.indexOf("smsCode") === -1 &&
      error.response.config.url.indexOf("actuator") === -1
    ) {
      store.dispatch(go(PAGE_SESSION_EXPIRED));
    }
    return Promise.reject(error);
  }
);

function dispatchToggleNetworkWarnings(toggle) {
  try {
    store.dispatch(toggleNetworkWarnings(toggle));
  } catch (err) {
    console.log(
      "Axios interceptor error: Request dispatch network warning",
      err
    );
  }
}

export { axiosInstance };
