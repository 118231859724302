import styled from "styled-components/macro";

const DaonButton = styled.button`
  background: ${props => props.outline ? 'transparent' : props.theme.mainColor};
  box-shadow: 0px 1px 5px rgba(158, 86, 2, 0.2);
  border-radius: 8px;
  border: ${props => props.outline ? '2px solid ' + props.theme.outlineButtonText : '2px solid ' + props.theme.mainColor};
  max-width: 300px;
  height: 54px;
  width: 100%;
  align-items: center;
  font-size: 16px;
  text-align: center;
  color: ${props => props.outline ? props.theme.outlineButtonText : props.theme.mainButtonText};
  cursor: pointer;
  display: block;
  position: relative;
  //z-index: 20;
  margin: ${props => props.margin ? props.margin : ''};
  margin-bottom: 1rem;
  &:active {
    ${props=>{
      return props.outline ? "background: rgba(230, 230, 230, 0.15);" : "opacity: 70%;"
      }}}
  &:disabled {
    pointer-events: none;
    filter: grayscale(0.7);
    cursor: not-allowed;
  }
  @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) and (orientation: portrait) {
      width: 100%;
      max-width: 100%;
      
  }

  /* @media (orientation: landscape) {
    margin-right: 15px;
  } */


  &.secondary {
    border: 1px solid #6F7479;
    background: transparent;
    color: #6F7479;
    box-shadow: none;
  }
`;
export default DaonButton;
