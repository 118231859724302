import {
  SET_MOBILE_PLATFORM,
  SET_NFC_CHALLENGE,
  INCREASE_NFC_RETRIES,
  RESTART_NFC_RETRIES,
  SET_SDK_VERSION
} from "logic/actions/composite";
export const defaultState = {
  mobileAppPlatform: null,
  nfcTestData: undefined,
  nfcRetries: 0,
  sdkVersion: null,
};

export function composite(state = defaultState, action) {
  switch (action.type) {
    case SET_MOBILE_PLATFORM:
      return {
        ...state,
        mobileAppPlatform: action.payload,
      };
    case SET_NFC_CHALLENGE:
      return {
        ...state,
        nfcTestData: action.payload,
      };
    case SET_SDK_VERSION:
      return {
        ...state,
        sdkVersion: action.payload,
      };
    case INCREASE_NFC_RETRIES:
      return {
        ...state,
        nfcRetries: state.nfcRetries + 1,
      };
    case RESTART_NFC_RETRIES:
      return {
        ...state,
        nfcRetries: 0,
      };
    default:
      return state;
  }
}

export const selectIsCompositeFlow = (state) => {
  return !!state.composite.mobileAppPlatform;
};

export const selectNFCRetriesCounter = (state) => {
  return state.composite.nfcRetries;
};

export const selectMobileAppPlatform = (state) => {
  return state.composite?.mobileAppPlatform;
};

export const selectNFCTestData = (state) => {
  return state.composite?.nfcTestData?.challenge;
};
export const selectSDLVersion = (state) => {
  return state.composite?.sdkVersion;
}