import { SET_VERSION_DATA } from "../actions/version";

export function version(
  state = {
    webVersionInfo: "",
    isVersionDataAvailable: false,
  },
  action
) {
  switch (action.type) {
    case SET_VERSION_DATA:
      return {
        ...state,
        webVersionInfo: action.payload.webVersionInfo,
        isVersionDataAvailable: action.payload.isVersionDataAvailable,
      };
    default:
      return state;
  }
}

export const selectWebVersionInfo = (state) => {
  return state.version.webVersionInfo;
};

export const selectIsVersionDataAvailable = (state) => {
  return state.version.isVersionDataAvailable;
};
