import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
html {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
  }
  
  * {
    box-sizing: border-box;
    font-family: "IBM Plex Sans";
  }
  
  @supports (font-variation-settings: normal) {
    html {
      font-family: "IBM Plex Sans", sans-serif;
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.textPrimary};
  }
  
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    text-align: left;
    margin-bottom: 1rem;
  }
  
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 2em;
    text-align: left;
    margin-bottom: 1rem;
  }
  
  button {
    font: 600 1em "IBM Plex Sans";
    cursor: pointer;
    border: none;
  }
  
  p {
    color: ${props => props.theme.textSecondary};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin: 1rem 0;
    @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
        font-size: 16px;
        line-height: 21px;
    }

    @media (max-width: ${props => props.theme.smallPhoneBreakpoint + 'px'}) {
      font-size: 13px;
      line-height: 17px;
    }
  }

  label {
    color: ${props => props.theme.textSecondary};
    font-size: 14px;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: ${props => props.theme.inputColor};
    color: ${props => props.theme.textPrimary};
  }
  
`