import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dobsImg from "../../assets/images/dobs_ebook.png";
import * as S from "./PageIntroScreen.styles";
import { nextStep } from "logic/actions/navigation";
import PageContent from "../../Components/PageContent";
import {
  selectIsCompositeFlow,
  selectMobileAppPlatform,
} from "logic/reducers/composite";
import { useTranslation } from "react-i18next";
import SelectLanguage from "Components/common/SelectLanguage";
import { isMobile } from "logic/deviceType";
import DaonButton from "Components/common/DaonButton";
import ProgressStepper from "./ProgressStepper";
import PoweredByDaonAbsolute from "Components/common/PoweredByDaonAbsolute";
import WhitelabelCompanyLogo from "Components/common/WhitelabelCompanyLogo/WhitelabelCompanyLogo";
import PoweredByDaon from "Components/common/PoweredByDaon";
import { webView } from "logic/webView";
import { ANDROID, PAGE_INTRO_BACK_PRESS } from "logic/enums/composite";
import { applicationUpdateParams } from "logic/actions/api/application";
import {
  shouldSkipIntroScreenDesktop,
  shouldSkipIntroScreenMobile,
} from "logic/reducers/configuration";

function PageIntroScreen({ onClick, isSwitchingToMobile }) {
  const dispatch = useDispatch();
  const isCompositeFlow = useSelector(selectIsCompositeFlow);
  const mobileAppPlatform = useSelector(selectMobileAppPlatform);
  const shouldSkipIntroDesktop = useSelector(shouldSkipIntroScreenDesktop);
  const shouldSkipSwitchIntroScreen = useSelector(shouldSkipIntroScreenMobile);
  const { t } = useTranslation();

  const title = t("PageIntro.header");
  useEffect(() => {
    if (!isMobile(navigator.userAgent) && shouldSkipIntroDesktop) {
      next();
    }
    if (isSwitchingToMobile && shouldSkipSwitchIntroScreen) {
      onClick();
    }
    !isSwitchingToMobile && dispatch(applicationUpdateParams());
    if (mobileAppPlatform === ANDROID)
      window.onMobileBackPressed = onPageIntroBackPressed;
    return () => {
      if (mobileAppPlatform === ANDROID) window.onMobileBackPressed = null;
    };
  }, []);

  function onPageIntroBackPressed() {
    webView(PAGE_INTRO_BACK_PRESS);
  }

  function next() {
    nextStep()(dispatch);
  }
  const originalScreen = (
    <S.FlexDiv>
      <S.Stepper>
        {!isMobile(navigator.userAgent) && (
          <S.LogoWrapper>
            <WhitelabelCompanyLogo logoSize={60} />
          </S.LogoWrapper>
        )}
        <h2>
          {isSwitchingToMobile
            ? t("PageIntro.switchTitle")
            : t("PageIntro.title")}
        </h2>
        <p>{!isCompositeFlow && t("PageIntro.subtitle")}</p>
        <ProgressStepper />
        {!isCompositeFlow && <S.HorLine aria-hidden="true" />}
        <S.Wrapper>
          <S.LanguageWrapper>
            <SelectLanguage
              moveDialog={true}
              isIntroScreen={true}
              shouldUpdateLanguage={!!isSwitchingToMobile}
            />
          </S.LanguageWrapper>
          <DaonButton onClick={onClick || next} id="get_started">
            {t("PageIntro.get_started")}
          </DaonButton>
          {!isCompositeFlow && isMobile(navigator.userAgent) && (
            <PoweredByDaon />
          )}
        </S.Wrapper>
      </S.Stepper>
      <S.Background>
        <img src={dobsImg} alt="" />
        <S.ImageDiv>
          <S.Paragraph>{t("PageIntro.safety")}</S.Paragraph>
          <PoweredByDaonAbsolute white={true} className="introScreen" />
        </S.ImageDiv>
      </S.Background>
    </S.FlexDiv>
  );

  const whiteScreen = <S.WhiteDiv />;
  return (
    <PageContent
      isIntroScreen={true}
      showBack={false}
      hideFooter={true}
      title={title}
      hideHeader={!!isSwitchingToMobile}
    >
      {isSwitchingToMobile && shouldSkipSwitchIntroScreen
        ? whiteScreen
        : originalScreen}
    </PageContent>
  );
}

export default PageIntroScreen;
