import styled from 'styled-components/macro'

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 8;
    min-height: 78vh;

    @media only screen and (min-width:${props => props.theme.phoneBreakpoint + 'px'}) {
        justify-content: center;
    }
`;

export const Paragraph = styled.p`
    text-align: left;
    font-size: 18px;
    line-height: 23px;
    @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
        font-size: 16px;
        line-height: 21px;
    }
`;

export const Label = styled.label`
    margin-bottom: 7px;
`;

export const Recaptcha = styled.div`
    display: flex;
    justify-content: center;
    margin: ${({ margin }) => margin || "1rem"};
`;

export const CheckWrapper = styled.div`
   display: flex;
   margin: 10px 0 15px 0;
`

export const Form = styled.form`
    .react-tel-input {
        .special-label {
            display: block;
            left: 48px;
            top: 6px;
            color: ${props => props.theme.textSecondary};
            font-size: 11px;
            line-height: 14px;
            background-color: ${props => props.theme.inputColor};
        }
        .form-control {
            background-color: ${props => props.theme.inputColor};
            @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) and (orientation: portrait) {
                width: 100% !important;
            }
        }
        .flag-dropdown {
            border: 2px solid #DDE1E6;
            border-radius: 8px 0 0 8px;
            &.open {
                z-index: 50;        
            }
        }
        
        .country-list {
            left: -1px;
            margin-top: 6px;
            .search {
                padding-bottom: 15px;
                & input {
                    width: 92%;
                }
            }
        }
    }
    margin: ${props => props.switchToMobileCheckerHidden && "10px 0 25px 0"};
`;

export const SendToMobile = styled.label`
    color: rgb(80, 93, 100);
    font-weight: 500;
`;
