import { store } from "../store";
import { axiosInstance } from "logic/api";

/**
 * POST https://{gdprServiceHostname}/userConsents
 * @returns {Promise}
 * @example
 * request:
 * {
 *  serviceId: "www.example.com",
 *  appId: "Onboarding Web App",
 *  userId: "", //guid
 *  production: true,
 *  consents: "", //stringified JSON
 * }
 * response:
 * { ok: true }
 */
export function sendGDPRConsent(request) {
  const gdprUrl = getGDPRUrl();
  return axiosInstance.post(`${gdprUrl}/userConsents`, request, {
    withCredentials: false,
  });
}

function getGDPRUrl() {
  const { configuration } = store.getState();

  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_GDPR_SERVICE_HOSTNAME
  ) {
    if (
      configuration.gdprServiceHostname !==
      process.env.REACT_APP_GDPR_SERVICE_HOSTNAME
    ) {
      console.warn(`Warning - GDPR service url mismatch:
        process.env.REACT_APP_GDPR_SERVICE_HOSTNAME=${process.env.REACT_APP_GDPR_SERVICE_HOSTNAME}
        configuration.gdprServiceHostname=${configuration.gdprServiceHostname}
      This means that developers cannot test GDPR service locally unless REACT_APP_GDPR_SERVICE_HOSTNAME variable is set to the value received from "/config"`);

      return configuration.gdprServiceHostname;
    }
    return "";
  }
  return configuration.gdprServiceHostname;
}
