import { Component } from "react";
import DaonSpinner from "Components/common/DaonSpinner";
import { connect } from "react-redux";
import { func } from "prop-types";
import {
  newDocumentSelected,
  selectDocumentType,
  setDocument,
  setDocumentAspectRatio,
  setDocumentLabel,
  setDocuments,
  setIsNFCReady,
  setSkipDocumentSelection,
} from "logic/actions/documentCapture";
import { go } from "logic/actions/navigation";
import "./style.css";
import { DOCUMENT_COUNTRY_SELECTOR } from "logic/enums/pages";
import { withTranslation } from "react-i18next";
import PageContent from "Components/PageContent";
import countries from "i18n-iso-countries";
import {
  documentSidesSelector,
  isSinglePaged,
} from "logic/actions/documentCaptureConfiguration";
import { withTheme } from "styled-components";

import * as S from "./PageVerifyIdentity.styles";
import {
  PASSPORT,
  DRIVERS_LICENCE,
  ID_CARD,
  RESIDENCE_CARD,
  OTHER_DOCUMENT,
} from "logic/enums/document";
import DaonErrorBox from "Components/common/DaonErrorBox";
import { DOCUMENT_CAPTURE_INSTRUCTIONS } from "logic/enums/pages";
import { getCountriesData } from "logic/api/countries-documents";
import { ReactComponent as Passport } from "../../../../assets/icons/16-24/Passport.svg";
import { ReactComponent as DriversLicence } from "../../../../assets/icons/16-24/Drivers-license.svg";
import { ReactComponent as IDCard } from "../../../../assets/icons/16-24/ID-card.svg";
import { ReactComponent as ChevronR } from "../../../../assets/icons/16-24/Chevron-r.svg";
import { countrySelected } from "logic/actions/documentCaptureConfiguration";

//TODO@gva: mark for refactor to hooks (componentWillMount is deprecated)
export class PageVerifyIdentity extends Component {
  static propTypes = {
    selectDocumentType: func,
    go: func,
  };

  constructor(props) {
    super(props);
    this.go.bind(this);
    this.goToDocument.bind(this);
    this.state = {
      pending: true,
      documents: [
        {
          id: "passport_item",
          type: PASSPORT,
          icon: <Passport fill={this.props.theme.textPrimary} />,
          label: "PageVerifyIdentity.passport",
          nfcReady: true,
        },
        {
          id: "drivers_licence_item",
          type: DRIVERS_LICENCE,
          icon: <DriversLicence fill={this.props.theme.textPrimary} />,
          label: "PageVerifyIdentity.drivers_licence",
          nfcReady: false,
        },
        {
          id: "identity_card_item",
          type: ID_CARD,
          icon: <IDCard fill={this.props.theme.textPrimary} />,
          label: "PageVerifyIdentity.id",
          nfcReady: false,
        },
        {
          id: "residence_card_item",
          type: RESIDENCE_CARD,
          icon: <IDCard fill={this.props.theme.textPrimary} />,
          label: "PageVerifyIdentity.residence_card",
          nfcReady: false,
          hide: true,
        },
      ],
      documentCountry: this.props.dialCountryNum,
      showChangeCountry: true,
      locale: this.props.i18n.language.substr(0, 2),
    };
  }

  componentDidMount() {
    document.title = `${this.props.t(
      "PageDocumentCapture.title"
    )} | Onboarding`;
    this.loadIsoCountries(this.state.locale);
    this.props.i18n.on("languageChanged", (lng) => {
      this.loadIsoCountries(this.props.i18n.language);
    });
    this.setDocumentCountry(this.state.documentCountry);
  }

  loadIsoCountries = (locale) => {
    try {
      countries.registerLocale(
        require(`i18n-iso-countries/langs/${locale.substr(0, 2)}.json`)
      );
      this.setState({ locale: locale.substr(0, 2) });
    } catch (e) {
      countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));
      this.setState({ locale: "en" });
    }
  };

  setDocumentCountry = (documentCountry) => {
    const allowedCountries =
      this.props.configuration?.extraConfig?.steps?.document?.options
        ?.allowedCountries;
    const dialCountryNum = this.props.dialCountryNum;
    const defaultCountry =
      this.props.configuration?.extraConfig?.steps?.document?.options
        ?.defaultCountry;
    if (
      ((allowedCountries?.length > 0 &&
        !allowedCountries.includes(dialCountryNum)) ||
        !dialCountryNum) &&
        ((this.props.configuration?.extraConfig?.featureFlags?.enableDefaultCountry) ? true : (!this.props.documentValidationEnabled || allowedCountries?.length === 1))
      
    ) {
      if (defaultCountry) {
        documentCountry = defaultCountry;
      } else {
        documentCountry =
          allowedCountries?.length > 0 ? allowedCountries[0] : "ie";
      }
    }
    this.setState({ documentCountry });
    this.props.countrySelected(documentCountry);
  };

  getDocumentCountries = (defaultCountries, countriesData) => {
    if (!countriesData) return defaultCountries;
    if (!this.state.documentCountry)
      return { ...defaultCountries, ...countriesData };
    if (defaultCountries[this.state.documentCountry]) {
      if (!countriesData[this.state.documentCountry]) return defaultCountries;
      defaultCountries[this.state.documentCountry].forEach(
        (defaultCountryDocument) => {
          if (
            defaultCountryDocument?.type &&
            !countriesData[this.state.documentCountry].find(
              (document) => document.type === defaultCountryDocument.type
            )
          ) {
            countriesData[this.state.documentCountry].push(
              defaultCountryDocument
            );
          }
        }
      );
    }
    return countriesData;
  };
  documentsHandler = (countriesData, countries) => {
    countriesData = this.getDocumentCountries(
      this.props.documents || countries,
      countriesData
    );
    let documents = this.state.documents;

    const documentSides = { id: 2, dl: 2, rc: 2 };
    let hasOtherDocument = false;
    if (
      this.state.documentCountry &&
      countriesData[this.state.documentCountry]
    ) {
      countriesData[this.state.documentCountry].forEach((document) => {
        if (document.aspectRatio || document.barcodeSide || document.label) {
          documents.forEach((singleDocument) => {
            if (
              singleDocument.type === document.type &&
              singleDocument.type !== OTHER_DOCUMENT
            ) {
              if (document.type === RESIDENCE_CARD && document.sides > 0) {
                singleDocument.hide = false;
              }
              singleDocument.label = document.label || singleDocument.label;
              singleDocument.spectRatio = document.aspectRatio;
              singleDocument.nfcReady = document.nfcReady;
              singleDocument.barcodeSide = document.barcodeSide;
              singleDocument.documentPolicy = document.documentPolicy;
              singleDocument.order = document.order;
            }
          });
        }

        if (document.type === OTHER_DOCUMENT && document.sides > 0) {
          documentSides[document.label || "PageVerifyIdentity.other"] =
            document.sides;
          let documentPresent = this.state.documents.findIndex(
            (stateDocument) => document.label === stateDocument.label
          );
          if (documentPresent < 0) {
            documents.push({
              ...document,
              label: document.label || "PageVerifyIdentity.other",
              icon: <IDCard fill={this.props.theme.textPrimary} />,
            });
          }
          hasOtherDocument = true;
        }

        if (document.type === ID_CARD) {
          documentSides.id = document.sides;
        }
        if (document.type === DRIVERS_LICENCE) {
          documentSides.dl = document.sides;
        }
        if (document.type === RESIDENCE_CARD) {
          documentSides.rc = document.sides;
        }
        if (document.sides < 1) {
          let filteredDocuments = documents.filter(
            (doc) => doc.type !== document.type
          );
          documents = [...filteredDocuments];
        }
      });
    }
    if (
      !hasOtherDocument &&
      !countriesData[this.state.documentCountry]?.length > 0
    ) {
      this.setState({
        documents: [
          ...this.state.documents,
          {
            id: "other_doc_item",
            type: OTHER_DOCUMENT,
            icon: <IDCard fill={this.props.theme.textPrimary} />,
            label: "PageVerifyIdentity.other",
            nfcReady: false,
          },
        ],
      });
    }
    this.setState({ documents });
    if(this.props.skipSingleDocumentSelection && countriesData && Object.keys(countriesData).length === 1 && documents.length === 1) {
      this.handleEvent(documents[0]);
    }
    this.props.setSkipDocumentSelection(this.props.skipSingleDocumentSelection && countriesData && Object.keys(countriesData).length === 1 && documents.length === 1);
    this.setState({ pending: false });
    this.props.documentSidesSelector(documentSides);
  };

  componentWillMount() {
    if (
      this.props.configuration?.extraConfig?.steps?.document?.options
        ?.allowedCountries?.length === 1
    ) {
      this.setState({ showChangeCountry: false });
    }
    //TODO: Think whether we should display some loader, while loading json isn't done
    let countriesData =
      this.props.configuration?.extraConfig?.steps?.document?.options
        ?.countries;

    countriesData =
      countriesData &&
      Object.fromEntries(
        Object.entries(countriesData).filter(
          ([key]) => countriesData[key]?.length > 0
        )
      );
    if (!this.props.documents)
      getCountriesData().then((countries) => {
        this.props.setDocuments(countries);
        this.documentsHandler(countriesData, countries);
      });
    else {
      this.documentsHandler(countriesData);
    }
  }

  goToDocument = (type) => {
    if (!this.state.documentCountry) {
      this.setState({ error: "PageVerifyIdentity.country_not_selected" });
      return;
    }
    if (type === "PASSPORT") this.props.isSinglePaged(true);
    this.go(type);
  };

  goToCountryPage = () => {
    this.props.go(DOCUMENT_COUNTRY_SELECTOR);
  };

  go = (type) => {
    this.props.selectDocumentType(type);
    this.props.newDocumentSelected();
    this.props.go(DOCUMENT_CAPTURE_INSTRUCTIONS);
  };

  handleEvent = (document) => {
    this.goToDocument(document.type);
    this.props.setIsNFCReady(document.nfcReady);
    this.props.setDocument(document);
    this.props.setDocumentLabel(document.label);
    this.props.setDocumentAspectRatio(document.aspectRatio);
  };

  render() {
    return (
      <PageContent
        title={this.props.t("PageDocumentCapture.title")}
        instructions={true}
        justifyContent={this.state.pending ? "center" : "initial"}
        showBack={false}
      >
        {this.state.pending ? (
          <>
            <h2>{this.props.t("Common.processing")}</h2>
            <DaonSpinner color={this.props.theme.spinner} />
          </>
        ) : (
          <S.Content>
            <S.H2>{this.props.t("PageVerifyIdentity.title")}</S.H2>
            {this.state.showChangeCountry && (
              <div>
                <S.DescPar>
                  {this.props.t("PageVerifyIdentity.select_country")}
                </S.DescPar>
                <S.PanelDiv
                  onClick={this.goToCountryPage}
                  id="change_country_link"
                  style={{ marginBottom: "35px" }}
                >
                  <S.IconDiv>
                    <Passport fill={this.props.theme.textPrimary} />
                  </S.IconDiv>
                  <S.PanelP>
                    {countries.getName(
                      this.state.documentCountry,
                      this.props.i18n.language.substr(0, 2)
                    ) || countries.getName(
                      this.state.documentCountry,
                      "en"
                    ) ||  this.props.t("PageVerifyIdentity.select_country")}
                  </S.PanelP>
                  <S.IconDiv>
                    <ChevronR fill={this.props.theme?.textSecondary} />
                  </S.IconDiv>
                </S.PanelDiv>
              </div>
            )}
            {this.state.error && (
              <DaonErrorBox
                style={{ maxWidth: "300px" }}
                whiteBackground
                message={this.props.t(this.state.error)}
              ></DaonErrorBox>
            )}
            <S.DescPar>
              {this.props.t("PageVerifyIdentity.description")}
            </S.DescPar>
            {this.state.documents
              .sort((a, b) => a.order - b.order)
              .filter((document) => document.hide !== true)
              .map((document,index) => (
                <S.PanelDiv
                tabIndex="0"
                  key={document.id || document.label}
                  data-type={document.type}
                  onClick={() => {
                    this.handleEvent(document);
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      this.handleEvent(document);
                    }
                  }}
                  id={document.id || 'otherDocument-' + index}
                >
                  <S.IconDiv>{document.icon}</S.IconDiv>
                  <S.PanelP>{this.props.t(document.label)}</S.PanelP>
                  <S.IconDiv>
                    <ChevronR fill={this.props.theme?.textSecondary} />
                  </S.IconDiv>
                </S.PanelDiv>
              ))}
            
          </S.Content>
        )}
      </PageContent>
    );
  }
}

const componentWithTheme = withTheme(PageVerifyIdentity);
const componentWithTranslation = withTranslation()(componentWithTheme);
export default connect(
  (state) => {
    const documentValidationEnabled =
      state.configuration.extraConfig.steps?.document?.options
        ?.documentValidationEnabled ?? false;
    const skipSingleDocumentSelection =
      state.configuration.extraConfig.steps?.document?.options
        ?.skipSingleDocumentSelection ?? false;

    return {
      ...state.configuration.extraConfig.featureFlags,
      configuration: state.configuration,
      ...state.documentCapture,
      ...state.documentCaptureConfiguration,
      documentValidationEnabled,
      skipSingleDocumentSelection
    };
  },
  {
    selectDocumentType,
    setIsNFCReady,
    go,
    documentSidesSelector,
    newDocumentSelected,
    setDocument,
    setDocumentAspectRatio,
    setDocumentLabel,
    isSinglePaged,
    countrySelected,
    setDocuments,
    setSkipDocumentSelection
  }
)(componentWithTranslation);
