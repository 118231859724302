import { ANDROID } from "./enums/composite";
import { store } from "./store";
/*global MobileFormHandler*/

export const webView = (method, message) => {
  const compositeStore = store.getState().composite;
  if (!compositeStore.mobileAppPlatform) return;
  if (compositeStore.mobileAppPlatform === ANDROID) {
    executeFunctionByName({
      functionName: method,
      context: MobileFormHandler,
      isIos: false,
      message,
    });
  } else {
    executeFunctionByName({
      functionName: method,
      context: window,
      isIos: true,
      message,
    });
  }

  function executeFunctionByName({ functionName, context, isIos, message }) {
    var namespaces = functionName.split(".");
    var func = namespaces.pop();
    for (var i = 0; i < namespaces.length; i++) {
      context = context[namespaces[i]];
    }

    return isIos
      ? context.webkit?.messageHandlers[func].postMessage({
          message,
        })
      : message
      ? context[func].call(context, message)
      : context[func].call(context);
  }
};
