import { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import {
  ANDROID,
  IOS,
  WEB_LOADED,
  NFC_IS_AVAILABLE,
  VERSION_BELLOW_MIN,
  VERSION_NOT_LATEST,
  VERSION_NOT_CHECKED,
} from "logic/enums/composite";
import { setMobilePlatform, SET_SDK_VERSION} from "logic/actions/composite";
import { NFC_NOT_SUPPORTED, NFC_WRONG_IOS_VERSION } from "logic/enums/pages";
import { switchingToMobileStepsHandler } from "logic/steps";
import { composite_flow, logEvent, web_flow } from "logic/eventLogger";
import {
  nfc_error,
  nfc_not_supported,
  nfc_wrong_ios_version,
} from "logic/eventLogger";
import { getVersionsData } from "logic/api/versions";
import { webView } from "logic/webView";
import { DAON_CALLBACK } from "logic/enums/composite";
/*global MobileFormHandler*/
let resolveNFCPromise;
let rejectNFCPromise;
let rejectWebLoadedPromise;
let resolveWebLoadedPromise;

let errorCounter = 0;

const HandleComposite = (props) => {
  const dispatch = useDispatch();
  window.checkNFCPromise = checkNFCPromise;
  window.onNFCNotSupported = onNFCNotSupported;
  window.onNFCSupported = onNFCSupported;
  window.onWebLoaded = onWebLoaded;

  useEffect(() => {
    if (typeof props.setIsHandleCompositeFinished === "function") {
      setPlatform();
    }
    return () => {
      window.checkNFCPromise = null;
      window.onNFCNotSupported = null;
      window.onNFCSupported = null;
      window.onWebLoaded = null;
      window.removeEventListener("webLoadedEvent", webLoadedHandler);
      window.removeEventListener("nfcSupportEvent", nfcSupportHandler);
    };
  }, []);

  const nfcSupportHandler = (e) => {
    try {
      let response = JSON.parse(e.detail.data);
      if (!response.isNfcSupported) {
        props.setErrorPage(NFC_NOT_SUPPORTED);
        logEvent(nfc_error, {
          error: nfc_not_supported,
          platform: IOS,
        });
      } else if (!response.isRightIosVersion) {
        props.setErrorPage(NFC_WRONG_IOS_VERSION);
        logEvent(nfc_error, {
          error: nfc_wrong_ios_version,
        });
      }
      props.setIsHandleCompositeFinished(true);
    } catch (e) {
      console.log(e);
    }
  };

  const webLoadedHandler = (e) => {
    try {
      sdkInitializationHandler(IOS);
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const checkNFCPromise = () => {
    return new Promise((resolve, reject) => {
      resolveNFCPromise = resolve;
      rejectNFCPromise = reject;
      webView(NFC_IS_AVAILABLE);
    });
  };

  const webLoadedPromise = () => {
    return new Promise((resolve, reject) => {
      resolveWebLoadedPromise = resolve;
      rejectWebLoadedPromise = reject;
      webView(WEB_LOADED);
    });
  };

  function onWebLoaded() {
    resolveWebLoadedPromise();
  }

  function onNFCSupported() {
    resolveNFCPromise();
  }
  function onNFCNotSupported() {
    rejectNFCPromise();
  }

  const getVersionsDataHandler = (sdkVersion, platform, compatibilityVersion) => {
    getVersionsData()
      .then((versionsObject) => {

        dispatch({ type: SET_SDK_VERSION, payload: sdkVersion });
        if (versionsObject) {
          console.log(versionsObject);
          if (sdkVersion < versionsObject[`${platform}_SDK_VERSION`].min) {
            webView(DAON_CALLBACK, VERSION_BELLOW_MIN);
          } else if (
            sdkVersion < versionsObject[`${platform}_SDK_VERSION`].latest
          ) {
            webView(DAON_CALLBACK, VERSION_NOT_LATEST);
          }
        }
        logEvent(composite_flow, { platform, sdkVersion, compatibilityVersion });
        if (platform === ANDROID) {
          webLoadedPromise()
            .then(() => {
              sdkInitializationHandler(ANDROID);
            })
            .catch((e) => {
              console.log(e);
              return;
            });
        } else {
          window.addEventListener("webLoadedEvent", webLoadedHandler);
          webView(WEB_LOADED);
        }
      })
      .catch((e) => {
        console.log(e);
        errorCounter++;
        if (errorCounter < 9) {
          getVersionsDataHandler(sdkVersion, platform);
        } else {
          webView(DAON_CALLBACK, VERSION_NOT_CHECKED);
        }
      });
  };

  const setPlatform = () => {
    const parameters = new URLSearchParams(window.location.search);
    const platform = parameters.get("platform");
    switchingToMobileStepsHandler(platform);
    const sdkVersion = parameters.get("sdkVersion");
    const compatibilityVersion = parameters.get("compatibilityVersion");
    setMobilePlatform(platform)(dispatch);
    if (!platform || !sdkVersion) {
      logEvent(web_flow);
      props.setIsHandleCompositeFinished(true);
    } else {
      getVersionsDataHandler(sdkVersion, platform, compatibilityVersion);
    }
  };
  const sdkInitializationHandler = (mobileAppPlatform) => {
    if (mobileAppPlatform === IOS) {
      window.addEventListener("nfcSupportEvent", nfcSupportHandler);
      webView(NFC_IS_AVAILABLE);
    }
    if (mobileAppPlatform === ANDROID) {
      checkNFCPromise()
        .then(() => {
          props.setIsHandleCompositeFinished(true);
        })
        .catch((e) => {
          logEvent(nfc_error, {
            error: nfc_not_supported,
            platform: ANDROID,
          });
          console.log(e);
          props.setErrorPage(NFC_NOT_SUPPORTED);
          props.setIsHandleCompositeFinished(true);
        });
    }
  };
  return null;
};

export default connect((state) => state)(HandleComposite, setMobilePlatform);
