import { VALIDATE_PHONE } from "logic/enums/pages";
import {
  HIDE_SWITCH_TO_MOBILE_CHECKER,
  REDIRECT_FROM,
} from "../actions/phoneValidation";

export function phoneValidation(
  state = {
    switchToMobileCheckerHidden: false,
    redirectedFrom: VALIDATE_PHONE,
  },
  action
) {
  switch (action.type) {
    case HIDE_SWITCH_TO_MOBILE_CHECKER:
      return {
        ...state,
        switchToMobileCheckerHidden: action.payload,
      };
    case REDIRECT_FROM:
      return {
        ...state,
        redirectedFrom: action.payload,
      };
    default:
      return state;
  }
}
