import { SET_GYROSCOPE } from "../actions/gyroscope";

export function gyroscope(
  state = {
    isGyroscopeActive: true,
  },
  action
) {
  switch (action.type) {
    case SET_GYROSCOPE:
      return {
        ...state,
        isGyroscopeActive: action.payload,
      };
    default:
      return state;
  }
}
