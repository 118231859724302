import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APPROVED, DECLINED, REVIEW } from "logic/enums/pageStatus";
import DebugLinks from "Components/Debug/DebugLinks";
import PageStatus from "Components/PageStatus";
import PageContinueOnDesktop from "Components/PageContinueOnDesktop";
import PageDocumentCaptureConfirm from "Components/modules/DocumentCapture/PageDocumentCaptureConfirm";
import PageDocumentCaptureInstructions from "Components/modules/DocumentCapture/PageDocumentCaptureInstructions";
import PageDocumentCapturePortrait from "Components/modules/DocumentCapture/PageDocumentCapturePortrait";
import PageDocumentCountrySelector from "Components/modules/DocumentCapture/PageDocumentCountrySelector";
import PageFaceCapture from "Components/modules/FaceCapture/PageFaceCapture";
import PageFaceCaptureConfirm from "Components/modules/FaceCapture/PageFaceCaptureConfirm";
import PageFaceCaptureInstructions from "Components/modules/FaceCapture/PageFaceCaptureInstructions";
import PageNFCScan from "Components/modules/NFC/PageNFCScan";
import PageGDPR from "Components/PageGDPR";
import PageIntroScreen from "Components/PageIntroScreen";
import PageSentToMobile from "Components/modules/ValidatePhone/PageSentToMobile";
import PageSessionExpired from "Components/PageSessionExpired";
import PageThankYou from "Components/PageThankYou";
import PageValidatePhone from "Components/modules/ValidatePhone/PageValidatePhone";
import PageVerificationCode from "Components/modules/ValidatePhone/PageVerificationCode";
import PagePhoneStatus from "Components/modules/ValidatePhone/PagePhoneStatus";
import PageVerifyIdentity from "Components/modules/DocumentCapture/PageVerifyIdentity";
import PageQRInstructions from "Components/modules/ValidateQR/PageQRInstructions";
import PageQRScan from "Components/modules/ValidateQR/PageQRScan";
import PageCreateChallenge from "Components/modules/ValidatePhone/PageCreateChallenge";
import PageDocumentCaptureEditDocument from "Components/modules/DocumentCapture/PageDocumentCaptureEditDocument"

import { logEvent, page_open } from "logic/eventLogger";
import { CACHE_NAVIGATION, writeToLocalCache } from "logic/localCacheAdapter";
import {
  FACE_CAPTURE_INSTRUCTIONS,
  QR_INSTRUCTIONS,
  VALIDATE_PHONE,
  VERIFY_IDENTITY,
} from "logic/enums/pages";
import PageNFCInstructions from "Components/modules/NFC/PageNFCInstructions";
import PageNFCStatus from "Components/modules/NFC/PageNFCStatus";
import {
  NFC_STATUS_DISABLED,
  NFC_STATUS_FAILED,
  NFC_STATUS_INTERRUPTED,
  NFC_STATUS_NOT_SUPPORTED,
  NFC_STATUS_TIMEOUT,
  NFC_STATUS_WRONG_IOS_VERSION,
} from "logic/enums/nfc";
import { getDebugLinksData } from "logic/api/debugLinks";
import { DEBUG_LINKS_POSSIBILITY } from "logic/enums/debugLinks";

const pages = {
  PageApproved: <PageStatus status={APPROVED} />,
  PageDeclined: <PageStatus status={DECLINED} />,
  PageReview: <PageStatus status={REVIEW} />,
  PageContinueOnDesktop: <PageContinueOnDesktop />,
  PageDocumentCaptureConfirm: <PageDocumentCaptureConfirm />,
  PageDocumentCaptureInstructions: <PageDocumentCaptureInstructions />,
  PageDocumentCapturePortrait: <PageDocumentCapturePortrait />,
  PageDocumentCountrySelector: <PageDocumentCountrySelector />,
  PageFaceCapture: <PageFaceCapture />,
  PageFaceCaptureConfirm: <PageFaceCaptureConfirm />,
  PageFaceCaptureInstructions: <PageFaceCaptureInstructions />,
  PageGDPR: <PageGDPR />,
  PageIntroScreen: <PageIntroScreen />,
  PageSentToMobile: <PageSentToMobile />,
  PageSessionExpired: <PageSessionExpired />,
  PageThankYou: <PageThankYou />,
  PageValidatePhone: <PageValidatePhone />,
  PageVerificationCode: <PageVerificationCode />,
  PagePhoneStatus: <PagePhoneStatus />,
  PageVerifyIdentity: <PageVerifyIdentity />,
  PageQRInstructions: <PageQRInstructions />,
  PageNFCInstructions: <PageNFCInstructions />,
  PageQRScan: <PageQRScan />,
  PageCreateChallenge: <PageCreateChallenge />,
  PageNFCScan: <PageNFCScan />,
  PageNFCDisabled: <PageNFCStatus type={NFC_STATUS_DISABLED} />,
  PageNFCNotSupported: <PageNFCStatus type={NFC_STATUS_NOT_SUPPORTED} />,
  PageNFCTimeout: <PageNFCStatus type={NFC_STATUS_TIMEOUT} />,
  PageNFCFailed: <PageNFCStatus type={NFC_STATUS_FAILED} />,
  PageNFCInterrupted: <PageNFCStatus type={NFC_STATUS_INTERRUPTED} />,
  PageNFCWrongIosVersion: <PageNFCStatus type={NFC_STATUS_WRONG_IOS_VERSION} />,
  PageDocumentCaptureEditDocument: <PageDocumentCaptureEditDocument/>
};

export default function Navigation() {
  const page = useSelector((state) => state.navigation.page);
  const featureFlags = useSelector(
    (state) => state.configuration.extraConfig.featureFlags
  );

  const [debugLinksPossibility, setDebugLinksPossibility] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === "production")
      getDebugLinksData()
        .then((debugLinksData) => {
          setDebugLinksPossibility(debugLinksData[DEBUG_LINKS_POSSIBILITY]);
        })
        .catch(() => {});
  }, []);

  useEffect(() => {
    if (window) window.scrollTo(0, 0);
    if (
      [
        QR_INSTRUCTIONS,
        VALIDATE_PHONE,
        FACE_CAPTURE_INSTRUCTIONS,
        VERIFY_IDENTITY,
      ].includes(page)
    ) {
      writeToLocalCache({ page }, CACHE_NAVIGATION);
    }
  }, [page]);

  const isDebugLinksActive =
    process.env.REACT_APP_DEBUG_LINKS === "true" ||
    process.env.REACT_APP_DEBUG_LINKS === true ||
    (debugLinksPossibility &&
      (featureFlags.debugLinks === true || featureFlags.debugLinks === "true"));

  if (page) {
    logEvent(page_open, { name: page, pageName: page });
  }
  return (
    <React.Fragment>
      {pages[page]}
      {isDebugLinksActive && (
        <DebugLinks
          pages={{
            PageApproved: <PageStatus status={APPROVED} />,
            PageDeclined: <PageStatus status={DECLINED} />,
            PageReview: <PageStatus status={REVIEW} />,
            PageContinueOnDesktop: <PageContinueOnDesktop />,
            PageDocumentCountrySelector: <PageDocumentCountrySelector />,
            PageFaceCaptureInstructions: <PageFaceCaptureInstructions />,
            PageFaceCapture: <PageFaceCapture />,
            PageGDPR: <PageGDPR />,
            PageIntroScreen: <PageIntroScreen />,
            PageValidatePhone: <PageValidatePhone />,
            PageVerifyIdentity: <PageVerifyIdentity />,
            PageSessionExpired: <PageSessionExpired />,
            PageThankYou: <PageThankYou />,
            PageQRInstructions: <PageQRInstructions />,
            PageNFCInstructions: <PageNFCInstructions />,
            PageCreateChallenge: <PageCreateChallenge />,
            PageNFCScan: <PageNFCScan />,
            PageNFCDisabled: <PageNFCStatus type={NFC_STATUS_DISABLED} />,
            PageNFCFailed: <PageNFCStatus type={NFC_STATUS_FAILED} />,
            PageNFCNotSupported: (
              <PageNFCStatus type={NFC_STATUS_NOT_SUPPORTED} />
            ),
            PageNFCInterrupted: <PageNFCStatus type={NFC_STATUS_INTERRUPTED} />,
            PageNFCWrongIosVersion: (
              <PageNFCStatus type={NFC_STATUS_WRONG_IOS_VERSION} />
            ),
          }}
        />
      )}
    </React.Fragment>
  );
}
