import { store } from "../../store";
import { SET_TEST_DATA, ERROR_MESSAGE, setVideoId } from "../livenessTest";
import {
  CACHE_LIVENESS_TEST,
  CACHE_NFC_TEST,
  CACHE_SESSION_DATA,
  writeToLocalCache,
} from "../../localCacheAdapter";
import { SET_NFC_CHALLENGE } from "../composite";
import { axiosInstance } from "logic/api";
import { getTenantNameFromHost } from "logic/tenant";

/**
 * POST /{tenant}/DigitalOnBoardingServices/rest/v1/users/{userId}/idchecks/{idCheckId}/challenges?livenessPolicyName={livenessPolicyName}
 */
export const challengeCreate = () => (dispatch) => {
  const { application, configuration } = store.getState();
  let promise = axiosInstance.post(
    `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/challenges?livenessPolicyName=${configuration.livenessPolicyName}`
  );

  return promise.then((response) => {
    dispatch({ type: SET_TEST_DATA, payload: response.data });
    try {
      const sessionData = {
        tenant: getTenantNameFromHost(),
        timestamp: new Date().getTime(),
      };

      writeToLocalCache(sessionData, CACHE_SESSION_DATA);
      writeToLocalCache(response.data, CACHE_LIVENESS_TEST);
    } catch (e) {
      console.log(e);
    }

    return response;
  });
};

export const challengeCreateNFC = () => (dispatch) => {
  const { application } = store.getState();
  let promise = axiosInstance.post(
    `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/nfcChallenges`
  );
  return promise.then((response) => {
    dispatch({ type: SET_NFC_CHALLENGE, payload: response.data });
    return response;
  });
};
/**
 * POST /{tenant}/DigitalOnBoardingServices/rest/v1/users/{userId}/idchecks/{idCheckId}/videos/?{videoId | ""}
 */
export const challengeTemplateUpload = (base64Template) => (dispatch) => {
  dispatch({ type: UPDATE_MESSAGE, payload: "" });
  const { application, livenessTest, configuration } = store.getState();
  let promise = null;
  if (
    livenessTest.livenessTestData.items[0].type === "SVR3DFL" &&
    configuration.extraConfig.featureFlags &&
    configuration.extraConfig.featureFlags.new3dfl === "true" &&
    configuration.extraConfig.featureFlags.selfie3dfl === "true"
  ) {
    const request = {
      type: "Face",
      format: "Liveness3D",
      context: {},
      data: {
        value: base64Template,
      },
    };
    promise = axiosInstance.post(
      `/IdentityXServices/rest/v1/users/${application.user.id}/samples`,
      request
    );
  } else {
    const subtype =
      livenessTest.livenessTestData.items[0].type === "SVR3DFL"
        ? "SVR3DFL_CHALLENGE"
        : "WEB_CLR";
    const request = {
      subtype: subtype,
      captured: new Date().toISOString(),
      videoFormat: livenessTest.livenessTestData.items[0].type,
      challenges: [
        {
          challenge: {
            id: livenessTest.livenessTestData.items[0].id,
            type: livenessTest.livenessTestData.items[0].type,
          },
          start: 0,
          completed: 99999,
        },
      ],
      sensitiveData: {
        format: livenessTest.livenessTestData.items[0].type,
        value: base64Template,
      },
    };
    promise = axiosInstance.post(
      `/DigitalOnBoardingServices/rest/v1/users/${
        application.user.id
      }/idchecks/${application.user.idCheck.id}/videos/${
        livenessTest.videoId || ""
      }`,
      request
    );
  }

  return promise.then((response) => {
    if (response.data.processingStatus === "FAILED") {
      dispatch({
        type: ERROR_MESSAGE,
        payload: response.data.processingErrors.items[0].message,
      });
    }

    if (response.data.id) {
      dispatch(setVideoId(response.data.id));
    }
    return response;
  });
};
