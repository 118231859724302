import styled from 'styled-components/macro'

const sizeSpinner = (type) => {
    switch (type) {
      case "small":
        return "width: 60px; height: 60px";
        case "big":
      default:
        return "width: 76px; height: 76px";
    }
  };

const sizeDiv = (type) => {
        switch (type) {
          case "small":
            return "width: 46px; height: 46px";
            case "big":
          default:
            return "width: 62px; height: 62px";
        
      };
}





export const DaonSpinner = styled.div`
${({ type }) => sizeSpinner(type)};

background:${props => props.darkBackground ? '#020202' : 'white'};   
border-radius:50%;

div {

  ${({ type }) => sizeDiv(type)};


border-radius: 50%;
margin:7px;
border: 8px solid;
border-color: #8e8e90;
border-right-color: #0099F0;
animation: spinner-d3wgkg 1.2s infinite linear;
}

}

@keyframes spinner-d3wgkg {
to {
   transform: rotate(1turn);
}

`;