export const phone_number = "phone_number";
export const qr_code = "qr_code";
export const sms_code = "sms_code";
export const nfc_scan = "nfc_scan";
export const document_capture = "document_capture";

let startTimes = {};

export function startEventTimer(stepName) {
  startTimes[stepName] = window.performance.now();
}

export function getTimePassed(stepName) {
  if (startTimes[stepName]) {
    return window.performance.now() - startTimes[stepName];
  }
  return undefined;
}
