import { useDispatch } from "react-redux";
import { useContext } from "react";
import { previous } from "logic/actions/navigation";
import "./ButtonBack.styles.jsx";
import {ReactComponent as LeftArrow} from "../../../assets/icons/16-24/Back.svg";
import { useTranslation } from "react-i18next";
import * as S from "./ButtonBack.styles";
import { ThemeContext } from "styled-components";

export default function ButtonBack({ onClick }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const back = () => {
    dispatch(previous());
  }
  const theme = useContext(ThemeContext);
  return (
    <S.BackButton onClick={onClick || back} id="go_back">
      <LeftArrow fill={theme?.textPrimary} />
      <span>{t("Common.back")}</span>
    </S.BackButton>
  );
}
