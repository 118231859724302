import styled from 'styled-components/macro'

export const Wrapper = styled.div`
    @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
        display: ${({ hideFooter }) => !hideFooter ? "flex" : "none"};
        left: 50%;
        transform: translateX(-50%);
        z-index: 6;
        position: absolute;
        padding: 0;
        bottom: 2px;
        height: 30px;
    }

    @media screen and (orientation: portrait) and (max-width: ${props => props.theme.smallPhoneBreakpoint + 'px'}) {
        bottom: -30px;
    }
`;