import {
  CREATE_INSTANCE,
  PREVIEW,
  RETRY,
  SET_IS_WASM_LOADED,
} from "../actions/faceCapture";

export function faceCapture(
  state = {
    retries: 0,
    isWasmLoaded: false,
  },
  action
) {
  switch (action.type) {
    case CREATE_INSTANCE:
      return {
        ...state,
        FaceCaptureInstance: action.payload,
      };
    case PREVIEW:
      return {
        ...state,
        ...action.payload,
      };
    case RETRY:
      return {
        ...state,
        blob: null,
        retries: state.retries + 1,
      };

    case SET_IS_WASM_LOADED:
      return { ...state, isWasmLoaded: action.payload };

    default:
      return state;
  }
}
