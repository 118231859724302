import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";

import { countrySelected } from "logic/actions/documentCaptureConfiguration";
import { go } from "logic/actions/navigation";
import {
  applicationUpdate,
  applicationUpdateParams,
  applicationQRValidate,
} from "logic/actions/api/application";
import { redirectFrom } from "logic/actions/phoneValidation";
import { isFirefox, isMobile, isOpera } from "logic/deviceType";
import { NONE, OPTIONAL, QR_SMS, SMS } from "logic/enums/verification";
import {
  logEvent,
  phone,
  step_completed,
  switching_to_mobile,
} from "logic/eventLogger";
import {
  PHONE_STATUS,
  SENT_TO_MOBILE,
  VALIDATE_PHONE,
  VERIFICATION_CODE,
} from "logic/enums/pages";
import { getTimePassed, phone_number, startEventTimer } from "logic/stepTimers";

import DaonCheckbox from "Components/common/DaonCheckbox";
import DaonButton from "Components/common/DaonButton";
import DaonErrorBox from "Components/common/DaonErrorBox";
import PhoneInput from "react-phone-input-2";
import PageContent from "Components/PageContent";
import * as S from "./PageValidatePhone.styles";
import { withTheme } from "styled-components";
import resolutionCheckMethod from "logic/resolutionCheck";
import { Modal } from "react-bootstrap";
import CountrySelector from "Components/common/CountrySelector";
import { setValidatePhoneSessionDuration } from "logic/actions/application";
import { PAGE_SESSION_EXPIRED } from "logic/enums/pages";

const useLocalization = ({ path, onSuccess, onErr }) => {
  useEffect(() => {
    import(`react-phone-input-2/lang/${path}.json`)
      .then((data) => {
        onSuccess(data);
      })
      .catch((err) => {
        onErr(err);
      });
  }, []);
};

export function PageValidatePhone(props) {
  const switchToMobile =
    props?.configuration?.extraConfig?.steps?.phoneNumber?.options
      ?.switchToMobile || OPTIONAL;
  const mobileVerificationType =
    props?.configuration?.extraConfig?.steps?.phoneNumber?.options
      ?.mobileVerificationType;

  const recaptchaSiteKey = props.configuration.extraConfig.googleRecaptchaV2;
  document.title = `${props.t("PageValidatePhone.header")} | Onboarding`;
  const { t } = useTranslation();

  // State
  const [state, setState] = useState({
    recaptchaSuccess: !recaptchaSiteKey,
    pending: false,
    shouldProceedOnMobile:
      switchToMobile !== NONE && !isMobile(navigator.userAgent),
    isMobile: isMobile(navigator.userAgent),
    currentLanguage: props.i18n?.language,
    localization: {},
    country: "us",
    primaryPhone: "",
    resolutionTest: true,
    loadingText: "",
    countrySelectorPopup: false,
  });

  // Hooks
  useLocalization({
    path: props.i18n.language.substr(0, 2),
    onSuccess: (data) =>
      setState((prevState) => ({ ...prevState, localization: data.default })),
    onErr: (err) => {},
  });

  useEffect(() => {
    //check device resolution quality on desktop devices
    if (!state.isMobile && props.rejectLowResolutionDevices) {
      checkDeviceResolution();
    }

    startEventTimer(phone_number);
    setCountry();
    document.addEventListener("keydown", onKeyDown);

    return function cleanup() {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  //Refs
  const recaptchaRef = useRef(null);

  // Methods
  const checkDeviceResolution = () => {
    let width = props.docCaptureWidth ? parseInt(props.docCaptureWidth) : 1920;
    let height = props.docCaptureHeigth
      ? parseInt(props.docCaptureHeigth)
      : 1080;

    setState((prevState) => ({
      ...prevState,
      pending: true,
      loadingText: t("PageValidatePhone.resolution_check"),
    }));
    resolutionCheckMethod(width, height)
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          resolutionTest: false,
          shouldProceedOnMobile: true,
        }));
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          loadingText: "",
          pending: false,
        }));
      });
  };

  const toggleSendToMobile = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      shouldProceedOnMobile: e.target.checked,
    }));
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!state.recaptchaSuccess || !state.isPhoneValid) {
      return;
    }
    const t = props.t;
    const primaryPhone = state.primaryPhone;
    props.configuration.extraConfig.steps?.document?.options?.defaultCountry
      ? props.countrySelected(
          props.configuration.extraConfig.steps.document.options.defaultCountry
        )
      : props.countrySelected(state.country);

    if (primaryPhone) {
      const phoneNumber = primaryPhone
        .replace(/\s/g, "")
        .replace(/[{()}]|-/g, "");
      setState((prevState) => ({ ...prevState, pending: true }));

      try {
        await props.applicationUpdateParams();
        let response = await props.applicationUpdate(
          SMS,
          phoneNumber,
          state.shouldProceedOnMobile
        );
        const timePassed = getTimePassed(phone_number);
        logEvent(step_completed, {
          stepNameCompleted: phone,
          timePassed,
        });
        if (state.shouldProceedOnMobile && switchToMobile !== NONE) {
          logEvent(switching_to_mobile, {
            type: SMS,
          });
          props.redirectFrom(VALIDATE_PHONE);
          props.go(PHONE_STATUS);
        } else {
          props.setValidatePhoneSessionDuration(
            response?.data?.challengeExpirySec
          );
          props.redirectFrom();
          props.go(VERIFICATION_CODE);
        }
      } catch (err) {
        catchError(err);
      }
    } else {
      setState({ error: t("PageValidatePhone.error_invalid") });
    }
  };

  const catchError = (err) => {
    if (err?.response?.status === 401 || err?.response?.status === 403)
      props.go(PAGE_SESSION_EXPIRED);
    else {
      let errMessage =
        typeof err.response !== "undefined" && err.response.data.message
          ? err.response.data.message
          : props.t("Common.error");
      setState((prevState) => ({
        ...prevState,
        error: errMessage,
        pending: false,
      }));
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter" && state.isPhoneValid && state.recaptchaSuccess) {
      submit(e);
    }
  };

  const checkWithoutCountryCode = (primaryPhone, dialCode) => {
    if (dialCode) {
      const countryLength = dialCode.length;
      const phoneWithoutDialNumber = primaryPhone.slice(countryLength);
      setState((prevState) => ({
        ...prevState,
        isPhoneValid: phoneWithoutDialNumber.length > 7,
      }));
    }
  };

  const setCountry = () => {
    let extraConfig = props.configuration.extraConfig;

    if (extraConfig) {
      setState((prevState) => ({
        ...prevState,
        country:
          extraConfig.steps?.phoneNumber?.options.defaultCountry ||
          extraConfig.featureFlags.phoneNumberDefault,
      }));
    }
  };

  const onRecaptchaChange = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    setState((prevState) => ({
      ...prevState,
      recaptchaSuccess: recaptchaValue !== null,
    }));
  };
  const setSelectedCountry = (countryCode) => {
    setState((prevState) => ({
      ...prevState,
      country: countryCode,
      countrySelectorPopup: false,
    }));
  };

  const openCountrySelector = (e) => {
    let isPhoneInputClicked = e.target.className.includes("form-control");

    if (!isPhoneInputClicked && state.isMobile) {
      setState((prevState) => ({
        ...prevState,
        countrySelectorPopup: true,
      }));
    }
  };

  return (
    <PageContent
      toggleLoading={state.pending}
      showBack={false}
      title={t("PageValidatePhone.mobile")}
      instructions={true}
      loadingText={state.loadingText}
    >
      {
        <S.Content>
          <div>
            <h2>{t("PageValidatePhone.title")}</h2>
            <S.Paragraph>
              {t("PageValidatePhone.description_first")}
              <br></br>
              {t("PageValidatePhone.description_second")}
            </S.Paragraph>
            {
              <S.Form
                onSubmit={submit}
                id="phone-form"
                switchToMobileCheckerHidden={
                  mobileVerificationType === QR_SMS ||
                  switchToMobile !== OPTIONAL ||
                  isFirefox(navigator.userAgent) ||
                  isOpera(navigator.userAgent)
                }
              >
                {state.localization && (
                  <div onClick={openCountrySelector}>
                    <PhoneInput
                      inputProps={{ autoComplete: "tel" }}
                      country={state.country}
                      value={state.primaryPhone}
                      placeholder=""
                      onChange={(primaryPhone, e) => {
                        setState((prevState) => ({
                          ...prevState,
                          primaryPhone,
                          country: e.countryCode,
                        }));
                        checkWithoutCountryCode(primaryPhone, e.dialCode);
                      }}
                      localization={state.localization}
                      enableSearch={true}
                      disableSearchIcon={true}
                      disableDropdown={state.isMobile}
                      inputStyle={{
                        width: "395px",
                        height: "58px",
                        borderRadius: "8px",
                        border: `2px solid #DDE1E6`,
                        paddingLeft: "46px",
                        paddingTop: "18px",
                        fontSize: "20px",
                        color: props.theme && props.theme.textPrimary,
                      }}
                      specialLabel={t("PageValidatePhone.mobile")}
                      id="phone_number"
                    />
                  </div>
                )}

                {/* Show popup for select country on mobiles */}
                <Modal
                  show={state.countrySelectorPopup}
                  onHide={() =>
                    setState((prevState) => ({
                      ...prevState,
                      countrySelectorPopup: false,
                    }))
                  }
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t("Common.chooseCountry")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <CountrySelector
                      height="80%"
                      countrySelected={setSelectedCountry}
                    />
                  </Modal.Body>
                </Modal>

                <S.Recaptcha margin="0.3rem">
                  {recaptchaSiteKey && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={recaptchaSiteKey}
                      onChange={onRecaptchaChange}
                      hl={state.currentLanguage}
                    />
                  )}
                </S.Recaptcha>
                {!state.isMobile &&
                  switchToMobile === OPTIONAL &&
                  mobileVerificationType !== QR_SMS &&
                  !isFirefox(navigator.userAgent) &&
                  !isOpera(navigator.userAgent) &&
                  (state.resolutionTest ? (
                    <S.SendToMobile>
                      <S.CheckWrapper>
                        <DaonCheckbox
                          checked={state.shouldProceedOnMobile}
                          onChange={toggleSendToMobile}
                        />
                        <S.Paragraph>
                          {t("PageValidatePhone.continue_mobile")}
                        </S.Paragraph>
                      </S.CheckWrapper>
                    </S.SendToMobile>
                  ) : (
                    <div>
                      <S.Paragraph>
                        {t("PageValidatePhone.low_resolution_info")}
                      </S.Paragraph>
                    </div>
                  ))}
              </S.Form>
            }
            {state.error ? (
              <DaonErrorBox
                whiteBackground
                message={state.error}
              ></DaonErrorBox>
            ) : null}
          </div>
          {(props.phoneValidation.redirectedFrom === SENT_TO_MOBILE ||
            props.phoneValidation.redirectedFrom === VERIFICATION_CODE) && (
            <DaonErrorBox
              whiteBackground={true}
              message={t("PageValidatePhone.expirationMessage")}
            ></DaonErrorBox>
          )}
          <DaonButton
            type="submit"
            form="phone-form"
            id="verify"
            disabled={!state.isPhoneValid || !state.recaptchaSuccess}
          >
            {t("PageValidatePhone.verify")}
          </DaonButton>
        </S.Content>
      }
    </PageContent>
  );
}

const componentWithTheme = withTheme(PageValidatePhone);
const componentWithTranslation = withTranslation()(componentWithTheme);
export default connect(
  (state) => ({
    ...state,
    docCaptureWidth:
      state.configuration.extraConfig?.steps?.document?.options?.width ||
      state.configuration.extraConfig?.featureFlags.width,
    docCaptureHeigth:
      state.configuration.extraConfig?.steps?.document?.options?.height ||
      state.configuration.extraConfig?.featureFlags.heigth,
    rejectLowResolutionDevices:
      !!state.configuration.extraConfig?.steps?.document?.options
        ?.rejectLowResolutionDevices,
  }),
  {
    countrySelected,
    applicationUpdate,
    applicationUpdateParams,
    applicationQRValidate,
    go,
    redirectFrom,
    setValidatePhoneSessionDuration,
  }
)(componentWithTranslation);
