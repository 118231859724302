import {connect} from "react-redux";
import {nextPage, nextStep, go} from "logic/actions/navigation";
import {useTranslation} from "react-i18next";
import {
    documentsSave,
    processResponseErrors,
} from "logic/actions/api/document";
import {auDocumentsSave} from "logic/actions/api/auDocuments";
import {DRIVERS_LICENCE, PASSPORT} from "logic/enums/document";
import {
    DOCUMENT_CAPTURE_EDIT_DOCUMENT,
    DOCUMENT_CAPTURE_INSTRUCTIONS,
    THANK_YOU,
} from "logic/enums/pages";
import {
    setDocumentId,
    increaseRetryCounter,
} from "logic/actions/documentCapture";
import {useEffect, useState} from "react";
import {selectNFCRetriesCounter} from "logic/reducers/composite";
import {useSelector} from "react-redux";
import {steps} from "logic/steps";
import {NFC_STEP} from "logic/enums/steps";
import {acceptableDocument} from "logic/actions/api/document";
import DaonErrorBox from "Components/common/DaonErrorBox";
import {document_capture, nfc_scan} from "logic/stepTimers";
import {getTimePassed} from "logic/stepTimers";
import {document_processing_error, logEvent} from "logic/eventLogger";
import {step_completed} from "logic/eventLogger";
import {base64toBlob} from "logic/base64ToBlob";
import {BarcodeSideEnum} from "logic/enums/barcode";
import {CLIENT_ERROR, SERVER_ERROR} from "logic/enums/httpErrors";
//component was made because it will have to handle documents submit without nfc, as well as when nfc is present
export const SubmitDocument = (props) => {
    const [error, setError] = useState("");
    const [docCaptureInstance] = useState(
        new Daon.DocumentCapture({
            url: props.documentQualityHostname,
        })
    );
    let isNfcStepPresent = steps.findIndex((x) => x.name === NFC_STEP) > -1;
    const NFCRetries = useSelector(selectNFCRetriesCounter);
    const dialCountryNum = useSelector(
        (state) => state.documentCaptureConfiguration.dialCountryNum
    );
    const retries = useSelector((state) => state.documentCapture.retries);
    const retryCount = useSelector((state) => state.documentCapture.retryCount);
    const documentProcessingRetriesCounter = useSelector(
        (state) => state.documentCapture.documentProcessingRetriesCounter
    );

    const {t} = useTranslation();

    useEffect(() => submit(), []);

    const logCompletedStep = (idxDocumentId) => {
        const stepName = props.nfcData ? nfc_scan : document_capture;
        const timePassed = getTimePassed(stepName);
        logEvent(step_completed, {
            stepNameCompleted: stepName,
            idxDocumentId,
            documentCountry: dialCountryNum,
            timePassed,
            totalRetryCount: props.nfcData
                ? NFCRetries || 0
                : retries + retryCount + documentProcessingRetriesCounter || 0,
            documentType: props.documentType,
        });
    };

    const errorHandler = () => {
        props.errorHandler({
            pending: false,
        });
    };

    const submit = () => {
        if (
            props.documentType === PASSPORT ||
            props.documentsObject.base64CroppedSecondPage ||
            props.singlePaged === true
        ) {
            let documentsSaveArgs = {
                base64CroppedFirstPage: props.documentsObject.base64CroppedFirstPage,
                base64CroppedSecondPage: props.documentsObject.base64CroppedSecondPage,
                documentType: props.documentType,
                nfc: props.nfcData,
                country: dialCountryNum,
                accountApplicationId: props.applicationId,
            };
            const retriesLeft =
                props.documentProcessorRetries - props.processingRetriesCounter;
            if (!props.shouldNotSendUnprocessedDocuments || retriesLeft < 1) {
                documentsSaveArgs = {
                    ...documentsSaveArgs,
                    uncroppedFirstPage: props.documentsObject.base64UnprocessedFirstPage,
                    uncroppedSecondPage:
                    props.documentsObject.base64UnprocessedSecondPage,
                };
            }
            //deprecated

            // let bacrodeCheckerPromise = Promise.resolve(true);
            // let blob;
            // if (
            //   props.checkBarcode &&
            //   props.document.barcodeSide &&
            //   props.document.barcodeSide !== BarcodeSideEnum.NONE
            // ) {
            //   if (props.document.barcodeSide === BarcodeSideEnum.FRONT) {
            //     blob = base64toBlob(props.documentsObject.base64CroppedFirstPage);
            //   } else if (
            //     props.documentsObject.base64CroppedSecondPage &&
            //     props.document.barcodeSide === BarcodeSideEnum.BACK
            //   ) {
            //     blob = base64toBlob(props.documentsObject.base64CroppedSecondPage);
            //   }
            //   bacrodeCheckerPromise = docCaptureInstance.assessQualityBarcode(blob);
            // }
            // bacrodeCheckerPromise
            //   .then((response) => {
            //     if (response.result === "FAIL") {
            //       setError(response.feedback);
            //       errorHandler();
            //       return Promise.reject(false);
            //     }
            //     return props.documentsSave(documentsSaveArgs);
            //   })
            let promise = props.documentsSave;
            if (props.isAuDl && props.confirmOcrData) {
                promise = props.auDocumentsSave;
            }
            promise(documentsSaveArgs)
                .then((response) => {

                    const {data} = response;
                    const regulaDocumentChecks = data?.serverProcessed?.documentValidation?.checks?.
                        filter(item => item.name === 'REGULA_VERIFICATION_CHECK').
                        filter(item => item.resultStatus === "FAILED") || [];
                    const mandatoryRegulaChecks = [
                        "Date Of Expiry",
                        "Date Of Birth",
                        "Date Of Issue",
                        "Final Check Digit",
                        "Document Number Check Digit",
                        "Document Number",
                        "MRZ - Document Number Check Digit"
                    ];
                    if (regulaDocumentChecks.length > 0) {
                        //In case we have failed document check from Regula, simulate http error
                        let mandatoryRegulaCheck = false;
                        regulaDocumentChecks.forEach(item => {
                            if (mandatoryRegulaChecks.includes(item.description)) {
                                mandatoryRegulaCheck = true;
                            }
                        });
                        if (mandatoryRegulaCheck) {
                            const expiredDocument = regulaDocumentChecks.some(item => item.description === 'Date Of Expiry');

                            throw {
                                response: {
                                    data: {
                                        message: expiredDocument ? t('PageDocumentCaptureConfirm.regula_check_expired') : t('PageDocumentCaptureConfirm.regula_check_general')
                                    },
                                    status: 400,
                                }
                            }
                        }
                    }
                    props.setDocumentId(data?.id);
                    const processingErrorMessage = processResponseErrors(data, t);
                    if (processingErrorMessage || data?.processingStatus === "FAILED") {
                        if (
                            props.processingRetriesCounter >= props.documentProcessorRetries
                        ) {
                            props.nextStep();
                        } else {
                            logEvent(document_processing_error, {
                                errorMessage: data?.processingErrors?.items[0]?.message,
                                errorCode: data?.processingErrors?.items[0]?.code,
                                processingStatus: data?.processingStatus,
                                type: data?.type,
                            });
                            props.increaseRetryCounter();
                            setError(
                                processingErrorMessage
                                    ? processingErrorMessage
                                    : t("PageDocumentCaptureConfirm.error_reading_doc")
                            );
                            errorHandler();
                        }
                    } else {

                        if (props.isAuDl && props.confirmOcrData) {
                            props.go(DOCUMENT_CAPTURE_EDIT_DOCUMENT);
                            return
                        }

                        if (!props.documentValidationEnabled) {
                            const errorMessage = acceptableDocument(
                                response,
                                t,
                                props.documentType,
                                dialCountryNum,
                                props.nfcData
                            );
                            if (errorMessage) {
                                logEvent(document_processing_error, {
                                    errorMessage: errorMessage,
                                    processingStatus: data?.processingStatus,
                                    type: data?.type,
                                });
                                setError(errorMessage);
                                errorHandler();
                                return;
                            }
                        }
                        logCompletedStep(data?.id);
                        if (
                            dialCountryNum === "au" &&
                            props.documentType === DRIVERS_LICENCE &&
                            props.confirmOcrData
                        ) {
                            props.go(THANK_YOU);
                        } else {
                            props.nextStep();
                        }

                        if (isNfcStepPresent && !props.isNFCReady) props.nextStep();
                    }
                })
                .catch((err) => {
                    const {response} = err;
                    let errorStatusText = CLIENT_ERROR;
                    if (response?.status === 401 || response?.status === 403) return;
                    errorHandler();
                    if (!err) return;
                    let errorMessage = t("PageDocumentCaptureConfirm.error_reading_doc");
                    if (err.status >= 400) {
                        errorMessage = t(
                            "PageDocumentCaptureInstructions.error_document_quality_barcode"
                        );
                    }
                    if (response?.status === 413) {
                        errorMessage = t("PageDocumentCaptureInstructions.error_file_size");
                    } else if (response?.data && response?.status !== 500) {
                        const processingErrorMessage = processResponseErrors(
                            response.data,
                            t
                        );
                        if (processingErrorMessage) {
                            errorMessage = processingErrorMessage;
                        }
                    } else if (response?.status >= 400 && response?.status < 500) {
                        props.increaseRetryCounter();
                    } else if (response?.status >= 500) {
                        errorStatusText = SERVER_ERROR;
                        props.increaseRetryCounter();
                    }
                    logEvent(document_processing_error, {
                        errorMessage: response?.data?.message || response?.data,
                        status: response?.status,
                        statusText: response?.statusText || errorStatusText,
                    });
                    setError(errorMessage);
                });
        } else {
            props.go(DOCUMENT_CAPTURE_INSTRUCTIONS);
        }
    };
    if (error) {
        return (
            <DaonErrorBox
                style={props.style}
                message={
                    props.nfcData ? t("PageNFCScan.error_message") : error || props.error
                }
                whiteBackground={!props.notWhiteBackground}
            ></DaonErrorBox>
        );
    }

    return null;
};

export default connect(
    (state) => {
        const {processingRetriesCounter, document} = state.documentCapture;
        const {singlePaged} = state.documentCaptureConfiguration;
        const {featureFlags} = state.configuration.extraConfig;
        const {applicationId} = state.application;
        const {confirmOcrData} = state.configuration.extraConfig.featureFlags;
        let checkBarcode = false;

        // if (state.configuration.extraConfig?.steps?.document?.options) {
        //   checkBarcode =
        //     state.configuration.extraConfig?.steps?.document?.options.checkBarcode;
        // }

        const documentQualityHostname = state.configuration.documentQualityHostname
            ? `${state.configuration.documentQualityHostname}/rest/v1/quality/assessments`
            : "https://emea-saas-staging-docquality.identityx-cloud.com/rest/v1/quality/assessments";

        return {
            singlePaged,
            applicationId,
            processingRetriesCounter,
            confirmOcrData,
            shouldNotSendUnprocessedDocuments:
            featureFlags?.shouldNotSendUnprocessedDocuments,
            documentValidationEnabled:
            state.configuration.extraConfig.steps?.document?.options
                ?.documentValidationEnabled,
            documentProcessorRetries:
                featureFlags?.documentProcessorRetries ||
                state.configuration.extraConfig.steps?.document?.options
                    ?.documentProcessorRetries ||
                3,
            // checkBarcode: checkBarcode || false,
            document,
            documentQualityHostname,
        };
    },
    {
        increaseRetryCounter,
        nextPage,
        nextStep,
        go,
        documentsSave,
        setDocumentId,
        auDocumentsSave,
    }
)(SubmitDocument);
