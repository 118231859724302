export const ID_CARD = "ID_CARD";
export const PASSPORT = "PASSPORT";
export const DRIVERS_LICENCE = "DRIVERS_LICENCE";
export const RESIDENCE_CARD = "RESIDENCE_CARD";
export const OTHER_DOCUMENT = "OTHER_DOCUMENT";
export const ID_CARD_PORT = "ID_CARD_PORT";
export const ID_CARD_LAND_PORT = "ID_CARD_LAND_PORT";
export const DRIVERS_LICENCE_PORT = "DRIVERS_LICENCE_PORT";
export const OTHER_DOCUMENT_PORT = "OTHER_DOCUMENT_PORT";

export const CANVAS_DOCUMENT_PASSED = "#66CB9F";
export const CANVAS_DOCUMENT_FOUND = "#FAC109";
export const CANVAS_DOCUMENT_NOT_FOUND = "#F16063";

export const ID_CARD_RATIO = { aspectRatio: 1.5858 };
export const PASSPORT_RATIO = { aspectRatio: 1.4205 };
export const DOCUMENT_SIDE_BACK = "back";
export const DOCUMENT_SIDE_FRONT = "front";
export const DOCUMENT_INSTRUCTIONS = "DocumentInstructions";
export const DOCUMENT_DATE_OF_EXPIRY = "Date Of Expiry";
export const DOCUMENT_DATE_OF_BIRTH = "Date Of Birth";
export const DOCUMENT_NUMBER = "Identity Card Number";
export const REFERENCE_NUMBER = "Reference Number";
export const DOCUMENT_UNIQUE_NUMBER = "Unique Number";
export const DRIVERS_LICENCE_CODE = "DL Class Code C To";
export const DRIVERS_LICENCE_602 = "602";
export const DRIVERS_LICENCE_605 = "605";
export const DRIVERS_LICENCE_608 = "608";
export const DRIVERS_LICENCE_611 = "611";
export const DRIVERS_LICENCE_614 = "614";
export const DRIVERS_LICENCE_620 = "620";
export const DRIVERS_LICENCE_623 = "623";

