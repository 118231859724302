import React, { useState, useEffect } from "react";
import * as S from "./CountDownMessage.styles";
import { useTranslation } from "react-i18next";

const CountdownMessage = () => {
  const [count, setCount] = useState(5);
  const { t } = useTranslation();
  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [count]);
  
  return (
    <S.Container>
      <S.Message>
      {t("Common.capture_starting")}
        <S.Countdown>{count > 0 ? count : t("Common.starting")}</S.Countdown>
      </S.Message>
    </S.Container>
  );
};

export default CountdownMessage;
