import PageContent from "../PageContent";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { connect } from "react-redux";
import { logout } from "logic/api/logout";
import { ReactComponent as InfoIcon } from "assets/icons/info_icon.svg";

function PageContinueOnDesktop(props) {
  const { t } = useTranslation();
  useEffect(() => {
    logout().then((data) => console.log("Successfully logout!"));
  }, []);
  return (
    <PageContent
      justifyContent="center"
      showBack={false}
      title={t("PageThankYou.verification_complete")}
      hideHeader={true}
    >
      <div style={{ marginLeft: "0.5em" }}>
        <InfoIcon />
      </div>
      <h2>{t("PageContinueOnDesktop.title")}</h2>
      <p>
        {t(
          props.isSwitchedToMobile
            ? "PageContinueOnDesktop.description"
            : "PageThankYou.description"
        )}
      </p>
      <p>{t("PageThankYou.footer_new")}</p>
    </PageContent>
  );
}

export default connect((state) => {
  return {
    isSwitchedToMobile: state.application.isSwitchedToMobile,
  };
})(PageContinueOnDesktop);
