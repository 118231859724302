export const CACHE_NAVIGATION = "CACHE_NAVIGATION";
export const CACHE_APPLICATION = "CACHE_APPLICATION";
export const CACHE_LIVENESS_TEST = "CACHE_LIVENESS_TEST";
export const CACHE_DOCUMENT_CAPTURE = "CACHE_DOCUMENT_CAPTURE";
export const CACHE_SESSION_DATA = "CACHE_SESSION_DATA";
export const CACHE_DOCUMENT_ID = "CACHE_DOCUMENT_ID";

let _cacheType = "CACHE_TYPE_SESSIONSTORAGE";
let _cacheName_prefix = "SESSION_DATA_";

export function writeToLocalCache(data, storageName) {
  if (_cacheType === "CACHE_TYPE_SESSIONSTORAGE") {
    try {
      if (!!data) {
        sessionStorage.setItem(
          _cacheName_prefix + storageName,
          JSON.stringify(data)
        );
      }
    } catch {}
  } else if (_cacheType === "CACHE_TYPE_LOCALSTORAGE") {
    try {
      localStorage.setItem(
        _cacheName_prefix + storageName,
        JSON.stringify(data)
      );
    } catch {}
  }
}

export function readLocalCache(storageName) {
  try {
    const rawData =
      _cacheType === "CACHE_TYPE_SESSIONSTORAGE"
        ? sessionStorage.getItem(_cacheName_prefix + storageName)
        : _cacheType === "CACHE_TYPE_LOCALSTORAGE"
        ? localStorage.getItem(_cacheName_prefix + storageName)
        : undefined;

    if (rawData === null) {
      return undefined;
    }
    return JSON.parse(rawData);
  } catch (err) {
    return undefined;
  }
}

export function clearCache() {
  if (_cacheType === "CACHE_TYPE_SESSIONSTORAGE") {
    sessionStorage.removeItem(_cacheName_prefix + CACHE_NAVIGATION);
    sessionStorage.removeItem(_cacheName_prefix + CACHE_APPLICATION);
    sessionStorage.removeItem(_cacheName_prefix + CACHE_LIVENESS_TEST);
    sessionStorage.removeItem(_cacheName_prefix + CACHE_DOCUMENT_CAPTURE);
    sessionStorage.removeItem(_cacheName_prefix + CACHE_SESSION_DATA);
    sessionStorage.removeItem(_cacheName_prefix + CACHE_DOCUMENT_ID);
  } else if (_cacheType === "CACHE_TYPE_LOCALSTORAGE") {
    localStorage.removeItem(_cacheName_prefix + CACHE_NAVIGATION);
    localStorage.removeItem(_cacheName_prefix + CACHE_APPLICATION);
    localStorage.removeItem(_cacheName_prefix + CACHE_LIVENESS_TEST);
    localStorage.removeItem(_cacheName_prefix + CACHE_DOCUMENT_CAPTURE);
    localStorage.removeItem(_cacheName_prefix + CACHE_SESSION_DATA);
    localStorage.removeItem(_cacheName_prefix + CACHE_DOCUMENT_ID);
  }
}
