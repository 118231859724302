export const getTranslatedContent = (documentContent) => {
  const {
    documentSide,
    translationPage,
    translate,
    subtitlePrefix,
    documentLabel,
    documentSideTranslated,
    documentType,
  } = documentContent;

  const subtitleDescription = subtitlePrefix + " ";

  if (documentLabel && documentSideTranslated) {
    const translatedTitle = translate(
      `${translationPage}.title_${documentLabel.toLowerCase()}_${documentSide}`
    );

    const documentLabelTitle =
      translatedTitle !==
      `${translationPage}.title_${documentLabel.toLowerCase()}_${documentSide}`
        ? translatedTitle
        : documentLabel + " - " + documentSideTranslated;

    const translatedSubtitle = translate(
      `${translationPage}.subtitle_${documentLabel.toLowerCase()}_${documentSide}`
    );

    const documentLabelSubtitle =
      translatedSubtitle !==
      `${translationPage}.subtitle_${documentLabel.toLowerCase()}_${documentSide}`
        ? translatedSubtitle
        : subtitleDescription + documentLabel + " - " + documentSideTranslated;

    return {
      title: documentLabelTitle,
      subtitle: documentLabelSubtitle,
    };
  } else {
    if (documentType) {
      return {
        title: translate(
          `${translationPage}.title_${documentType.toLowerCase()}_${documentSide}`
        ),
        subtitle:
          subtitleDescription +
          translate(
            `${translationPage}.subtitle_${documentType.toLowerCase()}_${documentSide}`
          ),
        // add descriptionMobile and descriptionDesktop if needed, translations for them are left for now
      };
    }
  }
};
