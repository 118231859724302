import { useEffect, useState } from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "Components/common/LoadingOverlay";
import {
  DAON_CALLBACK,
  OFFLINE_STATUS_CODE,
  ONLINE_STATUS_CODE,
} from "logic/enums/composite";
import { webView } from "logic/webView";

ConnectionSpinner.propTypes = {
  isOnline: bool,
};
let offlineTimeout;
let onlineTimeout;

function ConnectionSpinner({ children }) {
  const [isOnline, setIsOnline] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    function handleOffline() {
      if (offlineTimeout) clearTimeout(offlineTimeout);
      offlineTimeout = setTimeout(() => {
        if (!window.navigator.onLine) {
          setIsOnline(false);
          webView(DAON_CALLBACK, OFFLINE_STATUS_CODE);
        }
      }, 3000);
    }

    function handleOnline() {
      if (onlineTimeout) clearTimeout(onlineTimeout);
      onlineTimeout = setTimeout(() => {
        setIsOnline(true);
        webView(DAON_CALLBACK, ONLINE_STATUS_CODE);
      }, 1000);
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);
    return () => {
      if (offlineTimeout) clearTimeout(offlineTimeout);
      if (onlineTimeout) clearTimeout(onlineTimeout);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  if (isOnline) return children;

  return (
    <LoadingOverlay
      loadingText={t("Common.connection_check_internet_connection")}
    />
  );
}

export default ConnectionSpinner;
