import { RESEND_CODE } from "../actions/resendVerificationCode";

const defaultState = {
  resend: false,
};

export function resendVerificationCode(state = defaultState, action) {
  switch (action.type) {
    case RESEND_CODE:
      return {
        ...state,
        resend: true,
      };
    default:
      return state;
  }
}
