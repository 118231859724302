import styled from "styled-components/macro";

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-between"};
  min-height: 85%;
  margin: 0 auto;
  padding: ${(props) => props.isIntroScreen ? 0 : '1rem'};
  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    padding: 0 1rem 1rem 1rem;
  }
`;

export const BottomDiv = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 32px;
`;

export const WrapperDiv = styled.div`
  margin-left: ${(props) => props.marginLeft};
  display: flex;
  min-height: 100vh;
  position: relative;
  &::after {
    content: "";
    display: block;
    height: 50px; /* Set same as footer's height */
  }
  height: ${(props) => (props.isScrollDisabled ? "100vh" : "initial")};
  overflow-y: ${(props) => (props.isScrollDisabled ? "hidden" : "auto")};
`;

export const SideDiv = styled.div`
  position: relative;
  display: none;
  z-index: 5;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.panelBackground};
  & h2 {
    margin-right: 70%;
  }
  @media (min-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    width: 20%;
    min-width: ${(props) => props.minWidth};
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.justifyContent};
  }
`;

export const MainContentDiv = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  z-index: 20;
`;

export const ActionHeader = styled.div`
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  z-index: 20;
  display: flex;
`;

export const Logo = styled.div`
  margin: 2em 0 3em;
`;

export const SideTitle = styled.h2`
  margin: -0.9em 0 2em;
`;

export const SideSubtitle = styled.div`
  & h2 {
    margin-right: 0;
    text-align: start;
  }
`;
