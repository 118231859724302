import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import PageContent from "../../../PageContent";
import {
  challengeCreate,
  challengeCreateNFC,
} from "logic/actions/api/liveness";
import { go, nextStep } from "logic/actions/navigation";
import { PAGE_SESSION_EXPIRED } from "logic/enums/pages";
import DaonSpinner from "Components/common/DaonSpinner";
import { logEvent, challenge_created } from "logic/eventLogger";
import { shouldSkipIntroScreenMobile } from "logic/reducers/configuration";

function PageCreateChallenge(props) {
  const dispatch = useDispatch();
  const shouldSkipSwitchIntroScreen = useSelector(shouldSkipIntroScreenMobile);
  const createChallenge = async () => {
    try {
      await challengeCreate()(dispatch);
      logEvent(challenge_created);
      if (props.composite.mobileAppPlatform)
        await challengeCreateNFC()(dispatch);

      //add logEvent
      props.nextStep();
    } catch (e) {
      console.log(e);
      props.go(PAGE_SESSION_EXPIRED);
    }
  };
  useEffect(() => {
    createChallenge();
  }, []);
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  return (
    <>
      {!shouldSkipSwitchIntroScreen ? (
        <PageContent
          justifyContent="center"
          showBack={false}
          title={t("PageValidatePhone.mobile")}
        >
          <h2>{t("Common.processing")}</h2>
          <DaonSpinner color={theme.spinner} />
        </PageContent>
      ) : (
        <PageContent showBack={false} hideHeader={true}></PageContent>
      )}
    </>
  );
}

export default connect((state) => state, {
  nextStep,
  go,
  challengeCreate,
  challengeCreateNFC,
})(PageCreateChallenge);
