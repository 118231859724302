import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "Components/common/LoadingOverlay";

export default function ProcessingOverlay({
  children,
  toggleLoading,
  loadingText,
  isDarkMode,
}) {
  const { t } = useTranslation();
  const [textUpdated, setTextUpdated] = useState(t("Common.processing"));
  let [counter, setCounter] = useState(0);

  useEffect(() => {
    if (!toggleLoading) setCounter(0);
    let loadingInterval = setInterval(() => {
      if (toggleLoading) setCounter((c) => c++);
    }, 1000);
    return () => {
      clearInterval(loadingInterval);
    };
  }, [toggleLoading]);

  useEffect(() => {
    if (counter > 13) {
      setTextUpdated(t("Common.still_processing"));
    } else {
      setTextUpdated(loadingText || t("Common.processing"));
    }
  }, [counter, loadingText]);

  return (
    <>
      {children}
      {toggleLoading && (
        <LoadingOverlay isDarkMode={isDarkMode} loadingText={textUpdated} />
      )}
    </>
  );
}
