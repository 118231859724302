import countries from "i18n-iso-countries";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { isMobile } from "logic/deviceType";
import Select from "react-select";
import DaonSpinner from "Components/common/DaonSpinner";

export default function CountrySelector(props) {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language.substring(0, 2));
  const [allCountries, setAllCountries] = useState(loadCountryNames);
  const [countriesLoaded, setLoadedCountries] = useState(false);
  const [suggestedName, setSuggestedName] = useState("");
  const [loading, setLoading] = useState(true);
  const [countryOptions, setCountryOptions] = useState();

  const loadIsoCountries = async (locale) => {
    return new Promise((resolve, reject) => {
      try {
        countries.registerLocale(
          require(`i18n-iso-countries/langs/${locale.substr(0, 2)}.json`)
        );
        setAllCountries(loadCountryNames);
        setLoadedCountries(true);
        resolve();
      } catch (e) {
        setLocale("en");
        reject(e);
      }
    });
  };

  useEffect(() => {
    setLoadedCountries(false);
    loadIsoCountries(locale).then(() => {
      setLoading(false);
      setCountryOptions(formatCountryOptions(loadCountryNames()));
      setSuggestedName(countries.getName(props.dialCountryNum, locale));
    });
  }, [locale]);

  function formatCountryOptions(countries) {
    return countries.map((country) => {
      return {
        value: country,
        label: country,
      };
    });
  }

  function loadCountryNames() {
    const localeSupported = countries.langs().includes(locale);
    const localeToUse = localeSupported ? locale : "en";

    if (props.document?.options?.allowedCountries?.length > 0) {
      return props.document.options.allowedCountries.map((countryCode) =>
        countries.getName(countryCode, localeToUse)
      );
    }

    return Object.values(countries.getNames(localeToUse));
  }

  i18n.on("languageChanged", (lng) => {
    setLocale(i18n.language.substring(0, 2));

    setCountryOptions(formatCountryOptions(loadCountryNames()));
  });

  const style = {
    control: (styles) => ({
      ...styles,
      width: !isMobile(navigator.userAgent) ? "35ch" : "100%",
    }),
    menu: (styles) => ({
      ...styles,
      width: !isMobile(navigator.userAgent) ? "35ch" : "100%",
    }),
  };

  useEffect(() => {
    setLoadedCountries(false);
    loadIsoCountries(locale);
    setSuggestedName(countries.getName(props.dialCountryNum, locale));
  }, [locale]);

  return (
    <>
      {loading ? (
        <DaonSpinner isDarkMode={true} />
      ) : (
        <Select
          placeholder={t("Common.chooseCountry")}
          aria-label="select_country"
          id="select_country"
          options={countryOptions}
          onChange={(e) => {
            const localeSupported = countries
              .langs()
              .includes(i18n.language.substr(0, 2));
            const localeToUse = localeSupported
              ? i18n.language.substr(0, 2)
              : "en";
            const countryCode = countries
              .getAlpha2Code(e.value, localeToUse)
              .toLowerCase();
            props.countrySelected(countryCode);
          }}
          styles={style}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      )}
    </>
  );
}
