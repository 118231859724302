export default (width, height) => {
  return new Promise((resolve, reject) => {
    const docCaptureInstance = new Daon.DocumentCapture({
      width,
      height,
    });
    docCaptureInstance
      .hasMinimumResolution()
      .then(() => resolve())
      .catch(() => reject());
  });
};
