import PoweredByDaon from "../PoweredByDaon";
import * as S from "./PoweredByDaon.styles";

export default function PoweredByDaonAbsolute({
  hideFooter,
  white,
  className,
}) {
  return (
    <S.Wrapper hideFooter={hideFooter}>
      <PoweredByDaon white={white} className={className} />
    </S.Wrapper>
  );
}
