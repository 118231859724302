import locales from "locales";
import { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { isMobile } from "logic/deviceType";
import { useSelector, useDispatch } from "react-redux";
import { applicationUpdateParams } from "logic/actions/api/application";
import { useContext } from "react";
import Select from "react-select";

export default function SelectLanguage({
  mobile = false,
  isDarkMode = false,
  isIntroScreen = false,
}) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const color = isDarkMode
    ? "#FFF"
    : mobile
    ? theme.textPrimary
    : theme.mainColor;

  const languageOptions = useSelector((state) => {
    const stateLangs =
      state.configuration?.extraConfig?.localization?.languages;
    return stateLangs
      ? stateLangs.map((languageCode) => {
          return {
            value: locales[languageCode]?.label,
            label:
              !isMobile(navigator.userAgent) || isIntroScreen
                ? locales[languageCode]?.label
                : languageCode.toUpperCase(),
            key: languageCode,
            id: `"lang_" + ${languageCode}`,
          };
        })
      : null;
  });

  const updateApplicationLanguage = () => {
    dispatch(applicationUpdateParams());
  };
  const changeLanguage = (language) => {
    i18n.changeLanguage(language.key);
    document.documentElement.lang = i18n.language;
    updateApplicationLanguage();
  };

  const style = {
    control: (styles) => ({
      ...styles,
      width:!isMobile(navigator.userAgent) || isIntroScreen ? "12ch" : "100%",
      borderColor: color,
      backgroundColor: isDarkMode ? "black" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: color,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: color,
    }),
    menu: (styles) => ({
      ...styles,
      maxHeight: "25vh",
      width: !isMobile(navigator.userAgent) || isIntroScreen ?  "12ch" : "100%",
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: "25vh",
      overflowY: "scroll",
      width: !isMobile(navigator.userAgent) || isIntroScreen? "12ch" : "100%",
    }),
  };

  return (
    <>
      {languageOptions && languageOptions.length > 1 && (
        <Select
          id="select_language"
          aria-labelledby="select_language"
          options={languageOptions}
          inputProps={{ readOnly: true }}
          isSearchable={false}
          value={languageOptions.filter(
            (option) => option.key === i18n.language
          )}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          }}
          menuPlacement={isIntroScreen ? "top" : "bottom"}
          
          onChange={(e) => changeLanguage(e)}
          styles={style}
        />
      )}
    </>
  );
}
