import SelectLanguage from "Components/common/SelectLanguage";
import { previous } from "logic/actions/navigation";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import * as S from "./Header.styles";
import { steps } from "logic/steps";
import locales from "locales";
import WhitelabelCompanyLogo from "Components/common/WhitelabelCompanyLogo/WhitelabelCompanyLogo";
import {
  shouldSkipIntroScreenMobile,
} from "logic/reducers/configuration";

export default function Header({
  title,
  showBack = true,
  isDarkMode = false,
  onClick,
  instructions,
  isIntroScreen = false,
  hideHeader = false,
}) {
  const numberOfSteps = steps.length - 1;
  const currentStep = useSelector((store) => store.navigation.step - 1);
  const shouldSkipSwitchIntroScreen = useSelector(shouldSkipIntroScreenMobile);
  const dispatch = useDispatch();
  const back = () => {
    dispatch(previous());
  };
  const theme = useContext(ThemeContext);
  const companyLogo = useSelector(
    (state) => state.configuration?.extraConfig?.whitelabel?.companyLogo
  );
  const supportedLanguages = useSelector((state) => {
    const stateLangs =
      state.configuration.extraConfig.localization &&
      state.configuration.extraConfig.localization.languages;
    if (stateLangs && stateLangs[0]) {
      let newLangsArray = [];
      stateLangs.forEach((langCode) => {
        if (locales[langCode] !== undefined) {
          newLangsArray.push({
            code: langCode,
            label: locales[langCode].label,
          });
        }
      });
      return newLangsArray;
    }
    return stateLangs;
  });
  return (
    <S.Header
      backgroundColor={
        isDarkMode ? theme.darkBackground : theme.lightBackground
      }
    >
      <S.HeaderDiv>
        {showBack && (
          <S.ArrowDiv>
            <S.Button onClick={onClick || back}>
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 7H4.33L9.92 1.41L8.5 0L0.5 8L8.5 16L9.91 14.59L4.33 9H16.5V7Z"
                  fill={isDarkMode ? "#FFF" : theme.textPrimary}
                />
              </svg>
            </S.Button>
          </S.ArrowDiv>
        )}
        {companyLogo && (
          <S.LogoDiv>
            {(!hideHeader || !shouldSkipSwitchIntroScreen)&&<WhitelabelCompanyLogo logoSize={32} />}
          </S.LogoDiv>
        )}

        {!hideHeader && !isIntroScreen && (
          <S.Title
            color={isDarkMode ? "#FFF" : null}
            textAlign={companyLogo ? "left" : "none"}
          >
            {title || "Onboarding"}
          </S.Title>
        )}
        {supportedLanguages && supportedLanguages.length > 1 && !hideHeader && (
          <S.LanguageDiv>
            {instructions && (
              <SelectLanguage mobile={true} isDarkMode={isDarkMode} />
            )}
          </S.LanguageDiv>
        )}
      </S.HeaderDiv>
      {!isDarkMode && !hideHeader && !isIntroScreen && (
        <S.Progress aria-label="Progress:" max={numberOfSteps} value={currentStep + 1}></S.Progress>
      )}
    </S.Header>
  );
}

