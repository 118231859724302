import { getUserId } from "logic/userId";

const userConsents = {
  policies: [
    {
      name: "Terms of Use",
      document: "/policies/TermsOfUse_v2_0.txt",
      consentProvided: true,
    },
    {
      name: "Privacy Policy",
      document: "/policies/PrivacyPolicy_v2_0.txt",
      consentProvided: true,
    },
  ],
  consents: [
    {
      name: "Use of biometrics for authentication",
      description:
        "I agree to the processing of my biometrics for the purpose of authentication by this application.",
      consentProvided: true,
    },
  ],
};

export const userConsentRequest = {
  serviceId: window.location.href.split("?")[0],
  appId: "Onboarding Web App",
  userId: getUserId(),
  production: process.env.NODE_ENV === "production",
  consents: JSON.stringify(userConsents),
};
