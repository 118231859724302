 import styled from 'styled-components'

export  const BackButton = styled.button`
height: 33px;
max-width: 120px;
background: ${props => props.theme.panelBackground};
border-radius: 4px;
display: grid;
grid-template-columns: 25px 1.9fr;
align-items: center;
padding: 0px 10px;
border:none;
font-weight:600;
z-index: 18;
position: relative;
color: ${props => props.theme.textPrimary};
`
