import { useEffect, useState } from "react";
import * as Api from "logic/api";
import ButtonRestartApp from "../../common/ButtonRestartApp";
import { useDispatch, useSelector } from "react-redux";
import { getTenantNameFromHost } from "logic/tenant";
import { useTranslation } from "react-i18next";
import { setConfiguration } from "logic/actions/configuration";
import {
  clearCache,
  readLocalCache,
  CACHE_SESSION_DATA,
} from "logic/localCacheAdapter";
import { getPublicKey } from "logic/api/getPublicKeys";
import { application_start, logEvent } from "logic/eventLogger";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import {
  selectLanguage,
  selectSessionExpirationTimeSeconds,
  selectSupportedLanguages,
  selectTranslations,
} from "logic/reducers/configuration";
import DaonErrorBox from "Components/common/DaonErrorBox";
import PageContent from "Components/PageContent";
import * as S from "./ApplicationConfig.styles";
import PoweredByDaon from "../../common/PoweredByDaon";
import { ThemeProvider } from "styled-components";
import DaonSpinner from "Components/common/DaonSpinner";
import { setPublicKey } from "logic/actions/fidelity";
import locales from "locales";

function ApplicationConfig({ children }) {
  const [loaded, setLoaded] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [error, setError] = useState("");
  const [customTheme, setCustomTheme] = useState({});
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const sessionExpiratonTime =
    useSelector(selectSessionExpirationTimeSeconds) * 1000;
  const language = useSelector(selectLanguage);
  const supportedLanguages = useSelector(selectSupportedLanguages);
  const translations = useSelector(selectTranslations);

  const tenantNamespace = getTenantNameFromHost();
  useEffect(() => {
    if (window.location.href.includes("newSession=true")) {
      localStorage.removeItem("i18nextLng");
      clearCache();
      i18n.changeLanguage();
    }

    logEvent(application_start);
    if (!getTimePassed("application")) startEventTimer("application");
    setLoadingText(t(`Common.slogan${randomInt(1, 3)}`));
  }, []);

  useEffect(() => {
    const sessionData = readLocalCache(CACHE_SESSION_DATA);
    if (sessionData) {
      const sessionDataTimestamp = sessionData.timestamp;
      const currentTimestamp = new Date().getTime();
      if (currentTimestamp - sessionDataTimestamp > sessionExpiratonTime) {
        clearCache();
        window.location.reload();
      }
    }
  }, [sessionExpiratonTime]);

  useEffect(() => {
    Api.getConfiguration()
      .then((response) => {
        dispatch(setConfiguration(response.data));
        if (response.data.extraConfig?.theme) {
          setCustomTheme(response.data.extraConfig.theme);
        }
        if (
          response.data.extraConfig.steps?.face?.options?.isEncryptionEnabled
        ) {
          getPublicKey().then((response) => {
            dispatch(
              setPublicKey(response.data.keys.find((el) => el.use == "enc"))
            );
          });
        }
        setLoaded(true);
      })
      .catch((err) => {
        let errMessage =
          typeof err.response !== "undefined" && err.response.data.message
            ? err.response.data.message
            : "Failed to load application. Check your internet connection";
        setError(errMessage);
      });
  }, [dispatch]);

  useEffect(() => {
    if (language) {
      if (!supportedLanguages?.includes(i18n.language)) {
        const languageTag = i18n.language.substring(0, 2);
        supportedLanguages?.includes(languageTag)
          ? i18n.changeLanguage(languageTag)
          : i18n.changeLanguage(language);
      }
    }
  }, [language, supportedLanguages]);

  useEffect(() => {
    if (translations && tenantNamespace && supportedLanguages) {
      let allKeys = [];
      const translationsKeys = Object.keys(translations);
      translationsKeys.forEach((lang)=> {
        if(translations[lang]) {
          Object.keys(translations[lang]).forEach(translation => allKeys.push(translation));
        }
      });
      const uniqueKeys = [...new Set(allKeys)];
      supportedLanguages.forEach((lang) => {
        const translationsBundle = {};
        uniqueKeys.forEach(key => {
          if (translations[lang] && translations[lang][key]) {
            translationsBundle[key] = translations[lang][key]
          } else {
            translationsBundle[key] = locales[lang].translation[key]
          }
        });
        i18n.addResourceBundle(lang, tenantNamespace, translationsBundle);
      });
      i18n.setDefaultNamespace(tenantNamespace);
    }
  }, [translations, tenantNamespace, i18n]);

  const randomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  if (loaded)
    return <ThemeProvider theme={{ ...customTheme }}>{children}</ThemeProvider>;

  if (error)
    return (
      <ThemeProvider theme={{ ...customTheme }}>
        <PageContent justifyContent="center" showBack={false}>
          <div style={{ display: "none" }} data-testid="panel-error"></div>
          <DaonErrorBox message={error} whiteBackground />
          <ButtonRestartApp />
        </PageContent>
      </ThemeProvider>
    );

  return (
    <S.Wrapper>
      {/* <DaonSpinner /> */}
      <PoweredByDaon white={true} hideFooter={false} />
      <S.Heading data-testid="loading-configuration">{loadingText}</S.Heading>
    </S.Wrapper>
  );
}

export default ApplicationConfig;
