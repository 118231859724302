import styled from 'styled-components';

export const ListWrapper = styled.div`
    color: ${props => props.theme.textPrimary};
    margin-bottom: 30px;
`;

export const OrderedList = styled.ol`
    padding-inline-start: 0px;
    list-style: none;
    margin-bottom: 50px;
`;

export const ListedItem = styled.li`
    display: flex;
    margin: 25px auto;
`;

export const LiWrapper = styled.div`
    position: relative;
    margin-right: 15px;
`;

export const StepCircle = styled.div`
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.textSecondary};
    align-items: center;
    text-align: center;
    line-height: 23px;
`;

export const VerticalLine = styled.div`
    border-left: 2px solid ${props => props.theme.textSecondary};
    height: 96%;
    left: 48%;
    position: absolute;
`;

export const StepDescription = styled.p`
    margin: 2px 0 0 0;
    display: inline-block;
    color: ${props => props.theme.textPrimary};
    font-size: 16px;
`;
