import { useEffect, useState } from "react";
import ButtonRestartApp from "../../common/ButtonRestartApp";
import PageContent from "../../PageContent";
import { useDispatch, connect } from "react-redux";
import { applicationGet, getStart } from "logic/actions/api/application";
import i18n from "i18n";
import {
  setApplicationData,
  setApplicationId,
} from "logic/actions/application";
import { setTestData } from "logic/actions/livenessTest";
import { go, goToStepNumber } from "logic/actions/navigation";
import { logEvent, switched_to_mobile } from "logic/eventLogger";
import {
  CREATE_CHALLENGE,
  QR_INSTRUCTIONS,
  VALIDATE_PHONE,
} from "logic/enums/pages";
import * as S from "./ApplicationContinue.styles";
import {
  clearCache,
  readLocalCache,
  CACHE_APPLICATION,
  CACHE_LIVENESS_TEST,
  CACHE_NAVIGATION,
  CACHE_DOCUMENT_CAPTURE,
  CACHE_SESSION_DATA,
  CACHE_DOCUMENT_ID,
} from "logic/localCacheAdapter";
import { countrySelected } from "logic/actions/documentCaptureConfiguration";
import { getTenantNameFromHost, isSmsLink } from "logic/tenant";
import { useTranslation } from "react-i18next";
import DaonErrorBox from "Components/common/DaonErrorBox";
import { setDocumentId } from "logic/actions/documentCapture";
import { webView } from "logic/webView";
import {
  DAON_CALLBACK,
  SESSION_EXPIRED_STATUS_CODE,
} from "logic/enums/composite";
import { setUser } from "logic/integrations/datadog";

const ApplicationContinue = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [switchingToMobile, setSwitchingToMobile] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const parameters = new URLSearchParams(window.location.search);
    const code = parameters.get("code");
    const applicationId = parameters.get("accountApplicationId");
    async function handleApiCalls() {
      try {
        if (applicationId) {
          const response = await applicationGet()(dispatch);
          setApplicationData(response.data)(dispatch);
          setUser(response.data?.user?.idxFriendlyUserId, applicationId);
          const { countryCode, languageTag } = response.data;
          if (countryCode) countrySelected(countryCode)(dispatch);
          window.history.replaceState(
            {},
            document.title,
            "start?accountApplicationId=" + applicationId
          );
          if (code) {
            setSwitchingToMobile(true);
            clearCache();
            logEvent(switched_to_mobile);
            setLoaded(true);
            go(CREATE_CHALLENGE)(dispatch);
          } else {
            const navigation = readLocalCache(CACHE_NAVIGATION);
            const cacheSessionData = readLocalCache(CACHE_SESSION_DATA);
            const cacheApplication = readLocalCache(CACHE_APPLICATION);
            if (navigation && navigation.page && cacheApplication) {
              if (
                navigation.page === QR_INSTRUCTIONS ||
                navigation.page === VALIDATE_PHONE
              ) {
                dispatch(setApplicationData(cacheApplication));
                go(navigation.page)(dispatch);
              } else if (
                cacheSessionData &&
                cacheSessionData.tenant === getTenantNameFromHost()
              ) {
                dispatch(setApplicationData(cacheApplication));

                const cacheDocumentCapture = readLocalCache(
                  CACHE_DOCUMENT_CAPTURE
                );
                if (
                  cacheDocumentCapture &&
                  cacheDocumentCapture.dialCountryNum
                ) {
                  countrySelected(cacheDocumentCapture.dialCountryNum)(
                    dispatch
                  );
                }

                const documentId = readLocalCache(CACHE_DOCUMENT_ID);
                if (documentId) {
                  setDocumentId(documentId)(dispatch);
                }

                const cacheLivenessTest = readLocalCache(CACHE_LIVENESS_TEST);
                if (cacheLivenessTest) {
                  setTestData(cacheLivenessTest)(dispatch);
                }
                go(navigation.page)(dispatch);
              } else {
                clearCache();
              }
            } else {
              clearCache();
              goToStepNumber(0)(dispatch);
            }
            setLoaded(true);
          }
        }
      } catch (err) {
        webView(DAON_CALLBACK, SESSION_EXPIRED_STATUS_CODE);
        if (err.toString().includes("SMS code already validated")) {
          setError(t("Common.app_continue_error_link_expired"));
        } else {
          setError(t("Common.app_continue_error_switching_to_mobile"));
        }
      }
    }
    handleApiCalls();
  }, []);

  if (loaded) return props.children;

  if (error)
    return (
      <PageContent
        justifyContent="center"
        showBack={false}
        title={t("PageContinue.title")}
      >
        <h2>{t("PageContinue.error")}</h2>
        <DaonErrorBox message={error} whiteBackground={true} />
        {!props.runsWithIdp ? <ButtonRestartApp /> : null}
      </PageContent>
    );

  return (
    <S.Wrapper>
      <S.Content color = {!switchingToMobile ? "#FFF" : "black"}>
        {switchingToMobile
          ? t("Common.app_continue_switching_to_mobile")
          : t("Common.processing")}
      </S.Content>{" "}
    </S.Wrapper>
  );
};

export default connect((state) => state)(
  ApplicationContinue,
  setApplicationData,
  setApplicationId,
  applicationGet,
  getStart,
  isSmsLink,
  setDocumentId
);
