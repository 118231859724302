export function getCameraIds() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      return devices
        .filter((device) => deviceFilter(device))
        .map((device) => {
          return { cameraId: device.deviceId, label: device.label };
        });
    })
    .catch(function (err) {
      console.log(err.name + ": " + err.message);
    });
}

function deviceFilter(device) {
  return device.kind === "videoinput" && !device?.label?.includes(" IR ");
}
