import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  background: #FFF;
`;

export const Heading = styled.h2`
  color: #FFF;
  text-align: center;
  font-size: 1.2rem;
`;

