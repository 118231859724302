import { useTranslation } from "react-i18next";
import { clearCache } from "logic/localCacheAdapter";
import DaonButton from "../DaonButton";

export default function ButtonRestartApp({ onClick }) {
  function restartApp() {
    clearCache();
    window.location.reload();
  }

  const { t } = useTranslation();
  return (
    <DaonButton onClick={onClick || restartApp} id="restart_app">
      {t("Common.btn_restart_app")}
    </DaonButton>
  );
}
