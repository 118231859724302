import { v4 as uuidv4 } from 'uuid';

let _userId;

export function getUserId() {
  if (!_userId) {
    _userId = uuidv4();
  }
  return _userId;
}
