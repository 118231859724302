import { store } from "../../store";
import {
  PASSPORT,
  RESIDENCE_CARD,
  DOCUMENT_DATE_OF_EXPIRY,
  DOCUMENT_NUMBER,
  DOCUMENT_DATE_OF_BIRTH,
} from "../../enums/document";
import { refreshSession } from "logic/refreshSession";
import { axiosInstance } from "logic/api";
import { getDocumentData } from "logic/documentData";
import { writeToLocalCache, CACHE_DOCUMENT_ID } from "logic/localCacheAdapter";
import { DIGEST_ALGORITHM, SIGNATURE_ALGORITHM } from "logic/enums/composite";

const actionPrefix = "API_";
export const SAVE_DOCUMENT = actionPrefix + "SAVE_DOCUMENT";

/**
 * POST /{tenant}/DigitalOnBoardingServices/rest/v1/users/{userId}/idchecks/{idCheckId}/documents
 */
export const documentsSave =
  ({
    base64CroppedFirstPage,
    base64CroppedSecondPage,
    documentType,
    nfc,
    uncroppedFirstPage,
    uncroppedSecondPage,
    accountApplicationId,
    country,
  }) =>
  () => {
    const documentStore = store.getState().documentCaptureConfiguration;
    const isDocumentSinglePaged = documentStore.singlePaged;
    const compositeStore = store.getState().composite;
    const { documentNumber, documentExpiryDate, documentDateOfBirth } =
      store.getState().documentCapture;
    const nfcChallenge = compositeStore?.nfcTestData?.challenge;
    const documentId = store.getState().documentCapture.documentId;
    let images = [];

    if (base64CroppedFirstPage) {
      images = [
        ...images,
        getDocumentData("FRONT", "PROCESSED", base64CroppedFirstPage),
      ];
    }

    if (
      documentType !== PASSPORT &&
      isDocumentSinglePaged !== true &&
      base64CroppedSecondPage
    ) {
      images = [
        ...images,
        getDocumentData("BACK", "PROCESSED", base64CroppedSecondPage),
      ];
    }

    if (uncroppedFirstPage) {
      images = [
        ...images,
        getDocumentData("FRONT", "UNPROCESSED", uncroppedFirstPage),
      ];
    }

    if (uncroppedSecondPage) {
      images = [
        ...images,
        getDocumentData("BACK", "UNPROCESSED", uncroppedSecondPage),
      ];
    }
    let nfcData;
    if (nfc?.passport?.efdata && nfcChallenge) {
      const { dg1, dg2, dg15, sod } = nfc?.passport.efdata;
      nfcData = {
        sensitiveData: {
          ePassportData: {
            dgFile1: dg1,
            dgFile2: dg2,
            dgFile15: dg15,
            sodFile: sod,
          },
          ePassportActiveAuthentication: {
            challenge: nfcChallenge,
            digestAlgorithm: DIGEST_ALGORITHM,
            signatureAlgorithm: SIGNATURE_ALGORITHM,
          },
        },
      };
    }

    let ocrData;

    if (documentNumber && documentExpiryDate) {
      ocrData = {
        sensitiveData: {
          value: {
            [DOCUMENT_NUMBER]: documentNumber,
            [DOCUMENT_DATE_OF_EXPIRY]: documentExpiryDate,
            [DOCUMENT_DATE_OF_BIRTH]: documentDateOfBirth,
          },
        },
      };
    }
    const request = {
      processingType: "ID",
      captureType: "BBC",
      captured: new Date(),
      // country,
      // accountApplicationId,
      // documentType,
      captureFormat: documentType === PASSPORT ? "PASSPORT" : "ID1",
      clientCapture: {
        images,
        nfcData,
        ocrData,
      },
    };
    const { application, configuration, documentCapture } = store.getState();
    const isAsync =
      configuration.extraConfig.featureFlags.asyncDocumentProcessing ===
        "true" ||
      configuration.extraConfig.featureFlags.asyncDocumentProcessing === true;
    const policyName = (documentCapture?.document?.documentPolicy || configuration?.extraConfig?.steps?.document?.options?.documentPolicy) ?? false;
    const policyParam = policyName ? `&documentPolicyName=${policyName}` : '';
    
    let documentID = sessionStorage.getItem("SESSION_DATA_CACHE_DOCUMENT_ID");
    let promise = axiosInstance.post(
      `/DigitalOnBoardingServices/rest/v1/users/${
        application.user.id
      }/idchecks/${application.user.idCheck.id}/documents${
        documentID ? `/${documentID.replace(/['"]+/g, "")}` : ""
      }?isAsync=${isAsync}&accountApplicationId=${accountApplicationId}&country=${country}&documentType=${documentType}${policyParam}`,
      request
    );
    //not sure about this one ?
    promise.then((response) => {
      writeToLocalCache(response.data.id, CACHE_DOCUMENT_ID);
    });
    return promise.finally((response) => {
      refreshSession();
      return response;
    });
  };

export const processResponseErrors = (responseData, translation) => {
  let errorMessage;
  writeToLocalCache(responseData?.document?.id, CACHE_DOCUMENT_ID);
  if (responseData.processingErrors?.items[0]) {
    errorMessage = "";
    responseData.processingErrors.items.forEach((item) => {
      let message = item.message;
      if (item.failureCode && translation) {
        let errorCode = translation(
          `DocumentCapture.${item.failureCode.toLowerCase()}`
        );
        if (errorCode != `DocumentCapture.${item.failureCode.toLowerCase()}`) {
          message = errorCode;
        }
      } else if (item.code == 90 && translation) {
        message = translation(`DocumentCapture.unable_to_recognize`);
      }
      if(responseData?.processingErrors?.items[0]?.message.includes("No face found")) {
        errorMessage = translation(`DocumentCapture.no_face_found`)
        return errorMessage
      }
      if (errorMessage.indexOf(message) == -1) {
        errorMessage = translation(
          "PageDocumentCaptureConfirm.error_reading_doc"
        );
      }
    });
  } else if (responseData.message) {
    errorMessage = responseData.message;
  }
  return errorMessage;
};

export const acceptableDocument = (
  responseData,
  t,
  documentType,
  country,
  nfcData
) => {
  if (country !== "es" || nfcData) {
    return null;
  }
  const data = responseData.data;
  if (documentType === PASSPORT) {
    if (!data.type.toLowerCase().includes("passport")) {
      return t("DocumentCapture.different_document");
    }
  } else {
    const acceptableDocs = ["id card", "resident", "residence"];
    const residenceCardDocs = ["resident", "residence"];
    if (!data.issuer || !data.issuer.toLowerCase().includes("spain")) {
      return t("DocumentCapture.wrong_country");
    } else if (
      !acceptableDocs.some((document) =>
        data.type.toLowerCase().includes(document.toLowerCase())
      )
    ) {
      return t("DocumentCapture.cannot_accept_document");
    } else if (
      documentType === RESIDENCE_CARD &&
      !residenceCardDocs.some((document) =>
        data.type.toLowerCase().includes(document.toLowerCase())
      )
    ) {
      return t("DocumentCapture.different_document");
    }
  }
  return null;
};
