export const application_end = "application_end";
export const application_start = "application_start";
export const challenge_created = "challenge_created";
export const composite_flow = "composite_flow";
export const document_autocapture_error = "document_autocapture_error";
export const document_autocapture_failed = "document_autocapture_failed";
export const document_autocapture_finished = "document_autocapture_finished";
export const document_autocapture_started = "document_autocapture_started";
export const document_capture_back = "document_capture_back";
export const document_capture_native = "document_capture_native";
export const document_capture_retry = "document_capture_retry";
export const document_capture_started = "document_capture_started";
export const document_capture_timeout = "document_capture_timeout";
export const document_changed_camera = "document_changed_camera";
export const document_processing_error = "document_processing_error";
export const document_processing_retry = "document_processing_retry";
export const face_capture_back = "face_capture_back";
export const face_capture_error = "face_capture_error";
export const face_capture_finished = "face_capture_finished";
export const face_capture_native = "face_capture_native";
export const face_capture_retry = "face_capture_retry";
export const face_capture_started = "face_capture_started";
export const face_capture_timeout = "face_capture_timeout";
export const gyroscope_declined="gyroscope_declined";
export const idrnd_liveness_error = "idrnd_liveness_error";
export const idrnd_liveness_passed = "idrnd_liveness_passed";
export const idrnd_liveness_retry = "idrnd_liveness_retry";
export const liveness_test_retry = "liveness_test_retry";
export const liveness_test_started = "liveness_test_started";
export const nfc_disabled = "nfc_disabled";
export const nfc_error = "nfc_error";
export const nfc_interrupted = "nfc_interrupted";
export const nfc_not_supported = "nfc_not_supported";
export const nfc_scan_canceled = "nfc_scan_canceled";
export const nfc_scan_finished = "nfc_scan_finished";
export const nfc_scan_started = "nfc_scan_started";
export const nfc_timeout = "nfc_timeout";
export const nfc_wrong_ios_version = "nfc_wrong_ios_version";
export const page_open = "page_open";
export const phone = "phone";
export const phone_verified = "phone_verified";
export const qr_code_scan_retry = "qr_code_scan_retry";
export const qr_sms_verification_fallback = "qr_sms_verification_fallback";
export const retry_sensor_init = "retry_sensor_init";
export const set_application_data = "set_application_data";
export const skipped_phone_verification = "skipped_phone_verification";
export const sms_retry = "sms_retry";
export const step_completed = "step_completed";
export const switch_flow_finished = "switch_flow_finished";
export const switched_to_mobile = "switched_to_mobile";
export const switching_to_mobile = "switching_to_mobile";
export const web_flow = "web_flow";

const loggingFunctions = [];

export function subscribeLoggingFunction(fn) {
  loggingFunctions.push(fn);
}

export function logEvent(name, params) {
  loggingFunctions.forEach((fn) => fn(name, params));
}
