import { Component } from "react";
import { connect } from "react-redux";
import { go } from "logic/actions/navigation";
import { QR_INSTRUCTIONS, VALIDATE_PHONE } from "logic/enums/pages";
import { withTranslation } from "react-i18next";
import { resendCode } from "logic/actions/resendVerificationCode";
import WhitelabelCompanyLogo from "Components/common/WhitelabelCompanyLogo/WhitelabelCompanyLogo";

import * as S from "./PageSentToMobile.styles";
import mockImg from "../../../../assets/images/phone_mock.png";
import DaonErrorBox from "Components/common/DaonErrorBox";
import ButtonRestartApp from "Components/common/ButtonRestartApp";
import { logEvent, sms_retry } from "logic/eventLogger";
import placeholdergif from "assets/gifs/telefonoynubes.gif";

export class PageSentToMobile extends Component {
  constructor(props) {
    super(props);
    this.goBack.bind(this);
  }

  componentDidMount() {
    document.title = `${this.props.t("PageValidatePhone.header")} | Onboarding`;
  }

  goBack = () => {
    this.props.abortFunction();
    logEvent(sms_retry);
    this.props.resendCode();
    this.props.go(VALIDATE_PHONE);
  };

  render() {
    const t = this.props.t;
    return (
      <S.Wrapper>
        <span></span>
        <S.LogoDiv>
          <WhitelabelCompanyLogo logoSize={80} />
        </S.LogoDiv>
        {this.props.error ? (
          <S.Content>
            <h2>{t("PagePhoneStatus.error_title")}</h2>
            <S.ErrorWrapper>
              <DaonErrorBox message={this.props.error} whiteBackground />
              <ButtonRestartApp />
            </S.ErrorWrapper>
          </S.Content>
        ) : this.props.redirectedFrom === QR_INSTRUCTIONS ? (
          <S.Content>
            <h2>{t("PageSentToMobile.title_sms_new")}</h2>
            <S.Paragraph>{t("PageSentToMobile.message_redirected")}</S.Paragraph>
          </S.Content>
        ) : this.props.shouldPoll ? (
          <S.Content>
            <h2>{t("PageSentToMobile.title_sms_new")}</h2>
            {!this.props.verified && (
              <S.Paragraph>
                {t("PageSentToMobile.message1")}
                <br />
                {t("PageSentToMobile.message2")}
                <br />
                <br />
                <br />
              </S.Paragraph>
            )}
            {this.props.tryAgainShown && !this.props.verified && (
              <S.TryAgainParagraph>
                {t("PageSentToMobile.havent_received")}
                <S.LinkText
                  className="button-link"
                  onClick={this.goBack}
                  id="try_again"
                >
                  {t("PageSentToMobile.try_again")}
                </S.LinkText>
              </S.TryAgainParagraph>
            )}
            {this.props.verified && (
              <S.Paragraph>
                {t("PageSentToMobile.message_phone_verified")}
                <br />
                {t("PageSentToMobile.message_redirected")}
              </S.Paragraph>
            )}
          </S.Content>
        ) : (
          <S.Content>
            <h2>{t("PageSentToMobile.title_sms_mobile")}</h2>
            {this.props.tryAgainShown && (
              <S.Paragraph>
                {t("PageSentToMobile.havent_received")}{" "}
                <S.LinkText
                  className="button-link"
                  onClick={this.goBack}
                  id="try_again"
                >
                  {t("PageSentToMobile.try_again")}
                </S.LinkText>
              </S.Paragraph>
            )}
          </S.Content>
        )}
        <S.PhoneImg src={placeholdergif} alt="Mobile Phone" height="420" />
      </S.Wrapper>
    );
  }
}
const componentWithTranslation = withTranslation()(PageSentToMobile);
export default connect(
  (state) => {
    return {
      ...state.application,
      ...state.phoneValidation,
    };
  },
  { go, resendCode }
)(componentWithTranslation);
