import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageContent from "../../PageContent";
import { isSmsLink } from "logic/tenant";
import {
  setApplicationId,
  switchedToMobilePhone,
} from "logic/actions/application";
import { useDispatch, connect, useSelector } from "react-redux";
import DaonErrorBox from "Components/common/DaonErrorBox";
import ButtonRestartApp from "../../common/ButtonRestartApp";
import {
  getStart,
  applicationUpdateParams,
} from "logic/actions/api/application";
import { ommitNFCStep } from "logic/steps";
import { selectSupportedLanguages } from "logic/reducers/configuration";
import PageIntroScreen from "Components/PageIntroScreen";
import HandleComposite from "../HandleComposite";
import { NFC_NOT_SUPPORTED, NFC_WRONG_IOS_VERSION } from "logic/enums/pages";
import PageNFCStatus from "Components/modules/NFC/PageNFCStatus";
import {
  NFC_STATUS_NOT_SUPPORTED,
  NFC_STATUS_WRONG_IOS_VERSION,
} from "logic/enums/nfc";
/*global MobileFormHandler*/
import {
  checkBrowserCompatibility,
  checkIOSNotSafariVersion,
  isAndroid,
  isFirefox,
  isMacOS,
  isOpera,
  isMobile, isMiUi, isChrome,
} from "logic/deviceType";
import { ANDROID, IOS } from "logic/enums/composite";
import { handleCompositeRestart } from "logic/reducers/handleCompositeRestart";
import { NONE } from "logic/enums/verification";
import { IOS_SUPPORTED_NOT_SAFARI_VERSION } from "logic/enums/iosVersions";

function SwitchToMobile({ children }) {
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [code, setCode] = useState(null);
  const [type, setType] = useState(null);
  const [shouldShowRestartButton, setShouldShowRestartButton] = useState(true);
  const supportedLanguages = useSelector(selectSupportedLanguages);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [ishandleCompositeFinished, setIsHandleCompositeFinished] =
    useState(false);

  const parameters = new URLSearchParams(window.location.search);
  const applicationId = parameters.get("accountApplicationId");
  const languageTag = parameters.get("languageTag");
  const platform = parameters.get("platform");

  const switchToMobile = useSelector(
    (state) =>
      state?.configuration?.extraConfig?.steps?.phoneNumber?.options
        ?.switchToMobile
  );
  const allowDesktopOnSwitch = useSelector(
    (state) =>
      state?.configuration?.extraConfig?.steps?.phoneNumber?.options
        .allowDesktopOnSwitch
  );

  useEffect(() => {
    if (!platform) {
      ommitNFCStep();
    }
    if (languageTag) {
      i18n.changeLanguage(languageTag);
    }
    if (applicationId) {
      setApplicationId(applicationId)(dispatch);
      // if (!isBrowserCompatible()) return;
      if (!isIOSNotSafariVersionSupported()) return;
      if (parameters.get("code")) {
        if (!isMobile(navigator.userAgent) && allowDesktopOnSwitch !== true) {
          setError(t("Common.app_continue_wrong_platform"));
        }
        setCode(parameters.get("code"));
        setType(parameters.get("type"));

        switchedToMobilePhone(true)(dispatch);
      } else {
        switchedToMobilePhone(false)(dispatch);
        setLoaded(true);
      }
    }
  }, []);

  const setErrorPage = (page) => {
    setError(page);
  };
  function isBrowserCompatible() {
    if (
      (!checkBrowserCompatibility(navigator.userAgent) &&
        platform !== IOS &&
        platform !== ANDROID) ||
      (!isMobile(navigator.userAgent) &&
        switchToMobile === NONE &&
        (isFirefox(navigator.userAgent) || isOpera(navigator.userAgent)))
    ) {
      let extension;
      if (isMobile(navigator.userAgent)) {
        extension = isAndroid(navigator.userAgent)
          ? "Google Chrome"
          : "Safari/Google Chrome";
      } else {
        extension = isMacOS(navigator.userAgent)
          ? "Safari/Google Chrome"
          : "Google Chrome";
      }
      setShouldShowRestartButton(false);
      setError(`${t("Common.app_continue_wrong_browser")} ${extension}`);
      return false;
    }
    return true;
  }

  const isIOSNotSafariVersionSupported = () => {
    const iOSNotSafariVersion = checkIOSNotSafariVersion(navigator.userAgent);
    if (
      iOSNotSafariVersion &&
      iOSNotSafariVersion < IOS_SUPPORTED_NOT_SAFARI_VERSION
    ) {
      setShouldShowRestartButton(false);
      setError(t("Common.app_continue_wrong_ios_version"));
      return false;
    }
    return true;
  };

  async function verifyLink() {
    try {
      await getStart(
        code,
        type
      )(dispatch).then(() => {
        applicationUpdateParams()(dispatch);
      });
      setLoaded(true);
    } catch (err) {
      if (err.response.data.message.includes("Verification already verified")) {
        setError(t("Common.app_continue_error_link_expired"));
      } else {
        setError(t("Common.app_continue_error_switching_to_mobile"));
      }
    }
  }
  if (code && !ishandleCompositeFinished) {
    return (
      <>
        <HandleComposite
          setIsHandleCompositeFinished={setIsHandleCompositeFinished}
          setErrorPage={setErrorPage}
        />
      </>
    );
  }

  if (loaded && !error) return children;

  if (error === NFC_NOT_SUPPORTED)
    return <PageNFCStatus type={NFC_STATUS_NOT_SUPPORTED} />;

  if (error === NFC_WRONG_IOS_VERSION)
    return <PageNFCStatus type={NFC_STATUS_WRONG_IOS_VERSION} />;

  if (error)
    return (
      <PageContent
        justifyContent="center"
        showBack={false}
        title={t("PageContinue.title")}
      >
        <h2>{t("PageContinue.error")}</h2>
        <DaonErrorBox message={error} whiteBackground={true} />
        {shouldShowRestartButton && (
          <ButtonRestartApp
            onClick={platform ? handleCompositeRestart : null}
          />
        )}
      </PageContent>
    );
  if (ishandleCompositeFinished) {
    return <PageIntroScreen isSwitchingToMobile={code} onClick={verifyLink} />;
  }

  return null;
}

export default connect((state) => state)(
  SwitchToMobile,
  setApplicationId,
  isSmsLink,
  applicationUpdateParams,
  getStart
);
