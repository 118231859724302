import firebase from 'firebase/compat/app';
import "firebase/analytics";

import { subscribeLoggingFunction } from "../eventLogger";
import { getTenantNameFromHost } from "../tenant";

export function setup(config) {
  firebase.initializeApp(config);

  const analytics = firebase.analytics();
  analytics.setUserProperties({
    tenant: getTenantNameFromHost(),
  });

  subscribeLoggingFunction(analytics.logEvent);
}
