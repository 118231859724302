import styled from "styled-components";

export const DaonWrap = styled.div`
  width: 100%;
  max-width: 600px;
  max-height: 400px;
  margin: 0 auto;
  margin-bottom: 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    margin-top: 2rem;
  }

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    &.face-preview-wrapper {
      max-height: 40vh !important;

      .image-preview {
        width: 100% !important;
      }
    }
  }
`;

export const EnlargeBox = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 140px;
  width: fit-content;
  margin: 10px;
  margin-bottom: 0px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1em;
  user-select: none;

  span {
    margin-left: 0.5rem;
    text-align: center;
  }

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    font-size: 0.7em;
    min-width: 58px;
    padding: 5px 0;

    span {
      display: none;
    }
  }
`;

export const EnlargeBoxWrapper = styled.div`
  position: absolute;
  text-align: center;
  bottom: 1rem;

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    bottom: 0.7rem;
  }
`;

export const DaonPreviewImage = styled.img`
  max-width: 90%;
  height: auto;
  max-height: 360px;
  border-radius: 4px;
  margin: 0 auto;
  object-fit: cover;

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    max-width: 100%;
  }
`;
