import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as S from "./PageGDPR.styles";
import PageContent from "Components/PageContent";
import { nextPage } from "logic/actions/navigation";
import { useDispatch, useSelector } from "react-redux";
import * as Api from "logic/api";
import { logEvent, step_completed } from "logic/eventLogger";
import { useTranslation } from "react-i18next";
import { userConsentRequest } from "./consentRequest";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import DaonButton from "Components/common/DaonButton";
import DaonCheckbox from "Components/common/DaonCheckbox";
import DaonErrorBox from "Components/common/DaonErrorBox";
import { ReactComponent as ArrowRight } from "../../assets/icons/16-24/ArrowRight.svg";
import { isMobile } from "logic/deviceType";
import { selectIsCompositeFlow } from "logic/reducers/composite";
import { ThemeContext } from "styled-components";
import { response } from "msw";
import { cancelConsent } from "logic/api/application";
export default function PageGDPR() {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  let [isDisabled, setIsDisabled] = useState(true);
  const isCompositeFlow = useSelector(selectIsCompositeFlow);

  useEffect(() => {
    document.title = `${t("PageGDPR.title")} | Onboarding`;
    if (!getTimePassed("gdpr")) startEventTimer("gdpr");
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);
  }, []);

  function toggleCheckBox(e) {
    setChecked(e.target.checked);
  }

  function next() {
    if (checked) {
      nextPage()(dispatch);
    }
  }

  function cancelConset() {
    cancelConsent()
      .then((response) => {
        location.replace(response.data.redirectUrl);
      })
      .catch(() => {
        setHasError(true);
      });
  }
  function acceptGDPR() {
    if (!isInProgress) {
      setIsInProgress(true);
      Api.sendGDPRConsent(userConsentRequest)
        .then(() => {
          const stepName = "gdpr";
          const timePassed = getTimePassed(stepName);
          logEvent(step_completed, {
            stepNameCompleted: stepName,
            timePassed,
          });
          next();
        })
        .catch(() => {
          setHasError(true);
          setIsInProgress(false);
        });
    }
  }

  function toggleTermsOfUse() {
    setShowTermsOfUse(!showTermsOfUse);
  }

  function togglePrivacyPolicy() {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  }

  function navigateToBiometricPolicy() {
    const target = isCompositeFlow ? '_self' : '_blank';
    window.open('https://www.daon.com/biometric-privacy-policy/', target)
  }

  return (
    <PageContent title={t("PageGDPR.title")} instructions={true}>
      <S.Content>
        <div>
          <h2>{t("PageGDPR.title")}</h2>
          <S.CheckWrapper>
            <DaonCheckbox checked={checked} onChange={toggleCheckBox} />
            <p style={{ display: "inline-block", width: !isMobile(navigator.userAgent) ? "80%" : "null"}}>
              {t("PageGDPR.consent_statement")}
            </p>
          </S.CheckWrapper>
          {isMobile(navigator.userAgent) ? (
            <S.CardListItem>
              <S.MobileButton onClick={toggleTermsOfUse} id="terms_of_use_btn">
                <S.MobileLinkText upper data-toggle="modal">
                  {t("PageGDPR.terms_of_use")}
                </S.MobileLinkText>
                <ArrowRight
                  style={{ float: "right", margin: "4px" }}
                  fill={theme.mainColor}
                />
              </S.MobileButton>
              <S.MobileButton onClick={togglePrivacyPolicy} id="privacy_btn">
                <S.MobileLinkText data-toggle="modal">
                  {t("PageGDPR.view_privacy_policy")}
                </S.MobileLinkText>
                <ArrowRight
                  style={{ float: "right", margin: "4px" }}
                  fill={theme.mainColor}
                />
              </S.MobileButton>
              <S.MobileButton onClick={navigateToBiometricPolicy} id="biometric_policy_btn">
                <S.MobileLinkText data-toggle="modal">
                  {t("PageGDPR.view_biometric_policy")}
                </S.MobileLinkText>
                <ArrowRight
                  style={{ float: "right", margin: "4px" }}
                  fill={theme.mainColor}
                />
              </S.MobileButton>
            </S.CardListItem>
          ) : (
            <S.CardListItem>
              <S.TermPolicyButton
                upper
                onClick={toggleTermsOfUse}
                data-toggle="modal"
                id="terms_of_use_link"
              >
                {t("PageGDPR.terms_of_use")}
              </S.TermPolicyButton>
              <S.TermPolicyButton
                onClick={togglePrivacyPolicy}
                data-toggle="modal"
                id="privacy_link"
              >
                {t("PageGDPR.view_privacy_policy")}
              </S.TermPolicyButton>
              <S.TermPolicyButton
                onClick={navigateToBiometricPolicy}
                id="biometric_link"
              >
                {t("PageGDPR.view_biometric_policy")}
              </S.TermPolicyButton>
            </S.CardListItem>
          )}
        </div>
        <S.BoxDiv>
          {hasError && (
            <S.ErrorWrap>
              <DaonErrorBox
                whiteBackground
                message={t("Common.error")}
              ></DaonErrorBox>
            </S.ErrorWrap>
          )}
          <DaonButton
            onClick={acceptGDPR}
            disabled={!checked || isInProgress}
            type="button"
            id="agree"
          >
            {t("PageGDPR.i_agree")}
          </DaonButton>
          {!isCompositeFlow && (
            <DaonButton
              onClick={cancelConset}
              disabled={isDisabled}
              type="button"
              id="cancelConcent"
            >
              {t("PageGDPR.cancel_consent")}
            </DaonButton>
          )}
        </S.BoxDiv>
      </S.Content>

      <Modal show={showTermsOfUse} onHide={toggleTermsOfUse}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>
              <strong>{t("PageGDPR.terms_of_use")}</strong>
            </p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{
              __html: t("PageGDPR.terms_of_use_content"),
            }}
          ></div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={showPrivacyPolicy} onHide={togglePrivacyPolicy}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>
              <strong>{t("PageGDPR.privacy_policy")}</strong>
            </p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{
              __html: t("PageGDPR.privacy_policy_content"),
            }}
          ></div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </PageContent>
  );
}
