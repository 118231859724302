import {
  MULTI_CAPTURE_CONFIG,
  CAMERA_RESOLUTION,
  COUNTRY_SELECTOR,
  IS_SINGLE_PAGED,
  COUNTRY_NUM,
  VERTICAL_DOCUMENTS_PRESENT,
} from "../actions/documentCaptureConfiguration";

const defaultState = {
  numOfFrames: undefined,
  initialCaptureDelay: undefined,
  captureInterval: undefined,
  width: undefined,
  height: undefined,
  dialCountryNum: null,
  docCountryOptions: {
    id: undefined,
    idVertical: undefined,
    dl: undefined,
    dlVertical: undefined,
    singlePaged: false,
  },
  verticalDocumentsPresent: false,
};

export function documentCaptureConfiguration(state = defaultState, action) {
  switch (action.type) {
    case IS_SINGLE_PAGED: {
      return {
        ...state,
        singlePaged: action.payload,
      };
    }
    case VERTICAL_DOCUMENTS_PRESENT: {
      return {
        ...state,
        verticalDocumentsPresent: action.payload,
      };
    }
    case COUNTRY_SELECTOR: {
      return {
        ...state,
        docCountryOptions: action.payload,
      };
    }
    case COUNTRY_NUM: {
      return {
        ...state,
        dialCountryNum: action.payload,
      };
    }
    case CAMERA_RESOLUTION: {
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
      };
    }

    case MULTI_CAPTURE_CONFIG: {
      return {
        ...state,
        numOfFrames: action.payload.numOfFrames,
        captureInterval: action.payload.captureInterval,
        initialCaptureDelay: action.payload.initialCaptureDelay,
      };
    }
    default:
      return state;
  }
}

export const selectDialCountryNum = (state) => {
  return state.documentCaptureConfiguration?.dialCountryNum;
};
