import { setup as analyticsSetup } from "../integrations/firebaseAnalytics";
import { setup as sentrySetup } from "../integrations/sentry";
import { setup as datadogSetup } from "../integrations/datadog";
import { axiosInstance } from "./axiosInstance";
import {
  GDPR,
  PHONE_STATUS,
  QR_INSTRUCTIONS,
  VALIDATE_PHONE,
} from "logic/enums/pages";
import { NONE, QR, SMS } from "logic/enums/verification";
import {
  steps,
  allSteps,
  initSteps,
  handleValidationPages,
  omitConsentStep,
  setPhoneLabel,
} from "logic/steps";
import { isMobile } from "logic/deviceType";
import { PHONE_STEP } from "logic/enums/steps";

/**
 * GET https://{host}/{tenant}/config
 * @returns {Promise}
 * @example
 * request: N/A
 * response:
 * {
 *      "faceQualityHostname":"https://engdobs-facequality-qc.identityx-cloud.com",
 *      "documentQualityHostname":"https://engdobs-docquality-qc.identityx-cloud.com",
 *      "livenessPolicyName":"SampleWebLiveness",
 *      "evaluationPolicyName":"WebEvaluationPolicy",
 *      "gdprServiceHostname":"https://dobs-gdpr.identityx-cloud.com",
 *      "extraConfig":{...}
 * }
 */
function handleVerification(verificationType, switchToMobile) {
  if (!verificationType) return;
  if (
    verificationType.includes(QR) &&
    !isMobile(navigator.userAgent) &&
    switchToMobile !== NONE
  ) {
    handleValidationPages({
      start: 0,
      validationPages: [QR_INSTRUCTIONS, PHONE_STATUS],
    });
  }
  if (
    !verificationType.includes(SMS) ||
    (isMobile(navigator.userAgent) && verificationType.includes(QR)) ||
    (switchToMobile === NONE && verificationType.includes(QR))
  ) {
    const start = steps
      .find((step) => step.name === PHONE_STEP)
      .pages.indexOf(VALIDATE_PHONE);
    handleValidationPages({
      start: start,
      deleteCount: 2,
    });
  }
}

function handleStepShowing(verificationType, switchToMobile) {
  if (
    (isMobile(navigator.userAgent) || switchToMobile === NONE) &&
    verificationType?.includes(QR)
  ) {
    steps.find((step) => step.name === PHONE_STEP).hide = true;
  }
}
export function getConfiguration() {
  return axiosInstance.get(`/config`).then((response) => {
    const { extraConfig } = response.data;

    if (extraConfig) {
      if (process.env.NODE_ENV === "production") {
        if (extraConfig.firebase) {
          analyticsSetup(extraConfig.firebase);
        }
        if (extraConfig.sentry) {
          sentrySetup(extraConfig.sentry);
        }
        if (extraConfig.datadog) {
          datadogSetup(extraConfig.datadog);
        }
      }
      if (extraConfig.steps && !extraConfig.steps.data) {
        steps.splice(0, steps.length);
        allSteps.forEach((localStep) => {
          if (extraConfig.steps[localStep.name]) {
            steps.push({
              ...localStep,
              order: extraConfig.steps[localStep.name].order,
            });
          }
        });
        if (steps[0]?.order)
          steps.sort((step1, step2) => step1.order - step2.order);
      } else {
        omitConsentStep();
        initSteps();
      }
      setPhoneLabel(extraConfig?.steps?.phoneNumber?.options?.switchToMobile);

      //TODO: remove when steps configuration is completed
      if (extraConfig.featureFlags?.shouldSkipGDPR?.toString() === "true") {
        for (let step = 0; step < steps.length; step++) {
          let gdprIndex = steps[step].pages.indexOf(GDPR);
          if (gdprIndex >= 0) {
            steps.splice(step, 1);
            break;
          }
        }
      }
      handleVerification(
        extraConfig?.steps?.phoneNumber?.options?.mobileVerificationType,
        extraConfig?.steps?.phoneNumber?.options?.switchToMobile
      );
      handleStepShowing(
        extraConfig?.steps?.phoneNumber?.options?.mobileVerificationType,
        extraConfig?.steps?.phoneNumber?.options?.switchToMobile
      );
    }
    return response;
  });
}
