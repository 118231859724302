import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { nextPage, previous } from "logic/actions/navigation";
import PageContent from "Components/PageContent";
import * as S from "./PageQRScan.styles";
import { SMS } from "logic/enums/verification";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { applicationQRValidate } from "logic/actions/api/application";
import {
  logEvent,
  page_open,
  phone,
  qr_code_scan_retry,
  qr_sms_verification_fallback,
  step_completed,
} from "logic/eventLogger";
import { getTimePassed, qr_code } from "logic/stepTimers";
import { QR_SCAN } from "logic/enums/pages";
import { selectIsQrInstructionsSkipped } from "logic/reducers/configuration";

export const PageQRScan = (props) => {
  const mobileVerificationType =
    props.steps.phoneNumber.options.mobileVerificationType;

  const shouldSkipQrInstructions = useSelector(selectIsQrInstructionsSkipped);

  const onBackButtonClick = async () => {
    logEvent(qr_code_scan_retry);
    props.abortFunction();
    await applicationQRValidate(props.application.messageToUser)(dispatch);
    props.previous();
  };

  const goToSMSValidation = async () => {
    logEvent(qr_sms_verification_fallback);
    props.nextPage();
    await applicationQRValidate(props.application.messageToUser)(dispatch);
  };

  useEffect(() => {
    logEvent(page_open, { name: QR_SCAN, pageName: QR_SCAN });
    const timePassed = getTimePassed(qr_code);
    logEvent(step_completed, {
      stepNameCompleted: phone,
      timePassed,
    });
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <PageContent
      isQrScanStep
      toggleLoading={false}
      showBack={!shouldSkipQrInstructions}
      backButtonClick={onBackButtonClick}
      title={t("PageQRInstructions.title")}
      backgroundSideDiv={"white"}
    >
      <S.Content>
        <S.Heading>{t("PageQRScan.heading")}</S.Heading>
        <S.QRWrapper>
          {props?.application.messageToUser && (
            <QRCode
              id={"qrCode"}
              value={props.application.messageToUser}
              renderAs="svg"
              size={250}
            />
          )}
        </S.QRWrapper>
        <S.Paragraph>{t("PageQRScan.description")}</S.Paragraph>
        {mobileVerificationType?.includes(SMS) && (
          <S.SMSWrapper>
            <S.Paragraph>{t("PageQRScan.help")}</S.Paragraph>
            <S.SMSLink id="smsLink" onClick={goToSMSValidation}>
              {t("PageQRScan.sms_link")}
            </S.SMSLink>
          </S.SMSWrapper>
        )}
      </S.Content>
    </PageContent>
  );
};

export default connect(
  (state) => {
    return {
      steps: state.configuration.extraConfig.steps,
      application: state.application,
    };
  },
  {
    nextPage,
    previous,
    applicationQRValidate,
  }
)(PageQRScan);
