import {
  NAVIGATION_GO,
  NAVIGATION_NEXT,
  NAVIGATION_NEXT_STEP,
  NAVIGATION_PREVIOUS,
} from "logic/actions/navigation";
import {
  previous as navigationPrevious,
  nextStep,
  nextPage,
  steps,
} from "../steps";
import {
  RETRY as LIVENESS_RETRY,
  FAILED_ATTEMPT,
} from "../actions/livenessTest";
import {
  DOCUMENT_CAPTURE_CONFIRM,
  DOCUMENT_CAPTURE_EDIT_DOCUMENT,
  FACE_CAPTURE_CONFIRM,
  FACE_CAPTURE,
  LIVENESS_TEST_RETRY,
  DOCUMENT_CAPTURE_INSTRUCTIONS,
  VERIFY_IDENTITY,
} from "../enums/pages";
import {
  RETRY as DOCUMENT_CAPTURE_RETRY,
  PREVIEW as DOCUMENT_CAPTURE_PREVIEW,
  RETRY_PROCESSING as DOCUMENT_CAPTURE_RETRY_PROCESSING,
} from "../actions/documentCapture";
import {
  RETRY as FACE_CAPTURE_RETRY,
  PREVIEW as FACE_CAPTURE_PREVIEW,
} from "../actions/faceCapture";
import { IDRND_FACE } from "logic/enums/livenessTestType";

export const navigationMiddleware = (store) => (nextMiddleware) => (action) => {
  let state;
  switch (action.type) {
    case NAVIGATION_NEXT:
      state = store.getState();
      store.dispatch({
        type: NAVIGATION_GO,
        payload: nextPage(state.navigation.page),
      });
      return;
    case NAVIGATION_NEXT_STEP:
      state = store.getState();
      store.dispatch({
        type: NAVIGATION_GO,
        payload: nextStep(state.navigation.page),
      });
      return;
    case NAVIGATION_PREVIOUS:
      state = store.getState();
      store.dispatch({
        type: NAVIGATION_GO,
        payload:
          state.navigation.page === DOCUMENT_CAPTURE_INSTRUCTIONS
            ? VERIFY_IDENTITY
            : navigationPrevious(state.navigation.page),
      });
      return;
    case DOCUMENT_CAPTURE_PREVIEW:
      store.dispatch({
        type: NAVIGATION_GO,
        payload: DOCUMENT_CAPTURE_CONFIRM,
      });
      break;
    case DOCUMENT_CAPTURE_EDIT_DOCUMENT: 
      store.dispatch({
        type: NAVIGATION_GO,
        payload: DOCUMENT_CAPTURE_EDIT_DOCUMENT,
      });
    
    case DOCUMENT_CAPTURE_RETRY:
      store.dispatch({
        type: NAVIGATION_GO,
        payload: DOCUMENT_CAPTURE_INSTRUCTIONS,
      });
      break;
    case DOCUMENT_CAPTURE_RETRY_PROCESSING:
      store.dispatch({
        type: NAVIGATION_GO,
        payload: DOCUMENT_CAPTURE_INSTRUCTIONS,
      });
      break;
    case FACE_CAPTURE_PREVIEW:
      nextMiddleware(action);
      store.dispatch({
        type: NAVIGATION_GO,
        payload: FACE_CAPTURE_CONFIRM,
      });
      return;
    case FACE_CAPTURE_RETRY:
      store.dispatch({
        type: NAVIGATION_GO,
        payload: FACE_CAPTURE,
      });
      break;
    case LIVENESS_RETRY:
      store.dispatch({
        type: NAVIGATION_GO,
        payload: steps[steps.length - 2][0],
      });
      break;
    case FAILED_ATTEMPT:
      nextMiddleware(action);
      state = store.getState();
      if (
        state.livenessTest &&
        state.livenessTest.livenessTestData &&
        state.livenessTest.livenessTestData.items[0].type !== IDRND_FACE
      ) {
        store.dispatch({
          type: NAVIGATION_GO,
          payload: LIVENESS_TEST_RETRY,
        });
      }
      return;
    default:
  }
  nextMiddleware(action);
};
