import * as S from "./DaonCheckbox.styles";

export default function DaonCheckbox({ checked, onChange }) {
  return (
    <div>
      <S.Checkbox
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id="daon-checkbox"
      />
      <label htmlFor="daon-checkbox" id="checkbox_label"></label>
    </div>
  );
}
