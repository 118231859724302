import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";

export const SquaredIcon = styled.div`
  height: 28px;
  width: 28px;
  opacity: 0.7;
  border-radius: 3px;
  background-color: #364045;
  position: absolute;
  left: 80%;
  bottom: 20%;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.previewPageText};
  font-size: 1.5em;
  font-weight: 600;
  margin: 2em 0;
  text-align: center;

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    text-align: start;
    margin: 1em 0;
  }
`;

export const WrapperDiv = styled.div`
  position: relative;
  max-width: 90vw;

  #wrapperDiv {
    ${(props) => {
      return props.isNative ? "transform: scaleX(-1)" : "";
    }}
  }

  & .loaded-image-container {
    pointer-events: none;
    height: 85%;
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }

  & .rotate-mobile-native {
    transform: rotate(90deg);
  }
`;

export const MessageParagraph = styled.div`
  text-align: center;
  color: ${(props) => props.theme.previewPageText};
  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    text-align: start;
    margin: 1em 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    flex-direction: column;
    margin-bottom: 1.25rem;
  }
`;

export const StyledTextField = styled(TextField)`
  margin: 1em 0;
  & .MuiInputBase-input {
    color: white;
    font-size: 20px;border: 1px solid white;
    border-radius: 6%;
  }
`;

export const StyledDateField = styled(DatePicker)`
  margin: 1em 0;
  touch-action: manipulation;
  & .MuiInputBase-input {
    color: white;
    font-size: 20px;
    border: 1px solid white;
    border-radius: 6%;
  }
`;
export const ConfirmInputsDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const CalendarDiv = styled.div`
  width: 16ch;
`