export const applicationPostQRMock = {
  id: "409f1531-e361-4619-88fd-3dc04d02d8bb",
  user: {
    userId: "0559ee6b-1797-4dc5-aca8-f984901ce971",
    idxFriendlyUserId: "bitdiob2",
    primaryPhone: null,
    href: "https://dobsdev-oidc-dobs.identityx-build.com/drag3338/IdentityXServices/rest/v1/users/QTAzL4QoFEWW_zciWY7sIVKoog",
  },
  interruptIDPAuth: false,
  verificationCode: 876543,
  languageTag: "en-US",
  countryCode: "rs",
  cancelled: false,
  link: "https://localhost:4000?applicationId=409f1531-e361-4619-88fd-3dco4d02d8bb&code=876543&verificationType=QR",
};
