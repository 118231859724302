import {
  NETWORK_WARNINGS_INACTIVE,
  NETWORK_WARNINGS_ACTIVE,
} from "../actions/api";

const defaultState = {
  isInProgress: false,
};

export function apiFetch(state = defaultState, action) {
  switch (action.type) {
    case NETWORK_WARNINGS_ACTIVE:
      return { isInProgress: true };
    case NETWORK_WARNINGS_INACTIVE:
      return { isInProgress: false };
    default:
      return state;
  }
}
