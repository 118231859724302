 import styled from 'styled-components'

export const InputField = styled.input`
    width: 52px;
    line-height: 1;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 8px;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    appearance: textfield;
    -webkit-appearance: textfield;
    border: 2px solid #DDE1E6;
    border-radius: 8px;

    @keyframes shake {
        0% { margin-left: 0; }
        25% { margin-left: -2px; }
        50% { margin-left: -4px; }
        100% { margin-left: 0; }
    }
    
    &.error {
      animation: shake 0.25s ease-in-out 0s 2;
      border: 2px solid #F16063;
    }

    @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
        width: 44px;
        padding: 6px;
        font-size: 24px;
        border-radius: 4px;
      }

    @media (max-width: ${props => props.theme.smallPhoneBreakpoint + 'px'}) {
        width: 42px;
        padding: 4px;
        font-size: 22px;
      }
`
