import { store } from "../../store";
import {
  PASSPORT,
  DOCUMENT_NUMBER,
  REFERENCE_NUMBER,
  DOCUMENT_UNIQUE_NUMBER,
  DRIVERS_LICENCE_CODE,
  DRIVERS_LICENCE_602,
  DRIVERS_LICENCE_605,
  DRIVERS_LICENCE_608,
  DRIVERS_LICENCE_611,
  DRIVERS_LICENCE_614,
  DRIVERS_LICENCE_620,
  DRIVERS_LICENCE_623,
  DOCUMENT_DATE_OF_EXPIRY,
  DOCUMENT_DATE_OF_BIRTH,
} from "../../enums/document";
import { axiosInstance } from "logic/api";
import { getDocumentData } from "logic/documentData";
import { writeToLocalCache, CACHE_DOCUMENT_ID } from "logic/localCacheAdapter";
import {
  setDocumentExpiryDate,
  setDocumentNumber,
  setDocumentDateOfBirth,
} from "logic/actions/documentCapture";

const actionPrefix = "API_";
export const SAVE_DOCUMENT = actionPrefix + "SAVE_DOCUMENT";

/**
 * POST /{tenant}/DigitalOnBoardingServices/rest/v1/users/{userId}/idchecks/{idCheckId}/documents
 */
export const auDocumentsSave =
  ({
    base64CroppedFirstPage,
    base64CroppedSecondPage,
    documentType,
    uncroppedFirstPage,
    uncroppedSecondPage,
    accountApplicationId,
    country,
  }) =>
  (dispatch) => {
    const documentStore = store.getState().documentCaptureConfiguration;
    const isDocumentSinglePaged = documentStore.singlePaged;
    const compositeStore = store.getState().composite;
    const nfcChallenge = compositeStore?.nfcTestData?.challenge;
    const documentId = store.getState().documentCapture.documentId;
    let images = [];

    if (base64CroppedFirstPage) {
      images = [
        ...images,
        getDocumentData("FRONT", "PROCESSED", base64CroppedFirstPage),
      ];
    }

    if (
      documentType !== PASSPORT &&
      isDocumentSinglePaged !== true &&
      base64CroppedSecondPage
    ) {
      images = [
        ...images,
        getDocumentData("BACK", "PROCESSED", base64CroppedSecondPage),
      ];
    }

    if (uncroppedFirstPage) {
      images = [
        ...images,
        getDocumentData("FRONT", "UNPROCESSED", uncroppedFirstPage),
      ];
    }

    if (uncroppedSecondPage) {
      images = [
        ...images,
        getDocumentData("BACK", "UNPROCESSED", uncroppedSecondPage),
      ];
    }
    let nfcData;
    const request = {
      processingType: "ID",
      captureType: "BBC",
      captured: new Date(),
      // country,
      // accountApplicationId,
      // documentType,
      captureFormat: documentType === PASSPORT ? "PASSPORT" : "ID1",
      clientCapture: {
        images,
        nfcData,
      },
    };

    const { application, configuration, documentCapture } = store.getState();
    const isAsync =
      configuration.extraConfig.featureFlags.asyncDocumentProcessing ===
        "true" ||
      configuration.extraConfig.featureFlags.asyncDocumentProcessing === true;
    const policyName = (documentCapture.document.documentPolicy || configuration.extraConfig.steps.document.options.documentPolicy) ?? false;
    const policyParam = policyName ? `&documentPolicyName=${policyName}` : '';

    let documentID = sessionStorage.getItem("SESSION_DATA_CACHE_DOCUMENT_ID");
    let promise = axiosInstance.post(
      `/DigitalOnBoardingServices/rest/v1/users/${
        application.user.id
      }/idchecks/${application.user.idCheck.id}/documents${
        documentID ? `/${documentID.replace(/['"]+/g, "")}` : ""
      }?isAsync=${isAsync}&accountApplicationId=${accountApplicationId}&country=${country}&documentType=${documentType}${policyParam}`,
      request
    );
    //not sure about this one ?
    const ocrResult = {};

    const extractValues = (obj) => {
      for (let key in obj) {
          const item = obj[key];
          if (
              item.name === DOCUMENT_NUMBER ||
              item.name === REFERENCE_NUMBER ||
              item.name === DOCUMENT_UNIQUE_NUMBER ||
              item.name === DOCUMENT_DATE_OF_EXPIRY ||
              item.name === DOCUMENT_DATE_OF_BIRTH ||
              item.name === DRIVERS_LICENCE_CODE ||
              item.name === DRIVERS_LICENCE_602 ||
              item.name === DRIVERS_LICENCE_605 ||
              item.name === DRIVERS_LICENCE_608 ||
              item.name === DRIVERS_LICENCE_611 ||
              item.name === DRIVERS_LICENCE_614 ||
              item.name === DRIVERS_LICENCE_620 ||
              item.name === DRIVERS_LICENCE_623
          ) {
              const keyName = item.name.replace(/\s/g, "");
              ocrResult[keyName] = item.value;
          }
      }
  
      return ocrResult;
  }
    const extractPathFromUrl = (urlString) => {
      try {
        const url = new URL(urlString);
        let pathSegments = url.pathname.split("/").slice(2); // split the pathname into segments and remove the first one
        return "/" + pathSegments.join("/") + url.search;
      } catch (error) {
        console.error("Invalid URL: ", error);
        return null;
      }
    }
    
  
    return promise.then((response) => {
      writeToLocalCache(response.data.id, CACHE_DOCUMENT_ID);
      const resHolder = response;

      if (response.data.processingStatus === "FAILED") return response;

      let url = response.data.serverProcessed.href + "/ocrData/sensitiveData";

      return axiosInstance.get(extractPathFromUrl(url)).then((response) => {
        extractValues(response.data.visualZone);
        const ocrDocumentNumber = ocrResult.IdentityCardNumber || ocrResult.ReferenceNumber || ocrResult.UniqueNumber
        const ocrDocumentExpiry = ocrResult.DateOfExpiry || ocrResult.DLClassCodeCTo || ocrResult["602"] || ocrResult["605"] || ocrResult["608"] || ocrResult["611"] || ocrResult["614"] || ocrResult["620"] || ocrResult["623"]
        dispatch(setDocumentExpiryDate(ocrDocumentExpiry));
        dispatch(setDocumentNumber(ocrDocumentNumber));
        dispatch(setDocumentDateOfBirth(ocrResult.DateOfBirth));
        return resHolder;
    });
    });
  };
