import styled from "styled-components";

export const CompanyLogo = styled.div`
  & img {
    max-width: 200px;
    max-height: ${(props) =>
      props.logoSize ? props.logoSize + "px" : "200px"};
    width: auto;
    @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
      max-width: 80px;
    }
  }

  &.white-logo {
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }
`;
