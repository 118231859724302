export const SWITCHED_TO_MOBILE_PHONE = "SWITCHED_TO_MOBILE_PHONE";
export const SWITCHED_TO_MOBILE_TRANSACTION = "SWITCHED_TO_MOBILE_TRANSACTION";
export const API_SET_APPLICATION_DATA = "API_SET_APPLICATION_DATA";
export const SET_APPLICATION_ID = "SET_APPLICATION_ID";
export const SET_VALIDATE_PHONE_SESSION_DURATION =
  "  SET_VALIDATE_PHONE_SESSION_DURATION";

export const switchedToMobilePhone = (payload) => (dispatch) => {
  return dispatch({ type: SWITCHED_TO_MOBILE_PHONE, payload });
};

// export const switchedToMobileTransaction = (payload) => (dispatch) => {
//   return dispatch({ type: SWITCHED_TO_MOBILE_TRANSACTION, payload });
// };
export const setValidatePhoneSessionDuration = (payload) => (dispatch) => {
  dispatch({ type: SET_VALIDATE_PHONE_SESSION_DURATION, payload });
};

export const setApplicationData = (payload) => (dispatch) => {
  dispatch({ type: API_SET_APPLICATION_DATA, payload });
};

export const setApplicationId = (payload) => (dispatch) => {
  dispatch({ type: SET_APPLICATION_ID, payload });
};
