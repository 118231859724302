import styled from "styled-components/macro"

export const OrderedList = styled.ol`
    padding-inline-start:0px;
    list-style:none;
    margin:0px;
`
export const ListedItem = styled.li`
    display: flex;
`
export const RoundIcon = styled.div`
    line-height: 0;
    border-radius: 50%;
    position: relative;
    z-index: 30;
    margin-bottom: 28px;
    padding:12px;
    background-color: ${props => props.theme.textPrimary};
`;

export const IconWrap = styled.div`
    position: relative;
    z-index: 30;
    margin-right: 20px;
`
export const Paragraph = styled.p`
    margin: 0px;
    margin-top: 1px;
    color: ${props => props.color};
    font-size: ${props => props.size};
`

export const VerticalLine = styled.div`
    border-left: 2px solid;
    border-color: ${props => props.theme.textPrimary};
    height: 70%;
    top: 30%;
    left: 50%;
    margin-left: -1px;
    position: absolute;
    z-index: 20;
`

export const WrapperDiv = styled.div`
  margin: 3vh 0 3vh;
  @media (max-width: ${props => props.theme.smallPhoneBreakpoint + 'px'}) {
    margin: 3vh 0 1vh;
  }

  @media (max-width: ${(props) =>
    props.theme.phoneBreakpoint + "px"}) {
        margin: 3vh 0 1vh;

}
`

export const SpanDiv = styled.div`
width:50%;
display:inline-block;
height:100%;
position:absolute;
`
