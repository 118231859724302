export const applicationGetMock = {
  id: "f01b19dd-8ead-486c-a658-5f951ae284c9",
  created: "2022-04-28T10:30:22.695+00:00",
  user: {
    id: "QTAzZB5Oh36aT6GtUUKKsMh2tg",
    userId: "657606ce-31aa-4419-ade8-7f65444fd85a",
    idxFriendlyUserId: "fmdgsu0i",
    href: "https://engdobsqc-oidc-dobs.identityx-build.com/phonetesting1/IdentityXServices/rest/v1/users/QTAzZB5Oh36aT6GtUUKKsMh2tg",
    idCheck: { id: "Eyw8hYn4eOhlEsf82-xSYg" },
  },
  shouldProceedOnMobile: "true",
  interruptIDPAuth: false,
  languageTag: "en-US",
  countryCode: "ie",
  verifications: [
    {
      created: "2022-04-28T10:30:57.881+00:00",
      type: "QR",
      destination: "QRCode",
      sender: null,
      challenge: "958455",
      messageToUser:
        "https://engdobsqc-oidc-dobs-webapp.identityx-build.com/phonetesting1/start?accountApplicationId=f01b19dd-8ead-486c-a658-5f951ae284c9&type=QR&code=958455",
      challengeCreationTime: "2022-04-28T10:30:57.887+00:00",
      challengeRepliedTime: null,
      challengeExpirySec: 1800,
      maxRetriesAllowed: 3,
      retriesCount: 0,
      maxUpdatesAllowed: 20,
      updatesCount: 1,
      status: "NOT_ATTEMPED",
      invalid: false,
    },
  ],
};
