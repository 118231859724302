import { SET_PUBLIC_KEY } from "../actions/fidelity";

export function fidelity(
  state = {
    publicKey: null,
  },
  action
) {
  switch (action.type) {
    case SET_PUBLIC_KEY:
      return {
        ...state,
        publicKey: action.payload,
      };
    default:
      return state;
  }
}