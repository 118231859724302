import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import PageContent from "../PageContent";
import ButtonRestartApp from "../common/ButtonRestartApp";
import DaonErrorBox from "Components/common/DaonErrorBox";
import { clearCache } from "logic/localCacheAdapter";
import { handleCompositeRestart } from "logic/reducers/handleCompositeRestart";

function PageSessionExpired({ runsWithIdp, mobileAppPlatform }) {
  useEffect(() => {
    clearCache();
  }, []);
  const { t } = useTranslation();
  const text = runsWithIdp
    ? t("ApiMessaging.session_expired_oidc")
    : t("ApiMessaging.session_expired");
  return (
    <PageContent
      justifyContent="center"
      showBack={false}
      title={t("PageSessionExpired.title")}
    >
      <h2>{t("PageSessionExpired.title")}</h2>
      <DaonErrorBox message={text} whiteBackground />
      {!runsWithIdp || mobileAppPlatform ? (
        <ButtonRestartApp
          onClick={mobileAppPlatform ? handleCompositeRestart : null}
        />
      ) : null}
    </PageContent>
  );
}

export default connect((state) => {
  return {
    ...state.configuration,
    ...state.composite,
  };
})(PageSessionExpired);
