import { rest } from "msw";
import { faceMock } from "./face";
import { configMock } from "./config";
import { challengeMock } from "./challenge";
import { applicationQR, applicationSubmitPost } from "./applicationSubmitPost";
import { applicationPostMock } from "./applicationPost";
import { applicationPutPhoneMock } from "./applicationPutPhoneMock";
import { applicationPutQRMock } from "./applicationPutQRMock";
import { assessmentsMock } from "./assesments";
import { applicationPostQRMock } from "./applicationPostQRMock";
import { applicationPostPhoneMock } from "./applicationPostPhoneMock";
import { applicationGetMock } from "./applicationGetMock";
export const handlers = [
  rest.get("*/config", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(configMock));
  }),
  rest.post("*/userConsents", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ ok: true }));
  }),
  rest.get("*/actuator/info", (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: {
          app: { version: "localhost-mock" },
          git: { version: "localhost-mock" },
        },
      })
    );
  }),
  rest.get("*/rest/v1/accountapplications/:applicationId", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(applicationGetMock));
  }),
  rest.post(
    "*/IdentityXServices/rest/v1/users/:userId/face/samples",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(faceMock));
    }
  ),
  rest.post(
    "/rest/v1/accountapplications/:applicationId/verification/phone",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(applicationPostPhoneMock));
    }
  ),

  rest.post(
    "/rest/v1/accountapplications/:applicationId/verification/QR",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(applicationPostQRMock));
    }
  ),
  rest.post(
    "*/DigitalOnBoardingServices/rest/v1/users/:userId/idchecks/:checkId/challenges",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(challengeMock));
    }
  ),
  rest.post(
    "*/DigitalOnBoardingServices/rest/v1/users/:userId/idchecks/:checkId/videos",
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          id: 1,
          processingStatus: "PROCESSED",
        })
      );
    }
  ),
  rest.post(
    "*/DigitalOnBoardingServices/rest/v1/users/:userId/idchecks/:checkId/documents",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(faceMock));
    }
  ),
  rest.post("*/rest/v1/accountapplications", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(applicationPostMock));
  }),
  // rest.post("*/rest/v1/accountapplications", (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(applicationPutMock));
  // }),
  rest.put(
    "*/rest/v1/accountapplications/:applicationId/verification/phone",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(applicationPutPhoneMock));
    }
  ),
  rest.put(
    "*/rest/v1/accountapplications/:applicationId/verification/QR",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(applicationPutQRMock));
    }
  ),
  rest.post(
    "*/rest/v1/accountapplications/:applicationId/submit",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(applicationSubmitPost));
    }
  ),
  rest.post(
    "*/rest/v1/accountapplications/:applicationId/status",
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(Math.random() > 0.9 ? applicationQR : applicationSubmitPost)
      );
    }
  ),
  rest.get("*/startSession", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(applicationSubmitPost));
  }),
  rest.post("*/rest/v1/quality/assessments", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(assessmentsMock));
  }),
];
