
const SwitchCamera = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <clipPath id="c">
        <path d="M0 0h32v32H0z" />
      </clipPath>
      <filter
        id="a"
        filterUnits="objectBoundingBox"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
      >
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
      </filter>
      <image
        id="b"
        width={32}
        height={32}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAASElEQVRYhe3OsRGAMAzAQJljKe/BJHS5S89oGSqFadnABdICerC/F7XnDWTTf51AEs/Vsq/B0TL+JECAAAECBAgQIEBAO8DsBVcaBqcTs+bnAAAAAElFTkSuQmCC"
      />
      <mask id="e">
        <g filter="url(#a)">
          <use xlinkHref="#b" />
        </g>
      </mask>
      <g id="d" clipPath="url(#c)">
        <path
          style={{
            stroke: "none",
            fillRule: "evenodd",
            fill: props.fill,
            fillOpacity: 1,
          }}
          d="m22.96 15.293-.425.422c-.598-3.063-3.3-5.383-6.535-5.383A6.677 6.677 0 0 0 9.332 17 6.677 6.677 0 0 0 16 23.668a6.628 6.628 0 0 0 4.715-1.953A1 1 0 1 0 19.3 20.3 4.652 4.652 0 0 1 16 21.668 4.673 4.673 0 0 1 11.332 17 4.675 4.675 0 0 1 16 12.332a4.666 4.666 0 0 1 4.32 2.926.992.992 0 0 0-1.36.035 1 1 0 0 0 0 1.414l2.708 2.707 2.707-2.707a1 1 0 1 0-1.414-1.414Zm6.708 9.039A1.67 1.67 0 0 1 28 26H4a1.67 1.67 0 0 1-1.668-1.668v-14c0-.918.75-1.664 1.668-1.664h4.79c.304 0 .589-.14.78-.375l1.477-1.836a.33.33 0 0 1 .258-.125h9.39a.33.33 0 0 1 .258.125l1.477 1.836c.191.234.476.375.777.375H28c.918 0 1.668.746 1.668 1.664ZM28 6.668h-4.313l-1.175-1.465a2.327 2.327 0 0 0-1.817-.871h-9.39c-.711 0-1.371.316-1.817.871L8.313 6.668H4a3.67 3.67 0 0 0-3.668 3.664v14A3.671 3.671 0 0 0 4 28h24a3.671 3.671 0 0 0 3.668-3.668v-14A3.67 3.67 0 0 0 28 6.668Zm0 0"
        />
      </g>
    </defs>
    <use xlinkHref="#d" mask="url(#e)" />
  </svg>
)

export default SwitchCamera
