import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { go } from "logic/actions/navigation";
import { applicationStatus } from "logic/actions/api/application";
import {
  APPROVED,
  DECLINED,
  QR_INSTRUCTIONS,
  QR_SCAN,
  SENT_TO_MOBILE,
  THANK_YOU,
  VALIDATE_PHONE,
} from "logic/enums/pages";
import {
  AUTO_APPROVED,
  FAILED,
  FAILED_EXPIRED,
  VERIFIED,
} from "logic/enums/applicationStatus";
import PageSentToMobile from "../PageSentToMobile";
import PageQRScan from "Components/modules/ValidateQR/PageQRScan";
import { QR, SMS } from "logic/enums/verification";
import { logEvent, phone_verified, step_completed, switch_flow_finished } from "logic/eventLogger";
import { getTimePassed, phone_number, qr_code } from "logic/stepTimers";
import { redirectFrom } from "logic/actions/phoneValidation";
import { destroySessionAndNavigate } from "logic/api/logout";

export const PagePhoneStatus = (props) => {
  let errorCounter = 0;
  let timeout;
  let ismounted;
  let tryAgainTimeout;

  let goBackTimeout;
  const { t } = useTranslation();

  const [shouldPoll, setShouldPoll] = useState(false);
  const [verified, setVerified] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);
  const [tryAgainShown, setTryAgainShown] = useState(false);
  const [controller] = useState(new AbortController());

  useEffect(() => {
    ismounted = true;

    showTryAgainContent();

    if (props.runsWithIdp) {
      setShouldPoll(true);
      poll();
    }
    return () => {
      clearTimeout(timeout);
      clearTimeout(tryAgainTimeout);
      clearTimeout(goBackTimeout);
      ismounted = false;
    };
  }, []);

  useEffect(() => {
    if (verified) {
      let timePassed = getTimePassed(qr_code);
      let type = QR;
      if (
        getTimePassed(phone_number) &&
        props.redirectedFrom === VALIDATE_PHONE
      ) {
        timePassed = getTimePassed(phone_number);
        type = SMS;
      }
      logEvent(step_completed, {
        stepNameCompleted: phone_verified,
        type,
        timePassed,
      });
    }
  }, [verified]);

  const poll = () => {
    if (timeout) clearTimeout(timeout);
    if (!pending && ismounted) {
      timeout = setTimeout(() => {
        getStatus();
      }, 3000);
    }
  };

  const showTryAgainContent = () => {
    tryAgainTimeout = setTimeout(() => {
      setTryAgainShown(true);
    }, 7000);
  };

  const checkVerification = (verification) => {
    if (props.redirectedFrom === VALIDATE_PHONE) {
      return verification.type === SMS && verification.status === VERIFIED;
    } else {
      return verification.status === VERIFIED;
    }
  };

  const checkExpiration = (verification) => {
    if (goBackTimeout) clearTimeout(goBackTimeout);
    if (verification.challengeExpirySec) {
      goBackTimeout = setTimeout(() => {
        if (!ismounted) return;
        if (
          verification.status !== VERIFIED &&
          verification.status !== FAILED_EXPIRED
        ) {
          if (verification.type === QR) {
            props.go(QR_INSTRUCTIONS);
            props.redirectFrom(QR_SCAN);
          } else if (verification.type === SMS) {
            props.redirectFrom(SENT_TO_MOBILE);
            props.go(VALIDATE_PHONE);
          }
        }
      }, verification.challengeExpirySec * 1000);
    }
  };
  const abortFunction = () => {
    if (controller) controller.abort();
  };
  const getStatus = () => {
    if (!ismounted) return;
    setPending(true);
    props
      .applicationStatus(controller.signal)
      .then((response) => {
        setPending(false);
        const status = response.data.status;
        const verifications = response.data.verifications;
        if (!goBackTimeout) {
          verifications.forEach((verification) => {
            checkExpiration(verification);
          });
        }

        if (response.data.message) {
          setError(response.data.message);
          return;
        }
        if (response.data.redirectUrl) {
          logEvent(switch_flow_finished, {
            status: status,
          });
          if (!redirected) {
            setRedirected(true);
            destroySessionAndNavigate(response.data.redirectUrl);
          }
          return;
        } else if (
          verifications.find((verification) => checkVerification(verification))
        ) {
          clearTimeout(goBackTimeout);
          setVerified(true);
          poll();
        } else if (status === AUTO_APPROVED) {
          props.go(APPROVED);
        } else if (status === FAILED) {
          props.go(DECLINED);
        } else if (response.data.interruptIDPAuth) {
          props.go(THANK_YOU);
        } else {
          poll();
        }
      })
      .catch(() => {
        errorCounter++;
        if (errorCounter > 9) {
          setError(t("PagePhoneStatus.error_message"));
          return;
        } else {
          setPending(false);
          poll();
        }
      });
  };

  return props.redirectedFrom === VALIDATE_PHONE ||
    props.redirectFrom === SENT_TO_MOBILE ||
    verified ||
    error ? (
    <PageSentToMobile
      error={error}
      abortFunction={abortFunction}
      shouldPoll={shouldPoll}
      tryAgainShown={tryAgainShown}
      verified={verified}
    />
  ) : (
    <PageQRScan abortFunction={abortFunction} />
  );
};

export default connect(
  (state) => {
    return {
      ...state.configuration,
      ...state.phoneValidation,
    };
  },
  {
    applicationStatus,
    go,
    redirectFrom,
  }
)(PagePhoneStatus);
