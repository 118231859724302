export const CAMERA_INDEX = "CAMERA_INDEX";
export const CAMERA_SET_CAMERA_LIST = "CAMERA_SET_CAMERA_LIST";
export const CAMERA_SET_SELECTED_CAMERA = "CAMERA_SET_CASELECTED_CAMERA";
export const CHANGE_CAMERA = "CHANGE_CAMERA";

export function setCameraList(cameraList) {
  return {
    type: CAMERA_SET_CAMERA_LIST,
    payload: cameraList,
  };
}

export function changeCamera(payload) {
  return {
    type: CHANGE_CAMERA,
    payload,
  };
}

export function setSelectedCamera(device) {
  return {
    type: CAMERA_SET_SELECTED_CAMERA,
    payload: device,
  };
}

export const storeCameraIndex = (payload) => (dispatch) => {
  dispatch({
    type: CAMERA_INDEX,
    payload,
  });
};
