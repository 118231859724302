import { useDispatch, useSelector } from "react-redux";
import { nextPage } from "logic/actions/navigation";
import { useEffect, useState } from "react";
import PageContent from "Components/PageContent";
import * as S from "./PageNFCInstructions.styles";
import { useTranslation } from "react-i18next";
import DaonButton from "Components/common/DaonButton";
import usPassportVideo from "assets/videos/nfc_us_passport.webp";
import passportVideo from "assets/videos/nfc_passport.webp";
import passportCardVideo from "assets/videos/nfc_passport_card.webp";
import i18n from "i18next";

import { isMobile } from "logic/deviceType";
import { PASSPORT } from "logic/enums/document";
import InstructionVideo from "Components/common/InstuctionVideo";
import { nfc_scan } from "logic/stepTimers";
import { startEventTimer } from "logic/stepTimers";
import { getTimePassed } from "logic/stepTimers";
import { webView } from "logic/webView";
import { LANGUAGE_TAG } from "logic/enums/composite";
import { selectIsCompositeFlow } from "logic/reducers/composite";
// import DaonErrorBox from "Components/common/DaonErrorBox";

export const PageNFCInstructions = (props) => {
  const [displayVideo, setDisplayVideo] = useState(true);
  const [instructions, setInstructions] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialCountryNum = useSelector(
    (state) => state.documentCaptureConfiguration.dialCountryNum
  );
  const isCompositeFlow = useSelector(selectIsCompositeFlow);
  const documentType = useSelector(
    (state) => state.documentCapture.documentType
  );
  function goToNfcScan() {
    if (isCompositeFlow) webView(LANGUAGE_TAG, i18n.language);
    nextPage()(dispatch);
  }

  const updateDisplayProperty = () => {
    setDisplayVideo(!displayVideo);
  };

  useEffect(() => {
    if (!getTimePassed(nfc_scan)) startEventTimer(nfc_scan);

    setInstructions(
      documentType === PASSPORT
        ? dialCountryNum === "us"
          ? { video: usPassportVideo, alt: t("PageNfcInstructions.video_alt_passport_us"), title: t("PageNfcInstructions.video_title_passport_us")}
          : { video: passportVideo, alt: t("PageNfcInstructions.video_alt_passport"), title: t("PageNfcInstructions.video_title_passport")}
        : { video: passportCardVideo, alt: t("PageNfcInstructions.video_alt_document"), title: t("PageNfcInstructions.video_title_document")}
    );
  }, []);

  return (
    <PageContent
      //   toggleLoading={this.state.pending}
      showBack={false}
      title={t("PageNFCScan.title")}
      instructions={true}
    >
      <S.Content>
        <S.H2>{t("PageNFCInstructions.header")}</S.H2>
        {t("PageNFCInstructions.paragraph").trim() && <S.Paragraph margin="0 0 30px 0">
          {t("PageNFCInstructions.paragraph")}
        </S.Paragraph>}
        {isMobile(navigator.userAgent) && 
        <>
         {displayVideo && <InstructionVideo
            translation={t}
            video={instructions.video}
            alt={instructions.alt}
            title={instructions.title}
          ></InstructionVideo>}
          <DaonButton onClick={updateDisplayProperty} id="open_camera">
          {displayVideo ? t("Common.hide_video") : t("Common.display_video")}
        </DaonButton>
        </>
          
        }
        {t("PageNfcInstructions.make_sure").trim() && <S.Para>{t("PageNfcInstructions.make_sure")}</S.Para>}

        <S.ListItems>
          <ul>
            <li>{t("PageNfcInstructions.remove_case")}</li>
            <li>{t("PageNfcInstructions.place_phone")}</li>
            <li>{t("PageNfcInstructions.hold_document")}</li>
            {t("PageNfcInstructions.phone_touch").trim() && <li>{t("PageNfcInstructions.phone_touch")}</li>}
          </ul>
        </S.ListItems>
        <DaonButton onClick={goToNfcScan} id="open_camera">
          {t("PageNFCInstructions.scan_document")}
        </DaonButton>
      </S.Content>
    </PageContent>
  );
};

export default PageNFCInstructions;
