import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgb(34, 34, 34, 0.8);
`;

export const Heading = styled.h2`
  color: #FFF;
  text-align: center;
  font-size: 1.2rem;
  overflow-wrap: anywhere;
  width: 90%;
`;
