import dayjs from "dayjs";

export function validateDate(value) {
  if (!value) {
    return "The date is missing or incorrect format.";
  }
  const dateInput = dayjs(value);
  const tomorrow = dayjs().add(1, "day");

  // Must not be in the past or today
  if (dateInput.isBefore(tomorrow, "day")) return "Date must not be in the past or today";

  // Must be no more than 10 years into the future
  if (dateInput.isAfter(dayjs().add(10, "years"), "day"))
    return "Date must not be more than 10 years in the future";

  return null;
}

export function validateDateOfBirth(value) {
  if (!value) {
    return "The date is missing or in an incorrect format.";
  }

  const dateInput = dayjs(value);
  const today = dayjs();
  const oneHundredYearsAgo = dayjs().subtract(100, "years");

  // Date of birth must be in the past
  if (!dateInput.isBefore(today, "day")) {
    return "The date of birth must be in the past.";
  }

  // Date of birth must not be more than 100 years ago (making the person at most 100 years old)
  if (dateInput.isBefore(oneHundredYearsAgo, "day")) {
    return "The date of birth must be within the last 100 years."
  }

  // If the date passes the above checks, it is valid
  return null;
}

export function validateInput(value) {
  if (!value) {
    return "Document value is missing";
  }
  // Alphanumeric Validation
  if (!/^[a-z0-9]+$/i.test(value)) {
    return "Only alphanumeric characters are allowed";
  }

  // Min 8 characters Validation
  if (value.length < 8) {
    return "Minimum of 8 characters required";
  }

  // Max 12 characters Validation
  if (value.length > 12) {
    return "Maximum of 12 characters allowed";
  }

  return null;
}
