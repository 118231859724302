import { connect } from "react-redux";
import {
  documentSidesSelector,
  verticalDocumentsPresent,
  countrySelected,
} from "logic/actions/documentCaptureConfiguration";
import { go } from "logic/actions/navigation";
import { useTranslation } from "react-i18next";

import PageContent from "Components/PageContent";
import * as S from "./PageDocumentCountrySelector.styles";
import { VERIFY_IDENTITY } from "logic/enums/pages";
import CountrySelector from "Components/common/CountrySelector";

function PageDocumentCountrySelector(props) {
  const { t } = useTranslation();

  const countrySelected = (country) => {
    props.go(VERIFY_IDENTITY);
    props.documentSidesSelector({
      id: undefined,
      dl: undefined,
    });
    props.verticalDocumentsPresent(false);
    props.countrySelected(country);
  };

  return (
    <PageContent
      title={t("PageDocumentCapture.title")}
      hideFooter={true}
      instructions={true}
      justifyContent="center"
    >
      <S.Content>
        <S.Heading>
          {t("PageDocumentCountrySelector.issuing_country")}
        </S.Heading>
        <S.Paragraph margin="0 0 24px 0">
          {t("PageDocumentCountrySelector.description")}
        </S.Paragraph>
        <CountrySelector
          height="40vh"
          countrySelected={countrySelected}
          document={props.document}
        />
      </S.Content>
    </PageContent>
  );
}

export default connect(
  (state) => {
    return {
      ...state.documentCaptureConfiguration,
      ...state.configuration.extraConfig,
      document: state.configuration?.extraConfig?.steps?.document,
    };
  },
  {
    countrySelected,
    documentSidesSelector,
    verticalDocumentsPresent,
    go,
  }
)(PageDocumentCountrySelector);
