import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import PageContent from "../PageContent";
import { applicationSubmit } from "logic/actions/api/application";
import { go } from "logic/actions/navigation";
import { logEvent, step_completed, application_end } from "logic/eventLogger";
import setStatusCode from "logic/setStatusCode";
import {
  APPROVED,
  DECLINED,
  REVIEWING,
  CONTINUE_ON_DESKTOP,
} from "logic/enums/pages";
import { destroySessionAndNavigate } from "logic/api/logout";
import DaonSpinner from "Components/common/DaonSpinner";
import { clearCache } from "../../logic/localCacheAdapter";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import ButtonRestartApp from "../common/ButtonRestartApp";
import * as S from "./PageThankYou.styles";
import { getTimePassed } from "logic/stepTimers";
import DaonErrorBox from "Components/common/DaonErrorBox";
import DaonButton from "Components/common/DaonButton";
import WhitelabelCompanyLogo from "Components/common/WhitelabelCompanyLogo/WhitelabelCompanyLogo";
import { webView } from "logic/webView";
import { BAD_REQUEST_STATUS_CODE, DAON_CALLBACK } from "logic/enums/composite";
import { ReactComponent as InfoIcon } from "assets/icons/info_icon.svg";

function PageThankYou(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const [error, setError] = useState("");
  const [pending, setPending] = useState(true);
  const [evaluationRetries, setEvaluationRetries] = useState(3);
  const [mounted, setMounted] = useState(true);

  const showEvalResults = useSelector((store) => {
    const { showApplicationResults } =
      store.configuration.extraConfig.featureFlags;
    //resolving serialization problem
    return showApplicationResults === "true" || showApplicationResults === true;
  });

  const triggerEvaluation = () => {
    if (!evaluationRetries) {
      setError(t("PageThankYou.set_error"));
    } else {
      setPending(true);
      applicationSubmit()(dispatch)
        .then((response) => {
          const stepName = "application_submitted";
          const timePassed = getTimePassed(stepName);
          logEvent(step_completed, {
            stepNameCompleted: stepName,
            timePassed,
          });
          logEvent(application_end, {
            timePassed: getTimePassed("application"),
            status: response.data?.status,
          });

          if (mounted) {
            if (response.data.message) {
              setError(response.data.message);
              setPending(false);
              return;
            }
            if (response.data.redirectUrl) {
              setPending(false);
              destroySessionAndNavigate(response.data.redirectUrl);
            } else if (
              response.data.shouldProceedOnMobile === "true" &&
              props.configuration.runsWithIdp &&
              !response.data.interruptIDPAuth
            ) {
              if (props.mobileAppPlatform)
                setTimeout(() => {
                  webView(DAON_CALLBACK, setStatusCode(response.data.status));
                }, 2000);
              //shouldProceedOnMobile is missleading, it is used when user enrolled after switching from desktop
              go(CONTINUE_ON_DESKTOP)(dispatch);
            } else if (showEvalResults) {
              if (response.data.status === "AUTO_APPROVED") {
                go(APPROVED)(dispatch);
              } else if (response.data.status === "AUTO_DECLINED") {
                go(DECLINED)(dispatch);
              } else {
                go(REVIEWING)(dispatch);
              }
            }
          }
        })
        .catch(() => {
          webView(DAON_CALLBACK, BAD_REQUEST_STATUS_CODE);
          setPending(false);
          if (evaluationRetries) {
            setEvaluationRetries(evaluationRetries - 1);
          }
        })
        .finally(() => {
          localStorage.removeItem("i18nextLng");
          clearCache();
        });
    }
  };

  useEffect(() => {
    document.title = `${t("PageThankYou.verification_complete")} | Onboarding`;
  }, [t]);

  useEffect(() => {
    triggerEvaluation();
    return () => {
      setMounted(false);
    };
    // eslint-disable-next-line
  }, [dispatch]);

  if (error) {
    return (
      <S.RestartAppWrapper>
        <DaonErrorBox message={error} whiteBackground />
        <ButtonRestartApp />
        <WhitelabelCompanyLogo />
      </S.RestartAppWrapper>
    );
  }

  return (
    <PageContent
      showBack={false}
      justifyContent="center"
      title={t("PageThankYou.verification_complete")}
      hideHeader={true}
    >
      {evaluationRetries === 3 ? (
        <>
          <S.InfoIcon>
            <InfoIcon />
          </S.InfoIcon>

          <h2>{t("PageThankYou.title")}</h2>
          <p>
            {t(
              showEvalResults
                ? props.isSwitchedToMobile
                  ? "PageContinueOnDesktop.description"
                  : "PageThankYou.description"
                : "PageThankYou.description_evaluation"
            )}
          </p>
          
          {pending && <p>{t("PageThankYou.footer")}</p>}
          <p>{t("PageThankYou.footer_new")}</p>
        </>
      ) : (
        <S.Wrap>
          <S.RetryMessage>{t("PageThankYou.retry_message")}</S.RetryMessage>
          <DaonButton
            onClick={triggerEvaluation}
            id="resend"
            disabled={pending}
          >
            {t("PageThankYou.resend_button")} ({evaluationRetries})
          </DaonButton>
        </S.Wrap>
      )}
      {pending && <DaonSpinner color={theme.spinner} />}
    </PageContent>
  );
}

export default connect((state) => {
  return {
    configuration: state.configuration,
    isSwitchedToMobile: state.application.isSwitchedToMobile,
    mobileAppPlatform: state.composite.mobileAppPlatform,
  };
})(PageThankYou);
