import { useSelector } from "react-redux";
import { ReactComponent as CompletedStep } from "../../../assets/icons/16-24/CompletedStep.svg";
import { ReactComponent as CurrentStep } from "../../../assets/icons/16-24/CurrentStep.svg";
import { ReactComponent as Step } from "../../../assets/icons/16-24/Step.svg";
import * as S from "./ProgressSteper.styles";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { steps } from "logic/steps";
import { useTranslation } from "react-i18next";

export default function ProgressSteper() {
  const currentStep = useSelector((state) => {
    return steps[0]?.hide === true
      ? state.navigation.step - 1
      : state.navigation.step;
  });
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <S.WrapperDiv>
      <S.UnorderedList>
        {steps
          .filter((step) => step.pages && step.title && !step.hide)
          .map((step, index, { length }) => (
            <S.ListedItem key={index} aria-current={index === currentStep ? "step" : false} >
              <S.LiWrapper>
                {index < currentStep ? (
                  <CompletedStep alt="Completed"fill={theme.textPrimary} />
                ) : index === currentStep ? (
                  <CurrentStep alt="Current" fill={theme.textPrimary} />
                ) : (
                  <Step alt="Upcoming" fill={theme.textSecondary} />
                )}
                {index + 1 < length && (
                  <S.VerticalLine
                    color={
                      currentStep > index
                        ? theme.textPrimary
                        : theme.textSecondary
                    }
                  />
                )}
              </S.LiWrapper>
              <S.Paragraph
                color={currentStep >= index ? theme.textPrimary : ""}
              >
                {t(`${step.title}_left_sidebar`)}
              </S.Paragraph>
            </S.ListedItem>
          ))}
      </S.UnorderedList>
    </S.WrapperDiv>
  );
}
