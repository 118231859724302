import React, { Component } from "react";
import { func } from "prop-types";

export default class VisiblityChangeHandler extends Component {
  static propTypes = {
    onVisibilityChange: func.isRequired,
  };

  handleVisibilityChange = () => {
    if (typeof this.props.onVisibilityChange === "function") {
      this.props.onVisibilityChange(document.visibilityState === "visible");
    }
  };

  componentDidMount() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  render() {
    return <>{this.props.children}</>;
  }
}
