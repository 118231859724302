import styled from 'styled-components'

export const RestartAppWrapper = styled.div`
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-left: 30%;
     margin-top: 5%;
    
    & > button {
        margin: 1rem 0;
        width: 90%;
    }
`

export const RetryMessage = styled.h2`
    text-align: center;
    font-weight: 600;
    margin-bottom: 2rem;
`

export const Wrap = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const WrapLogo = styled.div`
  @media (min-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
    display: flex;
    align-items: start;
}
`

export const LinkText = styled.a`
    color: ${props => props.theme.mainColor};
    cursor: pointer;
    display: block;
    text-decoration-line: underline;
`;

export const InfoIcon = styled.div`
    margin-left: 0.5em;
`