import * as S from "./QRStepper.styles";
import { useTranslation } from "react-i18next";

export const QRStepper = () => {
  const { t } = useTranslation();

  const qrSteps = [
    "QRStepper.scan_QR",
    "QRStepper.complete_verification",
    "QRStepper.return",
  ];

  return (
    <S.ListWrapper>
      <S.OrderedList>
        {qrSteps.map((step, index, { length }) => (
          <S.ListedItem key={index}>
            <S.LiWrapper>
              <S.StepCircle>{index + 1}</S.StepCircle>
              {index + 1 < length && <S.VerticalLine />}
            </S.LiWrapper>
            <S.StepDescription>{t(step)}</S.StepDescription>
          </S.ListedItem>
        ))}
      </S.OrderedList>
    </S.ListWrapper>
  );
};

export default QRStepper;
