import { useEffect } from "react";

export default function ScrollingStopDetector({ onStop }) {
  useEffect(() => {
    var isScrolling;

    function onScroll(event) {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(
        () => typeof onStop === "function" && onStop(),
        66
      );
    }

    window.addEventListener("scroll", onScroll, false);
    window.addEventListener("resize", onScroll, false);
    return () => {
      window.removeEventListener("scroll", onScroll, false);
      window.removeEventListener("resize", onScroll, false);
    };
  });

  return null;
}
