import React, { Component } from "react";
import { string, func } from "prop-types";

import { isMobile } from "../../logic/deviceType";
import DaonButton from "Components/common/DaonButton";
import { withTranslation } from "react-i18next";
class LinkNativeCapture extends Component {
  static propTypes = {
    facingMode: string,
    onUploaded: func,
    onNativeButtonReady: func,
    onButtonClicked: func,
  };
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.onClick.bind(this);
    this.onUploaded.bind(this);
  }

  componentDidMount() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    if (typeof this.props.onNativeButtonReady === "function") {
      this.props.onNativeButtonReady(this.ref);
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  handleVisibilityChange = () => {
    if (typeof this.props.onVisibilityChange === "function") {
      this.props.onVisibilityChange(document.visibilityState === "visible");
    }
  };

  onClick = () => {
    this.props.handleShowNativeInstructions();
  };

  onClick = () => {
    if (typeof this.props.onButtonClicked === "function") {
      this.props.onButtonClicked();
    }
  };

  onUploaded = (e) => {
    const files = e.target.files;
    if (files && files.length) {
      if (typeof this.props.onUploaded === "function") {
        this.props.onUploaded(files[0]);
      }
    }
  };

  render() {
    const buttonText = isMobile(navigator.userAgent)
      ? this.props.t("PageDocumentCaptureInstructions.btn_take_a_photo")
      : this.props.imageType === "selfie"
        ? this.props.t("Common.upload_selfie")
        : this.props.t("Common.upload_document");

    return (
      <span>
        <input
          type="file"
          accept="image/*"
          capture={this.props.facingMode || "user"}
          ref={this.ref}
          onChange={this.onUploaded}
          id="file_input"
        />
        <DaonButton
          onClick={this.onClick}
          className="secondary"
          style={{ marginBottom: "1rem" }}
          id="upload"
        >
          {buttonText}
        </DaonButton>
      </span>
    );
  }
}

export default withTranslation()(LinkNativeCapture);
