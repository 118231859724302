export const SET_MOBILE_PLATFORM = "SET_MOBILE_PLATFORM";
export const SET_NFC_CHALLENGE = "SET_NFC_CHALLENGE";
export const INCREASE_NFC_RETRIES = "INCREASE_NFC_RETRIES";
export const RESTART_NFC_RETRIES = "RESTART_NFC_RETRIES";
export const SET_SDK_VERSION = "SET_SDK_VERSION";

export const setMobilePlatform = (payload) => (dispatch) => {
  dispatch({ type: SET_MOBILE_PLATFORM, payload });
};
export const setNFCChallenge = (payload) => (dispatch) => {
  dispatch({
    type: SET_NFC_CHALLENGE,
    payload,
  });
};
export const setSDKVersion = (payload) => (dispatch) => {
  dispatch({
    type: SET_SDK_VERSION,
    payload,
  });
};

export const NFCRetry = () => (dispatch) => {
  dispatch({ type: INCREASE_NFC_RETRIES });
};

export const restartNFCRetries = () => (dispatch) => {
  dispatch({ type: RESTART_NFC_RETRIES });
};
