import "./LoadingOverlay.styles.jsx";
import * as S from "./LoadingOverlay.styles";
import WhitelabelCompanyLogo from "../WhitelabelCompanyLogo/WhitelabelCompanyLogo.jsx";
import DaonSpinner from "../DaonSpinner/index.js";

export default function LoadingOverlay({ loadingText, isDarkMode }) {
  return (
    <S.Wrapper aria-modal="true">
      <DaonSpinner isDarkMode={isDarkMode} />
      <S.Heading data-testid="loading-configuration">{loadingText}</S.Heading>
      <WhitelabelCompanyLogo white />
    </S.Wrapper>
  );
}
