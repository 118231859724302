import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import errorRedIcon from "assets/icons/error_red.svg";
import warningRedIcon from "assets/icons/warning_red.svg";
import warningGrayIcon from "assets/icons/warning_gray.svg";
import {
  NFC_STATUS_DISABLED,
  NFC_STATUS_FAILED,
  NFC_STATUS_INTERRUPTED,
  NFC_STATUS_NOT_SUPPORTED,
  NFC_STATUS_TIMEOUT,
  NFC_STATUS_WRONG_IOS_VERSION,
} from "logic/enums/nfc";
import PageContent from "Components/PageContent";
import * as S from "./PageNFCStatus.styles";
import { NFC_INSTRUCTIONS, VERIFY_IDENTITY } from "logic/enums/pages";
import { useDispatch, useSelector } from "react-redux";
import DaonButton from "Components/common/DaonButton";
import { webView } from "logic/webView";
import { ANDROID, ENABLE_NFC } from "logic/enums/composite";
import { go } from "logic/actions/navigation";
import { selectMobileAppPlatform } from "logic/reducers/composite";
import { restartNFCRetries } from "logic/actions/composite";

export default function PageNFCStatus({ type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [icon, setIcon] = useState(null);
  const [buttonText, setButtonText] = useState(t("PageNFCStatus.button"));
  const mobileAppPlatform = useSelector(selectMobileAppPlatform);

  useEffect(() => {
    if (mobileAppPlatform === ANDROID) window.onNfcEnabled = onNfcEnabled;
    return () => {
      if (mobileAppPlatform === ANDROID) window.onNfcEnabled = null;
    };
  }, []);

  useEffect(() => {
    document.title = `${t("PageNFCScan.title")} | Onboarding`;

    switch (type) {
      case NFC_STATUS_DISABLED:
        setIcon(warningRedIcon);
        setTitle(t("PageNfcDisabled.title"));
        setContent(t("PageNfcDisabled.content"));
        setButtonText(t("PageNfcDisabled.button_text"));
        break;
      case NFC_STATUS_NOT_SUPPORTED:
        setIcon(warningRedIcon);
        setTitle(t("PageNfcNotSupported.title"));
        setContent(t("PageNfcNotSupported.content"));

        break;
      case NFC_STATUS_WRONG_IOS_VERSION:
        setIcon(warningRedIcon);
        setTitle(t("PageNfcIosVersion.title"));
        setContent(t("PageNfcIosVersion.content"));

        break;
      case NFC_STATUS_TIMEOUT:
        setIcon(warningGrayIcon);
        setTitle(t("PageNfcTimeout.title"));
        setContent(t("PageNfcTimeout.content"));

        break;
      case NFC_STATUS_FAILED:
        setIcon(errorRedIcon);
        setTitle(t("PageNfcFailed.title"));
        setContent(t("PageNfcFailed.content"));
        setButtonText(t("PageNfcFailed.button_text"));

        break;
      case NFC_STATUS_INTERRUPTED:
        setIcon(errorRedIcon);
        setTitle(t("PageNfcInterrupted.title"));
        setContent(t("PageNfcInterrupted.content"));
        setButtonText(t("PageNfcInterrupted.button_text"));
        break;

      default:
        setIcon(errorRedIcon);
        setTitle(t("PageNfcInterrupted.title"));
        setContent(t("PageNfcInterrupted.content"));
    }
  }, [t, type]);

  const onClick = () => {
    //multiple fails - what to do ???
    if (type === NFC_STATUS_DISABLED) {
      webView(ENABLE_NFC);
    } else if (type === NFC_STATUS_FAILED) {
      restartNFCRetries()(dispatch);
      go(VERIFY_IDENTITY)(dispatch);
    } else {
      go(NFC_INSTRUCTIONS)(dispatch);
    }
  };

  function onNfcEnabled() {
    go(NFC_INSTRUCTIONS)(dispatch);
  }
  return (
    <PageContent
      showBack={false}
      justifyContent="center"
      title={
        type === NFC_STATUS_WRONG_IOS_VERSION
          ? t("PageNFCStatus_wrong_ios_version.title")
          : t("PageNFCStatus.title")
      }
    >
      {icon && (
        <>
          <img src={icon} width="64" height="64" alt={`Error`}></img>
          <br />
        </>
      )}
      <h2>{title}</h2>
      <br />
      <S.Content>{content}</S.Content>
      {type !== NFC_STATUS_NOT_SUPPORTED &&
        type !== NFC_STATUS_WRONG_IOS_VERSION && (
          <S.ButtonWrapper>
            <DaonButton onClick={onClick} id="get_back">
              {buttonText}
            </DaonButton>
          </S.ButtonWrapper>
        )}
    </PageContent>
  );
}
