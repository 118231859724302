import PageContent from "../PageContent";
import { useTranslation } from "react-i18next";
import WhitelabelCompanyLogo from "Components/common/WhitelabelCompanyLogo/WhitelabelCompanyLogo";
import { APPROVED, DECLINED, REVIEW } from "../../logic/enums/pageStatus";

function PageStatus({ status }) {
  const { t } = useTranslation();
  let color;
  let content;
  let title;
  switch (status) {
    case APPROVED:
      color = "#8CD4A4";
      title = t(`PageStatus.${APPROVED}.title`);
      content = t(`PageStatus.${APPROVED}.content`);
      break;
    case DECLINED:
      color = "##FFEDED";
      title = t(`PageStatus.${DECLINED}.title`);
      content = t(`PageStatus.${DECLINED}.content`);
      break;
    default:
      title = t(`PageStatus.${REVIEW}.title`);
      content = t(`PageStatus.${REVIEW}.content`);
      break;
  }
  return (
    <PageContent
      justifyContent="center"
      showBack={false}
      title={t("PageThankYou.verification_complete")}
    >
      <h2 style={{ color }}>{title}</h2>
      <p>{content}</p>

      <WhitelabelCompanyLogo />
    </PageContent>
  );
}

export default PageStatus;
