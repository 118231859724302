export const theme = {
  mainColor: "#2277B8",
  lightColor: "#DDE1E6",
  mainButtonText: "#FDFEFE",
  outlineButtonText: "#FDFEFE",
  captureButton: "#FFF",
  previewPageText: "#FDFEFE",
  textPrimary: "#002846",
  textSecondary: "#5D6D7C",
  inputColor: "#FFF",
  loadingBackground: "#173B56",
  lightBackground: "#FFF",
  darkBackground: "#000",
  grayBackground: "#313235",
  darkHeaderBackground: "#000",
  panelBackground: "#EEEFF3",
  sentToMobileBackground: "#E5E5E5",
  spinner: "#000",

  xLargeScreenBreakpoint: 1536,
  largeScreenBreakpoint: 1300,
  tabletBreakpoint: 1024,
  phoneBreakpoint: 768,
  smallPhoneBreakpoint: 380,
  extraSmallPhoneBreakpoint: 300,
  footerHeight: 50,
};
