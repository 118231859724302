import styled from "styled-components/macro"

export const UnorderedList = styled.ul`
padding-inline-start:0px;
list-style:none;
margin:0px;
`
export const ListedItem = styled.li`
margin-bottom:-4px;
position: relative;
display: flex;

img {
    margin-top: 2px;
    background: ${props => props.theme.panelBackground};
    z-index: 20;
    position: relative;
}
`
export const Paragraph = styled.p`
margin: 0px;
font-size: 1.1rem;
margin-bottom: 35.5px;
    color: ${props => props.color}; 
    display: inline-block;

`

export const VerticalLine = styled.div`
border-left: 2px solid;
border-color: ${props => props.color};
bottom: 3px;
top: 20px;
left: 9px;
    left: 9px;
    position: absolute;
    position: absolute;
`

export const WrapperDiv = styled.div`
position: relative;
margin-left: 48px;
margin-right: 8px;
margin-top: -10%;
`
export const LiWrapper = styled.div`
margin-right: 20px;
`

export const SpanDiv = styled.div`
width:50%;
display:inline-block;
height:100%;
position:absolute;
`
