/*global Daon*/
/*eslint no-undef: "error"*/

const actionPrefix = "DOCUMENT_CAPTURE_";

export const SELECT_DOC_TYPE = actionPrefix + "SELECT_DOC_TYPE";
export const PREVIEW = actionPrefix + "PREVIEW";
export const RETRY = actionPrefix + "RETRY";
export const RESET_RETRIES = actionPrefix + "RESET_RETRIES";
export const SET_CROPPED_IMAGE = actionPrefix + "SET_CROPPED_IMAGE";
export const SET_CROPPED_FIRST_PAGE = actionPrefix + "SET_CROPPED_FIRST_PAGE";
export const CREATE_INSTANCE = actionPrefix + "CREATE_INSTANCE";
export const SET_DOCUMENTS = actionPrefix + "SET_DOCUMENTS";
export const FIRST_TIME_INIT = actionPrefix + "FIRST_TIME_INIT";
export const RETRY_PROCESSING = actionPrefix + "RETRY_PROCESSING";
export const SET_DOCUMENT_LABEL = actionPrefix + "SET_DOCUMENT_LABEL";
export const SET_IS_DOC_WASM_LOADED = actionPrefix + "SET_IS_DOC_WASM_LOADED";
export const SET_IS_NFC_READY = actionPrefix + "SET_IS_NFC_READY";
export const CAPTURE_TIMEOUT = actionPrefix + "CAPTURE_TIMEOUT";
export const SET_DOCUMENT_NUMBER = actionPrefix + "SET_DOCUMENT_NUMBER";
export const SET_DOCUMENT_EXPIRY_DATE = actionPrefix + "SET_DOCUMENT_EXPIRY_DATE";
export const SET_SKIP_DOCUMENT_SELECTION = actionPrefix + "SET_SKIP_DOCUMENT_SELECTION";
export const SET_DOCUMENT_DATE_OF_BIRTH = actionPrefix + "SET_DOCUMENT_DATE_OF_BIRTH";
export const USER_TIMEOUT = actionPrefix + 'USER_TIMEOUT';
export const RESET_USER_TIMEOUT = actionPrefix + 'RESET_USER_TIMEOUT';

export const NEW_DOCUMENT_SELECTED =
  actionPrefix + "  NEW_DOCUMENT_SELECTED,  ";
export const SET_ASPECT_RATIO = "SET_ASPECT_RATIO";
export const SET_DOCUMENT_ID = "SET_DOCUMENT_ID";
export const INCREASE_RETRY_COUNTER = actionPrefix + "INCREASE_RETRY_COUNTER";
export const SET_DOCUMENT = actionPrefix + "SET_DOCUMENT";

export function selectDocumentType(payload) {
  return { type: SELECT_DOC_TYPE, payload };
}

export function setIsNFCReady(payload) {
  return { type: SET_IS_NFC_READY, payload };
}

export function newDocumentSelected() {
  return { type: NEW_DOCUMENT_SELECTED };
}

export function setDocument(payload) {
  return { type: SET_DOCUMENT, payload };
}

export function setDocumentAspectRatio(payload) {
  return { type: SET_ASPECT_RATIO, payload };
}
export function setDocumentLabel(payload) {
  return { type: SET_DOCUMENT_LABEL, payload };
}
export function preview(payload) {
  return { type: PREVIEW, payload };
}

export const setDocumentId = (payload) => (dispatch) => {
  dispatch({ type: SET_DOCUMENT_ID, payload });
};

export const setDocumentNumber = (payload) => (dispatch) => {
  dispatch({ type: SET_DOCUMENT_NUMBER, payload });
};

export const setDocumentExpiryDate = (payload) => (dispatch) => {
  dispatch({ type: SET_DOCUMENT_EXPIRY_DATE, payload });
};
export const setDocumentDateOfBirth = (payload) => (dispatch) => {
  dispatch({ type: SET_DOCUMENT_DATE_OF_BIRTH, payload });
};

export function retry(payload) {
  return { type: RETRY, payload };
}

export function retryProcessing(payload) {
  return { type: RETRY_PROCESSING, payload };
}

export function increaseRetryCounter(payload) {
  return { type: INCREASE_RETRY_COUNTER, payload };
}
export function resetRetries() {
  return { type: RESET_RETRIES };
}

export function captureTimeout() {
  return {
    type: CAPTURE_TIMEOUT,
  };
}

export function setCroppedImage(payload) {
  return { type: SET_CROPPED_IMAGE, payload };
}
export function setIsDocumentWasmLoaded(payload) {
  return { type: SET_IS_DOC_WASM_LOADED, payload };
}

export function setCroppedFirstPage(payload) {
  return { type: SET_CROPPED_FIRST_PAGE, payload };
}

export function createDocumentCaptureInstance(config) {
  return {
    type: CREATE_INSTANCE,
    payload: new Daon.DocumentCapture(config),
  };
}

export function setDocuments(payload) {
  return {
    type: SET_DOCUMENTS,
    payload,
  };
}
export function setSkipDocumentSelection(payload) {
  return {
    type: SET_SKIP_DOCUMENT_SELECTION,
    payload,
  };
}

export function firstTimeInit() {
  return {
    type: FIRST_TIME_INIT,
  };
}

export function userTimeout() {
  return {
    type: USER_TIMEOUT,
  }
};

export function resetUserTimeout() {
  return {
  type: RESET_USER_TIMEOUT,

  }
};