import * as S from "./PoweredByDaon.styles";
import DaonLogo from "./DaonLogo";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import { useContext } from "react";

export default function PoweredByDaon({ white, className }) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  return (
    <S.Wrapper white={white} className={className || ""}>
      <span aria-hidden="true">
        {t("PoweredByDaon.text")}
      </span>
      <span >
        <DaonLogo fill={white ? "#FFF" : theme?.textSecondary} />
      </span>
    </S.Wrapper>
  );
}
