import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string } from "prop-types";
import DocumentCaptureUI from "../DocumentCaptureUI";
// import { saveAs } from "file-saver";

import {
  preview,
  setCroppedFirstPage,
  selectDocumentType,
  captureTimeout,
  userTimeout,
} from "logic/actions/documentCapture";
import { changeCamera } from "logic/actions/camera";
import { documentsSave } from "logic/actions/api/document";
import { go, previous } from "logic/actions/navigation";

import {
  CANVAS_DOCUMENT_FOUND,
  CANVAS_DOCUMENT_NOT_FOUND,
  CANVAS_DOCUMENT_PASSED,
  PASSPORT,
  DOCUMENT_SIDE_BACK,
  DOCUMENT_SIDE_FRONT,
  DOCUMENT_INSTRUCTIONS,
  OTHER_DOCUMENT,
} from "logic/enums/document";
import { WEBRTC } from "logic/enums/captureTypes";

import {
  logEvent,
  document_changed_camera,
  document_autocapture_started,
  document_autocapture_finished,
  document_autocapture_failed,
  document_capture_timeout,
  document_capture_back,
} from "logic/eventLogger";
import DaonErrorBox from "Components/common/DaonErrorBox";
import * as S from "./PageDocumentCapturePortrait.styles";
import { webView } from "logic/webView";
import {
  BACKGROUND_EVENT,
  GRANT_CAMERA_PERMISSION,
  MRZ_CHECK_ERROR_MESSAGE,
  ON_CHECK_DOCUMENT_OCR,
} from "logic/enums/composite";
import { isIOS, isMobile } from "logic/deviceType";
import CaptureButtonPanel from "../../../CaptureButtonPanel";
import { toggleNetworkWarnings } from "logic/actions/apiFetch";
import VisiblityChangeHandler from "../../common/VisiblityChangeHandler";
import {
  VERIFY_IDENTITY,
  DOCUMENT_CAPTURE_INSTRUCTIONS,
} from "logic/enums/pages";
import { withTranslation } from "react-i18next";
import { setSelectedCamera } from "logic/actions/camera";
import PageContent from "Components/PageContent";
import DaonButton from "Components/common/DaonButton";
import { base64ImageRegex } from "logic/enums/base64Image";
import { ANDROID, IOS } from "logic/enums/composite";
import { steps } from "logic/steps";
import { NFC_STEP } from "logic/enums/steps";
import { BarcodeSideEnum } from "logic/enums/barcode";
import {
  BARCODE_TRUST_NUM_FRAMES,
  SAAS_BARCODE_NUM_FRAMES,
  SAAS_NUM_FRAMES,
} from "logic/enums/numberOfFrames";
import { getTranslatedContent } from "logic/documentTranslation";
/*global MobileFormHandler*/

const START = 0;
const CAPTURING = 1;
const ERROR = -1;
let resolvePromise;
let rejectPromise;
let isNfcStepPresent;

export class PageDocumentCapturePortrait extends Component {
  static propTypes = {
    preview: func,
    documentType: string,
  };

  constructor(props) {
    super(props);
    this.canvasRef = new React.createRef();

    this.state = {
      pending: true,
      message: "",
      formState: START,
      captureMethod: "WEBRTC",
      cameraPermission: true,
      hideNative: false,
      showSwitchCameraButton: true,
      videoMetaDataLoaded: false,
      coords: {},
      count: 0,
      messageList: [],
      switchingCamera: false,
      aspectRatio: this.props.aspectRatio,
      showRemainingTime: false,
      params: {
        showIndicator: true,
        showExclamationMark: true,
      },
    };
    this.setShowRemainingTime = this.setShowRemainingTime.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      docCaptureInstance: new Daon.DocumentCapture({
        width: this.props.docCaptureWidth
          ? parseInt(this.props.docCaptureWidth)
          : 1920,
        height: this.props.docCaptureHeigth
          ? parseInt(this.props.docCaptureHeigth)
          : 1080,
        croppingTolerance: this.props.croppingTolerance,
        facingMode: "environment",
        compressionRate: this.props.compressionRate,
        shouldSaveUnprocessed:
          (!this.props.mobileAppPlatform ||
            !this.props.isNFCReady ||
            !isNfcStepPresent) &&
          (this.props.shouldNotSendUnprocessedDocuments === "true" ||
            !this.props.shouldNotSendUnprocessedDocuments),
        allowNonContinuousFrames: isIOS(navigator.userAgent)
          ? this.props.allowNonContinuousFrames
          : false,
        shouldScanBarcode: this.handleScanBarcode(),
      }),
    });
  }

  componentDidMount() {
    isNfcStepPresent = steps.findIndex((x) => x.name === NFC_STEP) > -1;
    if (this.props.mobileAppPlatform === ANDROID) {
      window.sendToNativePromise = this.sendToNativePromise;
      window.onOcrReadingFailed = this.onOcrReadingFailed;
      window.onOcrReadingSuccess = this.onOcrReadingSuccess;
    }
    if (this.props.mobileAppPlatform === IOS) {
      window.addEventListener("ocrEvent", this.ocrEventHandler);
      window.addEventListener("appState", this.appState);
    }

    document.title = `${this.props.t(
      "PageDocumentCapture.title"
    )} | Onboarding`;
  }

  getBack = () => {
    logEvent(document_capture_back, {
      captureTime: performance.now() - this.state.startTime || 0,
    });
    if (this.props.shouldSkipInstructionsPage) this.props.go(VERIFY_IDENTITY);
    else this.props.previous();
  };

  appState = (e) => {
    try {
      let response = JSON.parse(e.detail.data);
      if (response.appState === BACKGROUND_EVENT) {
        this.props.go(VERIFY_IDENTITY);
      }
    } catch (e) {
      console.log(e);
    }
  };

  setShowRemainingTime(value) {
    this.setState({ showRemainingTime: value });
  }
  ocrEventHandler = (e) => {
    try {
      let response = JSON.parse(e.detail.data);
      if (response.passed) {
        this.documentCapturePassedEvent(
          this.state.coordinates,
          this.state.qualityScore
        );
        this.preview();
      } else {
        this.setState({
          messageList: [
            {
              err: {
                message: MRZ_CHECK_ERROR_MESSAGE,
                messageCode: "DocumentCapture.OCR",
              },
              rank: 1,
            },
            ...this.state.messageList,
          ],
        });
        this.state.docCaptureInstance.searchForDocument(this.state.aspectRatio);
      }
    } catch (e) {
      console.log(e);
    }
  };

  documentCapturePassedEvent = (qualityScore, coordinates) => {
    logEvent(document_autocapture_finished, {
      captureTime: performance.now() - this.state.startTime,
      side: this.props.base64CroppedFirstPage ? "back" : "front",
      qualityScore,
      coordinates,
    });
  };

  preview = () => {
    this.props.preview({
      base64: this.state.base64,
      blob: this.state.blob,
      isRejected: false,
      captureMethod: this.state.captureMethod,
    });
    clearInterval(this.messagePriorityInterval);
  };

  handleScanBarcode = () => {
    if (
      this.props.checkBarcode &&
      this.props.document.barcodeSide &&
      this.props.document.barcodeSide !== BarcodeSideEnum.NONE
    )
      return (
        (!this.props.base64CroppedFirstPage &&
          this.props.document.barcodeSide === BarcodeSideEnum.FRONT) ||
        (this.props.base64CroppedFirstPage &&
          this.props.document.barcodeSide === BarcodeSideEnum.BACK)
      );

    return false;
  };

  startIdDetector = () => {
    this.state.docCaptureInstance.startIDDetector({
      onIDDetectorInitialized: () => {
        this.setState({
          pending: false,
          switchingCamera: false,
          message: this.state.switchingCamera
            ? this.props.t("PageDocumentCapturePortrait.position_document")
            : "",
        });
        this.getPriorityMessage();
        isMobile(navigator.userAgent) && this.tapHandler();
      },

      onIDDetectorError: (coordinates, err, qualityScore) => {
        this.setState({
          messageList: [
            { err, qualityScore, coordinates },
            ...this.state.messageList,
          ],
        });

        setTimeout(() => {
          this.state.docCaptureInstance.searchForDocument(
            this.state.aspectRatio
          );
        }, 100);
      },

      onIDDetection: async (
        documentImage,
        coordinates,
        qualityScore,
        unprocessedImage
      ) => {
        if (documentImage && documentImage !== "data:") {
          this.setState({ base64: documentImage, blob: unprocessedImage });
          if (
            this.props.mobileAppPlatform &&
            this.props.isNFCReady &&
            (this.props.singlePaged ||
              this.props.documentType === PASSPORT ||
              this.props.base64CroppedFirstPage) &&
            isNfcStepPresent
          ) {
            try {
              if (this.props.mobileAppPlatform === ANDROID) {
                await this.sendToNativePromise(documentImage);
                this.documentCapturePassedEvent(coordinates, qualityScore);
                this.preview();
              } else if (this.props.mobileAppPlatform === IOS) {
                this.setState({ coordinates, qualityScore });
                webView(
                  ON_CHECK_DOCUMENT_OCR,
                  documentImage.replace(base64ImageRegex, "")
                );
              }
            } catch (e) {
              console.log(e);
              this.setState({
                messageList: [
                  {
                    err: {
                      message: MRZ_CHECK_ERROR_MESSAGE,
                      messageCode: "DocumentCapture.OCR",
                    },
                    rank: 1,
                  },
                  ...this.state.messageList,
                ],
              });

              this.state.docCaptureInstance.searchForDocument(
                this.state.aspectRatio
              );
            }
          } else {
            this.documentCapturePassedEvent(coordinates, qualityScore);
            this.preview();
          }
        }
      },
      nPassedFrames: !!this.props.mobileAppPlatform
        ? BARCODE_TRUST_NUM_FRAMES
        : this.handleScanBarcode()
        ? SAAS_BARCODE_NUM_FRAMES
        : SAAS_NUM_FRAMES,
      debug: process.env.NODE_ENV === "development",
    });
  };

  onCaptureTimeout = () => {
    if (this.props.shouldSkipInstructionsPage) return;
    this.props.userTimeout();
    this.props.captureTimeout();
    logEvent(document_capture_timeout, {
      documentType: this.props.documentType,
      documentCountry: this.props.dialCountryNum,
      retryCount: this.props.retryCount,
    });
    this.props.go(DOCUMENT_CAPTURE_INSTRUCTIONS);
  };
  sendToNativePromise = (image) => {
    return new Promise((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
      webView(ON_CHECK_DOCUMENT_OCR, image.replace(base64ImageRegex, ""));
    });
  };

  onOcrReadingFailed = (message) => {
    rejectPromise(message);
  };
  onOcrReadingSuccess = () => {
    resolvePromise("aaa");
  };
  onCameraStarted = () => {
    document.title = `Document Capture| Onboarding`;
    if (this.state.videoMetaDataLoaded) return;
    this.setState({ videoMetadataLoaded: true });
    setTimeout(() => {
      this.state.docCaptureInstance.loadWasmModules({
        urlIDDetectorWasm: window.location.origin + "/DaonIDCapture.wasm",
        onIDModuleInited: ({ isLoaded, error }) => {
          if (isLoaded) {
            setTimeout(() => {
              this.startIdDetector();
            }, 1500);
          }
        },
        shouldSaveUnprocessed:
          (!this.props.mobileAppPlatform ||
            !this.props.isNFCReady ||
            !isNfcStepPresent) &&
          (this.props.shouldNotSendUnprocessedDocuments === "true" ||
            !this.props.shouldNotSendUnprocessedDocuments),
      });
    }, 1000);

    if (this.state.docCaptureInstance.camera.videoTracks.length) {
      const trackSettings =
        this.state.docCaptureInstance.camera.videoTracks[0].getSettings();
      this.setState({
        streamWidth: trackSettings.width,
        streamHeight: trackSettings.height,
      });
      const device = this.state.docCaptureInstance.camera.videoTracks[0];
      const { deviceId, groupId } = device.getSettings();
      this.props.setSelectedCamera({
        label: device.label,
        deviceId,
        groupId,
      });
    }
  };

  onVisibilityChange = (isVisible) => {
    if (
      this.state.captureMethod !== "NATIVE" &&
      !this.state.pending &&
      isVisible
    )
      this.props.go(VERIFY_IDENTITY);
  };

  getPriorityMessage() {
    if (this.messagePriorityInterval) {
      clearInterval(this.messagePriorityInterval);
    }

    this.messagePriorityInterval = setInterval(() => {
      if (this.state.messageList.length) {
        const rankedMessage = this.state.messageList.find(
          (message) => message.rank
        );
        const highestPriorityMessage =
          rankedMessage || this.state.messageList[0];

        if (highestPriorityMessage) {
          if (
            highestPriorityMessage.err?.code === 900 ||
            highestPriorityMessage.err?.message === MRZ_CHECK_ERROR_MESSAGE
          ) {
            this.state.docCaptureInstance.setStrokeStyle(
              CANVAS_DOCUMENT_PASSED,
              this.state.aspectRatio,
              {
                showIndicator: true,
                showCheckmark: true,
                overlayBackground: this.props.overlayBackground,
              }
            );
          } else if (highestPriorityMessage.err?.code === 910) {
            this.state.docCaptureInstance.setStrokeStyle(
              CANVAS_DOCUMENT_NOT_FOUND,
              this.state.aspectRatio,
              {
                showIndicator: true,
                showExclamationMark: true,
                overlayBackground: this.props.overlayBackground,
              }
            );
          } else {
            this.state.docCaptureInstance.setStrokeStyle(
              CANVAS_DOCUMENT_FOUND,
              this.state.aspectRatio,
              {
                showIndicator: true,
                showExclamationMark: true,
                overlayBackground: this.props.overlayBackground,
              }
            );
          }

          logEvent(document_autocapture_failed, {
            error: highestPriorityMessage.err.message,
            qualityScore: highestPriorityMessage.qualityScore,
            coordinates: highestPriorityMessage.coordinates,
            side: this.props.base64CroppedFirstPage ? "back" : "front",
          });
          this.setState({
            message:
              this.props.t(highestPriorityMessage.err.messageCode) ===
              highestPriorityMessage.err.messageCode
                ? highestPriorityMessage.err.message
                : this.props.t(highestPriorityMessage.err.messageCode),
            messageList: [],
          });
        }
      }
    }, 600);
  }

  flipCamera = () => {
    this.setState({ aspectRatio: 1 / this.state.aspectRatio }, () => {
      this.state.docCaptureInstance.setScanningRegion(this.state.aspectRatio);
      this.state.docCaptureInstance.setStrokeStyle();
    });
  };

  tapHandler = () => {
    const { documentType } = this.props;
    logEvent(document_autocapture_started, {
      documentType: documentType,
      width: this.props.width,
      height: this.props.height,
    });

    const startTime = performance.now();
    this.setState({
      captureMethod: WEBRTC,
      formState: CAPTURING,
      message: "",
      networkError: false,
      startTime,
    });

    this.state.docCaptureInstance.searchForDocument(this.state.aspectRatio);
  };

  changeCamera = (cameraId) => {
    this.props.changeCamera(cameraId);
    this.setState({
      switchingCamera: true,
      videoMetaDataLoaded: false,
      formState: START,
    });
    logEvent(document_changed_camera);
    clearInterval(this.messagePriorityInterval);
  };

  cameraPermissionDenied = () => {
    this.setState({
      cameraPermission: false,
      message: this.props.t("PageDocumentCapturePortrait.please_enable_camera"),
      isError: true,
      retryCameraInit: false,
      pending: false,
      formState: ERROR,
    });
  };

  retryCameraInit = () => {
    if (this.props.mobileAppPlatform === IOS) webView(GRANT_CAMERA_PERMISSION);
    this.setState({
      cameraPermission: true,
      retryCameraInit: true,
      message: "",
      isError: false,
      pending: true,
      formState: START,
    });
  };

  componentWillUnmount() {
    this.state.docCaptureInstance.stopCamera();
    this.state.docCaptureInstance.destroy();
    if (this.props.mobileAppPlatform === ANDROID) {
      window.sendToNativePromise = null;
      window.onOcrReadingFailed = null;
      window.onOcrReadingSuccess = null;
    }
    if (this.props.mobileAppPlatform === IOS)
      window.removeEventListener("ocrEvent", this.ocrEventHandler);
    window.removeEventListener("appState", this.appState);
    this.setState({ docCaptureInstance: null });
    clearInterval(this.messagePriorityInterval);
  }

  render() {
    const message =
      this.state.message ||
      this.props.t("PageDocumentCapturePortrait.position_document");

    let translationPage;
    let translatedContent;

    const documentSide = this.props.base64CroppedFirstPage
      ? DOCUMENT_SIDE_BACK
      : DOCUMENT_SIDE_FRONT;

    const documentType = this.props.documentType || OTHER_DOCUMENT;

    if (
      this.props.documentLabel &&
      !this.props.documentLabel.includes("PageVerifyIdentity")
    ) {
      const documentSideTranslated = this.props.t(
        `PageDocumentCaptureInstructions.document_label_${documentSide}`
      );
      const subtitlePrefix = this.props.t(
        "PageDocumentCaptureInstructions.instuctions_small"
      );

      translationPage = DOCUMENT_INSTRUCTIONS;
      translatedContent = getTranslatedContent({
        documentSide,
        translate: this.props.t,
        translationPage,
        subtitlePrefix,
        documentLabel: this.props.documentLabel,
        documentSideTranslated,
      });
    } else {
      translationPage = DOCUMENT_CAPTURE_INSTRUCTIONS;
      translatedContent = getTranslatedContent({
        documentSide,
        translate: this.props.t,
        translationPage,
        documentType,
      });
    }
    const title = translatedContent?.title;
    return (
      <PageContent
        toggleLoading={this.state.pending}
        hideFooter={true}
        isDarkMode={true}
        backButtonClick={this.getBack}
        justifyContent={"center"}
        title={title}
      >
        <VisiblityChangeHandler onVisibilityChange={this.onVisibilityChange} />

        <DocumentCaptureUI
          docCaptureInstance={this.state.docCaptureInstance}
          onCameraStarted={this.onCameraStarted}
          captureMethod={this.state.captureMethod}
          cameraPermissionDenied={this.cameraPermissionDenied}
          retryCameraInit={this.state.retryCameraInit}
          onCaptureTimeout={this.onCaptureTimeout}
          setShowRemainingTime={this.setShowRemainingTime}
        />
        <p>{this.state.userMessage}</p>

        <S.DocumentCaptureFooter isError={this.state.isError}>
          {this.state.message && this.state.formState == CAPTURING ? (
            <DaonErrorBox
              style={{
                margin: "10px auto 50px auto",
              }}
              neutral={this.state.cameraPermission}
              title={
                !this.state.cameraPermission &&
                this.props.t("Common.no_camera_title")
              }
              message={ !this.state.showRemainingTime ? (
                message === "passed" || message === "passed_partial"
                  ? //@TODO translate this message
                    "Hold!"
                  : message
              ) : this.props.t("Common.time_remaining_message_document")
              }
            />
          ) : (
            <S.DocumentMessage aria-live="polite">
              {" "}
              {this.props.t(
                isMobile(navigator.userAgent)
                  ? "DocumentCapture.position_document_inside_area"
                  : "PageDocumentCapturePortrait.position_document"
              )}{" "}
            </S.DocumentMessage>
          )}
          {!this.state.cameraPermission && (
            <DaonButton
              onClick={this.retryCameraInit}
              style={{ margin: "0 auto" }}
            >
              {this.props.t("Common.confirm_camera_permission")}
            </DaonButton>
          )}
          {this.state.videoMetadataLoaded && (
            <CaptureButtonPanel
              onCaptureClick={this.tapHandler}
              onChangeCamera={this.changeCamera}
              flipCameraHandler={
                this.props.documentType === PASSPORT ? false : this.flipCamera
              }
              showSwitchCameraButton={this.state.showSwitchCameraButton}
              disabled={this.state.formState !== START}
              shouldDisableCameraButton={this.state.switchingCamera}
              shouldHideCaptureButton={
                this.state.formState !== START || isMobile(navigator.userAgent)
              }
            />
          )}
        </S.DocumentCaptureFooter>
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageDocumentCapturePortrait);

export default connect(
  (state) => {
    const {
      documentType,
      isNFCReady,
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      aspectRatio,
      retryCount,
      document,
    } = state.documentCapture;
    const { width, height, singlePaged, count, dialCountryNum } =
      state.documentCaptureConfiguration;
    const tenant = state.configuration?.tenant;
    const { mobileAppPlatform } = state.composite;

    const documentProcessingDelayMs =
      state.configuration?.extraConfig?.featureFlags?.documentProcessingDelay;
    const checkBarcode =
      state.configuration.extraConfig?.steps?.document?.options.checkBarcode;
    const shouldSkipInstructionsPage =
      state.configuration.extraConfig?.steps?.document?.options
        .shouldSkipInstructionsPage;

    const docCaptureWidth =
      state.configuration.extraConfig?.steps?.document?.options?.width ||
      state.configuration.extraConfig?.featureFlags.width;
    const docCaptureHeigth =
      state.configuration.extraConfig?.steps?.document?.options?.height ||
      state.configuration.extraConfig?.featureFlags.heigth;
    const allowNonContinuousFrames =
      state.configuration.extraConfig?.steps?.document?.options
        ?.allowNonContinuousFrames || false;

    const shouldNotSendUnprocessedDocuments =
      state.configuration.extraConfig?.featureFlags
        ?.shouldNotSendUnprocessedDocuments;
    const croppingTolerance =
      state.configuration.extraConfig?.steps?.document?.options
        ?.croppingTolerance;
    const overlayBackground =
      state.configuration.extraConfig?.steps?.document?.options?.overlayColor ??
      "rgba(0, 0, 0, 1)";
    const compressionRate =
      state.configuration.extraConfig?.steps?.document?.options
        ?.compressionRate;

    return {
      documentType,
      aspectRatio,
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      count,
      width,
      height,
      isNFCReady,
      singlePaged,
      tenant,
      mobileAppPlatform,
      dialCountryNum,
      documentProcessingDelayMs,
      docCaptureWidth,
      docCaptureHeigth,
      allowNonContinuousFrames,
      shouldNotSendUnprocessedDocuments,
      croppingTolerance,
      compressionRate,
      retryCount,
      document,
      checkBarcode,
      shouldSkipInstructionsPage,
      documentLabel: state.documentCapture.documentLabel,
      overlayBackground,
    };
  },
  {
    go,
    previous,
    preview,
    captureTimeout,
    userTimeout,
    changeCamera,
    setCroppedFirstPage,
    documentsSave,
    toggleNetworkWarnings,
    selectDocumentType,
    setSelectedCamera,
  }
)(componentWithTranslation);
