import styled from "styled-components";

export const CanvasWrapper = styled.div`
  @media (min-aspect-ratio: 3/4) and (min-width: 1000px) {
    min-height: 640px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  padding-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const MessageBoxWrapper = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.4em;
  text-align: center;
  color: #fdfefe;
  margin-bottom: 10px;

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    font-size: 0.85em;
  }

  @media (max-width: ${(props) => props.theme.smallPhoneBreakpoint + "px"}) {
    font-size: 0.7em;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
`;

export const FaceCaptureFooter = styled.span`
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: ${props => props.isError ? 'column' : 'row'};
  top: ${props => props.isError ? '70%' : '92%'};
  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    top: ${props => props.isError ? '60%' : '80%'};
    width: ${props => props.isError ? '90%' : '98%'};
  }
  z-index: 9;
  font-size: 1.3rem;
  width: 98%;
  overflow: hidden;
`;
