import styled from 'styled-components/macro'

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 8;
    min-height: 78vh;

    @media only screen and (min-width:${props => props.theme.phoneBreakpoint + 'px'}) {
        justify-content: center;
    }
`;

export const Paragraph = styled.p`
    text-align: left;
    font-size: 18px;
    line-height: 23px;
    @media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
        font-size: 16px;
        line-height: 21px;
    }
`;

export const Label = styled.label`
    margin: 0;
    text-align: left;
`;

export const SMSInput = styled.input`
    width: 400px;
    padding: 14px 20px 14px 20px;
    text-align: left;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 14px;
    color: ${props => props.theme.textPrimary};
    border: 2px solid ${props => props.theme.lightColor};
    border-radius: 8px;
    -webkit-appearance: none;
    outline: none;
    box-sizing: border-box;
    @media (max-width:${props => props.theme.phoneBreakpoint + 'px'}) and (orientation: portrait) {
        width: 100%;
    }
`;

export const ResendLink = styled.a`
    font-weight: bold;
    color: ${props => props.theme.mainColor};
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    padding-left: 16px;
`;

