import { ID_CARD_RATIO, PASSPORT, PASSPORT_RATIO } from "logic/enums/document";
import {
  SELECT_DOC_TYPE,
  PREVIEW,
  RETRY,
  RESET_RETRIES,
  SET_CROPPED_IMAGE,
  CREATE_INSTANCE,
  FIRST_TIME_INIT,
  RETRY_PROCESSING,
  SET_DOCUMENT,
  SET_CROPPED_FIRST_PAGE,
  SET_ASPECT_RATIO,
  NEW_DOCUMENT_SELECTED,
  SET_DOCUMENT_LABEL,
  SET_IS_DOC_WASM_LOADED,
  SET_DOCUMENT_ID,
  SET_IS_NFC_READY,
  SET_DOCUMENT_NUMBER,
  SET_DOCUMENT_EXPIRY_DATE,
  SET_DOCUMENT_DATE_OF_BIRTH,
  INCREASE_RETRY_COUNTER,
  CAPTURE_TIMEOUT,
  SET_DOCUMENTS,
  SET_SKIP_DOCUMENT_SELECTION,
  USER_TIMEOUT,
  RESET_USER_TIMEOUT,
} from "../actions/documentCapture";

const defaultState = {
  firstTimeInitialized: false,
  documentId: undefined,
  documentType: undefined,
  base64UnprocessedFirstPage: undefined,
  base64UnprocessedSecondPage: undefined,
  base64CroppedFirstPage: undefined,
  base64CroppedSecondPage: undefined,
  retries: 0,
  retryCount: 0,
  message: undefined,
  processingRetriesCounter: 0,
  documentProcessingRetriesCounter: 0,
  allRetriesFailed: false,
  summaryScorePresent: undefined,
  document: undefined,
  aspectRatio: undefined,
  documentLabel: undefined,
  isDocWasmLoaded: false,
  isNFCReady: false,
  documents: undefined,
  userTimedOut: false,
};

export function documentCapture(state = defaultState, action) {
  switch (action.type) {
    case CREATE_INSTANCE:
      return {
        ...state,
        docCaptureInstance: action.payload,
      };
    case SET_IS_DOC_WASM_LOADED:
      return {
        ...state,
        isDocWasmLoaded: action.payload,
      };
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case USER_TIMEOUT:
      return {
        ...state,
        userTimedOut: true,
      };
    case RESET_USER_TIMEOUT:
      return {
        ...state,
        userTimedOut: false,
      };
    case SET_SKIP_DOCUMENT_SELECTION:
      return {
        ...state,
        skipDocumentSelection: action.payload,
      };

    case SET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
      };
    case SET_DOCUMENT_ID:
      return {
        ...state,
        documentId: action.payload,
      };

    case SET_IS_NFC_READY:
      return {
        ...state,
        isNFCReady: action.payload,
      };
    case SET_DOCUMENT_LABEL:
      return {
        ...state,
        documentLabel: action.payload,
      };
    case FIRST_TIME_INIT:
      return {
        ...state,
        firstTimeInitialized: true,
      };
    case SELECT_DOC_TYPE:
      return {
        ...state,
        documentType: action.payload,
      };
    case SET_ASPECT_RATIO:
      if (action.payload) {
        return {
          ...state,
          aspectRatio: action.payload,
        };
      } else {
        if (state.documentType === PASSPORT) {
          return { ...state, aspectRatio: PASSPORT_RATIO.aspectRatio };
        } else {
          return { ...state, aspectRatio: ID_CARD_RATIO.aspectRatio };
        }
      }
    case NEW_DOCUMENT_SELECTED:
      return {
        ...state,
        base64UnprocessedFirstPage: undefined,
        base64UnprocessedSecondPage: undefined,
        base64CroppedFirstPage: undefined,
        base64CroppedSecondPage: undefined,
        retries: 0,
      };
    case SET_DOCUMENT_NUMBER:
      return {
        ...state,
        documentNumber: action.payload
      };
      case SET_DOCUMENT_EXPIRY_DATE:
      return {
        ...state,
        documentExpiryDate: action.payload
      };
    case SET_DOCUMENT_DATE_OF_BIRTH: 
      return {
        ...state,
        documentDateOfBirth: action.payload
      }
    case PREVIEW:
      if (state.base64CroppedFirstPage) {
        return {
          ...state,
          base64UnprocessedSecondPage: action.payload.blob,
          base64CroppedSecondPage: action.payload.base64,
          message: action.payload.message,
          isRejected: action.payload.isRejected,
          captureMethod: action.payload.captureMethod,
          allRetriesFailed: action.payload.allRetriesFailed,
          summaryScorePresent: action.payload.summaryScorePresent,
        };
      }
      return {
        ...state,
        base64UnprocessedFirstPage: action.payload.blob,
        base64CroppedFirstPage: action.payload.base64,
        base64UnprocessedSecondPage: undefined,
        base64CroppedSecondPage: undefined,
        message: action.payload.message,
        isRejected: action.payload.isRejected,
        captureMethod: action.payload.captureMethod,
        allRetriesFailed: action.payload.allRetriesFailed,
        summaryScorePresent: action.payload.summaryScorePresent,
      };
    case RESET_RETRIES: {
      return {
        ...state,
        retries: 0,
      };
    }

    case RETRY:
      return {
        ...state,
        base64UnprocessedFirstPage: action.payload
          ? null
          : state.base64UnprocessedSecondPage
          ? state.base64UnprocessedFirstPage
          : undefined,
        base64CroppedFirstPage: action.payload
          ? null
          : state.base64CroppedSecondPage
          ? state.base64CroppedFirstPage
          : undefined,
        base64UnprocessedSecondPage: undefined,
        base64CroppedSecondPage: undefined,
        croppedResponseBase64: undefined,
        retries: state.retries + 1,
      };
    case SET_CROPPED_IMAGE: {
      return {
        ...state,
        croppedResponseBase64: action.payload,
      };
    }
    case SET_CROPPED_FIRST_PAGE: {
      return {
        ...state,
        base64CroppedFirstPage: action.payload,
      };
    }
    case CAPTURE_TIMEOUT: {
      return {
        ...state,
        retryCount: state.retryCount + 1,
      };
    }
    case RETRY_PROCESSING: {
      return {
        ...state,
        base64UnprocessedFirstPage: undefined,
        base64CroppedFirstPage: undefined,
        base64UnprocessedSecondPage: undefined,
        base64CroppedSecondPage: undefined,
        croppedResponseBase64: undefined,
        documentProcessingRetriesCounter:
          state.documentProcessingRetriesCounter + 1,
      };
    }
    case INCREASE_RETRY_COUNTER: {
      return {
        ...state,
        processingRetriesCounter: state.processingRetriesCounter + 1,
      };
    }

    default:
      return state;
  }
}

export const selectDocumentExpiryDate = (state) => {
  return state.documentCapture?.documentExpiryDate;
};

export const selectDocumentNumber = (state) => {
  return state.documentCapture?.documentNumber;
};
