import styled from 'styled-components/macro'

export const WrapperDiv = styled.div`
background: ${props => props.neutral? "rgba(255, 255, 255, 0.25)":"rgba(241,96,99,0.25)"};
border: ${props => props.neutral? "1px solid #FDFEFE": "1px solid #F16063"};
padding: 0.7rem;
border-radius: 8px;
margin: ${props => props.neutral? "auto" : ""};
margin-top: 1rem;
margin-bottom: 1rem;
max-width: 500px;
position: relative;
z-index: 15;
@media (max-width: ${props => props.theme.phoneBreakpoint + 'px'}) and (orientation: portrait) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 0.75rem;
}
` 

export const TitleSpan = styled.span`
font-size: 14px;
color: #F16063;
`

export const TitleDiv = styled.div`
display: flex;
`

export const Paragraph = styled.p`
color: ${props => props.whiteBackground ? '#F16063' : '#FDFEFE'};
margin:0;
font-size: 11px;
grid-column: 2;
`
export const ExclamationImage = styled.img`
color: white;
width: 20px;
margin: 0 0.5rem;
`